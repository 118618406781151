import React, { Component, createRef } from "react";
import Dropzone from "react-dropzone";
// import Modal from 'react-responsive-modal';
import AddEditProductCategory from "./AddEditProductCategory";
import AddEditProductBrand from "./AddEditProductBrand";
import { withRouter } from "react-router-dom";
import * as actionTypes from "../../actionTypes";
import AuditTrail from "../AuditTrail";
import * as constants from "../../Constatnts";
import Switch from "react-switch";
import ReactTooltip from "react-tooltip";
import CreatableSelect from "react-select";
import Input from "../2/Controls/Input";
import { getUOMs } from "../../CommonFunctions";
import Page from "../2/Controls/Page";
import PageSection from "../2/Controls/PageSection";
import { Editor } from "@tinymce/tinymce-react";
import firebase, { database } from '../../firebase';
class Product extends Component {
  constructor() {
    super();
    this.state = {
      productTypes: [
        { value: 1, text: "Goods for sale" },
        { value: 2, text: "Goods not for sale" },
        { value: 3, text: "Services" },
        { value: 4, text: "Wallet Recharge" },
        { value: 5, text: "Package" },
      ],
      pincodeMerchant: false,
      uoms: [],
      productAutoGenerateSKU: false,
      OMNIEnabled: false,
      quickAdd: true,
      sku: "",
      barCode: "",
      name: "",
      productTypeID: 1,
      productType: "Goods for sale",
      productTypeError: "",
      description: "",
      category: "",
      categoryID: "",
      subCategory: "",
      subCategoryID: "",
      brand: "",
      brandID: "",
      tags: "",
      images: [],
      compareAtPrice: "",
      supplyPrice: 0,
      markup: 0,
      retailPrice: 0,
      MRP: 0,
      MSP: 0,
      repackProduce: false,
      allowDiscount: true,
      allowPriceEdit: false,
      allowTaxChange: false,
      chargeTaxOnPoduct: false,
      taxInclusive: false,
      taxInclusivePurchase: false,
      priceBasedTax: false,
      walletTopUpSameAsRetailPrice: true,
      walletTopUpAmount: 0,
      walletTopUpError: "",
      taxID: "",
      taxGroup: "",
      taxPriceRange: null,
      initialTaxPriceRange: [
        {
          FromPrice: 0,
          ToPrice: 0,
          TaxID: "",
          TaxGroup: "",
          FromPriceErrMsg: "",
          ToPriceErrMsg: "",
          TaxErrMsg: "",
        },
        {
          FromPrice: 0,
          ToPrice: 0,
          TaxID: "",
          TaxGroup: "",
          FromPriceErrMsg: "",
          ToPriceErrMsg: "",
          TaxErrMsg: "",
        },
      ],
      HSNSACCode: "",
      categoryList: [],
      subCategoryList: [],
      brandList: [],
      tagList: [],
      isShowingModalAddCategory: false,
      firstLoad: false,
      isShowingModalAddBrand: false,
      isShowingModalAddSubCategory: false,
      nameError: "",
      categoryError: false,
      priceError: "",
      supplyPriceError: "",
      markupError: "",
      mrpError: "",
      skuError: "",
      categoryOrSubCategory: "",
      saving: false,
      editWalletTopUpAmount: false,
      accessibleToSelectedStores: false,
      storeSpecificPricing: false,
      storeSpecificPrices: [],
      storeRetailPrice: 0,
      storeSupplyPrice: 0,
      storeMarkUp: 0,
      storeMRP: 0,
      storeMSP: 0,
      storePriceStore: "",
      storePriceStoreID: "",
      storePriceStoreError: "",
      stores: [], //stores accessible to logged in user,
      storeWalletTopUpAmount: 0,
      storeWalletTopUpSameAsRetailPrice: true,
      storeEditWalletTopUpAmount: false,
      storeSpecificPricesError: "",
      packageServicesError: "",
      discountAmount: 0,
      discountPercentage: 0,
      storeDiscountAmount: 0,
      storeDiscountPercentage: 0,
      minQty: 0,
      maxQty: 0,
      reorderQty: 0,
      inventoryType: "FIFO",
      previousinventoryType:'',
      uniqueLabel: "",
      uom: "",
      searchMode: "",
      //Package Related
      services: [],
      service: "",
      serviceID: "",
      serviceDuration: 0,
      packageServices: [],
      serviceError: "",
      validityInDays: "",
      validityInDaysError: "",
      qtyPrompt: false,
      associateMembership: false,
      membershipID: "",
      membership: "",
      membershipError: "",
      mobile: false,
      storeSpecificQtys: [],
      notifyCustomerInDays: 0,
      notifyCustomerInDaysError: "",
      enableAlerts: false,
      smsTemplateID: "",
      smsTemplateError: "",
      smsTemplates: [],
      customField1: "",
      customField2: "",
      customField3: "",
      customField4: "",
      customField5: "",
      customField6: "",
      selectedAddress: null,
      selectedAddressIndex: -1,
      customField1DisplayName: "Custom Field 1",
      customField2DisplayName: "Custom Field 2",
      customField3DisplayName: "Custom Field 3",
      customField4DisplayName: "Custom Field 4",
      customField5DisplayName: "Custom Field 5",
      customField6DisplayName: "Custom Field 6",
      favourite: false,
      bom: false,
      bomManual: false,
      canBeUsedAsConsumable: false,
      batchTrackingBatchNoRequired: false,
      batchTrackingAutoBatchNoRequired: false,
      batchTrackingMRPRequired: false,
      batchTrackingMfgDateRequired: false,
      batchTrackingExpDateRequired: false,
      batchTrackingDateFormat: "mm/yy",
      inventoryTypeOptions: [
        { value: "FIFO" },
        { value: "Unique No" },
        { value: "Variants" },
        { value: "Batch Tracking" },
      ],
      //UOM related
      secondaryUOMs: [],
      secondaryUOM: "",
      secondaryUOMError: "",
      conversion: 0,
      masterUOMs: [],
      salesUOM: "",
      pruchaseUOM: "",
      //Variant related
      attributes: [],
      attribute: "",
      attributeValue: "",
      attributeValues: [],
      selectedAttribute: null,
      variants: [],
      customSKU: "",
      autogenerateSKU: false,
      variantsError: "",
      // customSKUError:'',
      variantSKUError: "",
      showAsAdditionalCharges: false,
      packagingCharges: 0,
      outOfStock: false,
      longDescription: "",
      sequenceNo: "",
      maxOrderQty: "",
    };
    this.onDrop = this.onDrop.bind(this);
    this.onRemoveImage = this.onRemoveImage.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onProductTypeChange = this.onProductTypeChange.bind(this);
    this.onSubCategoryChange = this.onSubCategoryChange.bind(this);
    this.onBrandChange = this.onBrandChange.bind(this);
    this.onSaveProduct = this.onSaveProduct.bind(this);
    this.onTaxChange = this.onTaxChange.bind(this);
    this.loadProductBrands = this.loadProductBrands.bind(this);
    this.onFromPriceChange = this.onFromPriceChange.bind(this);
    this.onToPriceChange = this.onToPriceChange.bind(this);
    this.onPriceBasedTaxChange = this.onPriceBasedTaxChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onAddStoreSpecificPrice = this.onAddStoreSpecificPrice.bind(this);
    this.onAddSecondaryUOM = this.onAddSecondaryUOM.bind(this);
    this.getServices = this.getServices.bind(this);
    //Package Related
    this.onAddService = this.onAddService.bind(this);
    this.onRemoveService = this.onRemoveService.bind(this);
    this.formControls = this.formControls.bind(this);
    this.onHeaderBackClick = this.onHeaderBackClick.bind(this);
    //Variant Related
    this.onAddVariant = this.onAddVariant.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.createVariants = this.createVariants.bind(this);
    this.editorRef = createRef();
  }

  onHeaderBackClick() {
    if (this.props.calledFromInvoice) {
      //console.log('Called fomm invoice ')
      this.props.onClose();
    } else if (this.props.isMobileApp()) {
      this.props.history.push({ pathname: "/home/catalogue/products" });
    } else {
      // console.log('Not called fomm invoice ')
      this.props.history.push("/home/product");
    }
  }

  async componentDidMount() {
    if (
      this.props.registration.registration.PincodeIntegration &&
      this.props.config.WhiteLabel.Code === "POSIBLE"
    ) {
      console.log("Pincode merchant");
      this.setState({
        pincodeMerchant: true,
        inventoryTypeOptions: [{ value: "FIFO" }, { value: "Batch Tracking" }],
      });
    }
    let uoms = getUOMs();
    this.setState({ uoms: uoms });
    if (
      this.props.registration.registration.hasOwnProperty("AutoGenerateSKU") &&
      this.props.registration.registration.AutoGenerateSKU
    ) {
      this.setState({
        productAutoGenerateSKU: this.props.registration.registration
          .AutoGenerateSKU,
        sku: "Auto generate",
      });
    }
    let stores = await this.props
      .getUserStores(
        this.props.user.user.RegistrationID,
        this.props.user.auth.uid
      )
      .then((stores) => {
        return stores;
      });
    let smsTemplates = await this.props
      .getSMSTemplates(this.props.user.user.RegistrationID, "productalert")
      .then((result) => {
        let smsTemplates = [];
        // console.log("result",result);
        if (result && result.length > 0) {
          smsTemplates = result.filter((f) => f.ModuleID === "productalert");
        }
        return smsTemplates;
      });
    // console.log("smsTemplates",smsTemplates);
    this.setState({ stores: stores, smsTemplates: smsTemplates });
    this.getServices();
    this.props.config.CustomFields.map((customField) => {
      if (customField.ModuleName === "Product") {
        let customField1DisplayName =
          customField.CustomField1Name.trim() === ""
            ? "Custom Field 1"
            : customField.CustomField1Name.trim();
        let customField2DisplayName =
          customField.CustomField2Name.trim() === ""
            ? "Custom Field 2"
            : customField.CustomField2Name.trim();
        let customField3DisplayName =
          customField.CustomField3Name.trim() === ""
            ? "Custom Field 3"
            : customField.CustomField3Name.trim();
        let customField4DisplayName =
          customField.CustomField4Name.trim() === ""
            ? "Custom Field 4"
            : customField.CustomField4Name.trim();
        let customField5DisplayName =
          customField.CustomField5Name.trim() === ""
            ? "Custom Field 5"
            : customField.CustomField5Name.trim();
        let customField6DisplayName =
          customField.CustomField6Name.trim() === ""
            ? "Custom Field 6"
            : customField.CustomField6Name.trim();
        this.setState({
          customField1DisplayName: customField1DisplayName,
          customField2DisplayName: customField2DisplayName,
          customField3DisplayName: customField3DisplayName,
          customField4DisplayName: customField4DisplayName,
          customField5DisplayName: customField5DisplayName,
          customField6DisplayName: customField6DisplayName,
        });
      }
    });
    const key = this.props.match.params.key;
    if (key) {
      //existing product
      this.props.getProductDetails(key).then((product) => {
        //    console.log('product',product)
        let storeSpecificQtys = product.hasOwnProperty("StoreSpecificQtys")
          ? product.StoreSpecificQtys
          : [];
        if (storeSpecificQtys.length === 0) {
          for (let index = 0; index < stores.length; index++) {
            const obj = stores[index];
            let store = {
              StoreID: obj.key,
              StoreName: obj.StoreName,
              MinQty: 0,
              MaxQty: 0,
              ReorderQty: 0,
            };
            storeSpecificQtys.push(store);
          }
        } else {
          for (let index = 0; index < stores.length; index++) {
            const obj = stores[index];
            let foundStore = storeSpecificQtys.filter(
              (i) => i.StoreID === obj.key
            );
            if (foundStore.length === 0) {
              let store = {
                StoreID: obj.key,
                StoreName: obj.StoreName,
                MinQty: 0,
                MaxQty: 0,
                ReorderQty: 0,
              };
              storeSpecificQtys.push(store);
            }
          }
        }

        let masterUOMs = [];
        if (product.hasOwnProperty("UOM") && product.UOM !== "") {
          masterUOMs.push({
            value: product.UOM,
            text: this.state.uoms.filter((f) => f.value === product.UOM)[0]
              .text,
          });
        }
        if (
          product.hasOwnProperty("SecondaryUOMs") &&
          product.SecondaryUOMs.length > 0
        ) {
          for (let su = 0; su < product.SecondaryUOMs.length; su++) {
            const obj1 = product.SecondaryUOMs[su];
            masterUOMs.push({
              value: obj1.SecondaryUOMValue,
              text: obj1.SecondaryUOM,
            });
          }
        }

        let variants = product.hasOwnProperty("Variants")
          ? product.Variants
          : [];
        for (let i = 0; i < variants.length; i++) {
          const variant = variants[i];
          if (!variant.hasOwnProperty("IsDeleted")) {
            variant.IsDeleted = false;
          }
        }

        this.setState({
          quickAdd: false,
          sku: product.SKU,
          barCode: product.BarCode,
          name: product.Name,
          productID: product.ProductID,
          productType: product.ProductType,
          productTypeID: product.ProductTypeID,
          description: product.Description,
          category: product.Category,
          categoryID: product.CategoryID,
          subCategory: product.SubCategory,
          subCategoryID: product.SubCategoryID,
          brand: product.Brand,
          brandID: product.BrandID,
          tags: product.tags,
          supplyPrice: product.SupplyPrice,
          markup: product.Markup,
          retailPrice: product.RetailPrice,
          MRP: product.MRP,
          MSP: product.hasOwnProperty("MSP") ? product.MSP : 0,
          allowDiscount: product.hasOwnProperty("AllowDiscount")
            ? product.AllowDiscount
            : true,
          allowPriceEdit: product.hasOwnProperty("AllowPriceEdit")
            ? product.AllowPriceEdit
            : true,
          allowTaxChange: product.hasOwnProperty("AllowTaxChange")
            ? product.AllowTaxChange
            : true,
          priceBasedTax: product.PriceBasedTax,
          walletTopUpSameAsRetailPrice: product.hasOwnProperty(
            "WalletTopUpSameAsRetailPrice"
          )
            ? product.WalletTopUpSameAsRetailPrice
            : true,
          walletTopUpAmount: product.hasOwnProperty("WalletTopUpAmount")
            ? product.WalletTopUpAmount
            : 0,
          chargeTaxOnPoduct: product.ChargeTaxOnProduct,
          taxInclusive: product.TaxInclusive,
          taxInclusivePurchase: product.hasOwnProperty("TaxInclusivePurchase")
            ? product.TaxInclusivePurchase
            : false,
          HSNSACCode: product.HSNSACCode,
          taxID: product.TaxID,
          taxGroup: product.TaxGroup,
          taxPriceRange: product.TaxPriceRange,
          images: product.Images,
          editWalletTopUpAmount: product.hasOwnProperty("EditWalletTopUpAmount")
            ? product.EditWalletTopUpAmount
            : false,
          accessibleToSelectedStores: product.hasOwnProperty(
            "AccessibleToSelectedStores"
          )
            ? product.AccessibleToSelectedStores
            : false,
          storeSpecificPricing: product.hasOwnProperty("StoreSpecificPricing")
            ? product.StoreSpecificPricing
            : false,
          storeSpecificPrices: product.hasOwnProperty("StoreSpecificPrices")
            ? product.StoreSpecificPrices
            : [],
          discountAmount: product.hasOwnProperty("DiscountAmount")
            ? product.DiscountAmount
            : 0,
          discountPercentage: product.hasOwnProperty("DiscountPercentage")
            ? product.DiscountPercentage
            : 0,
          minQty: product.hasOwnProperty("MinQty") ? product.MinQty : 0,
          maxQty: product.hasOwnProperty("MaxQty") ? product.MaxQty : 0,
          reorderQty: product.hasOwnProperty("ReorderQty")
            ? product.ReorderQty
            : 0,
          inventoryType: product.hasOwnProperty("InventoryType")
            ? product.InventoryType
            : "FIFO",
            previousinventoryType: product.hasOwnProperty("InventoryType")
            ? product.InventoryType
            : "",
            // InventoryType
          uom: product.hasOwnProperty("UOM") ? product.UOM : "",
          searchMode: product.hasOwnProperty("SearchMode")
            ? product.SearchMode
            : "All",
          uniqueLabel: product.hasOwnProperty("UniqueLabel")
            ? product.UniqueLabel
            : "",
          packageServices: product.hasOwnProperty("PackageServices")
            ? product.PackageServices
            : [],
          validityInDays: product.hasOwnProperty("ValidityInDays")
            ? product.ValidityInDays.toString()
            : "",
          repackProduce: product.hasOwnProperty("RepackProduce")
            ? product.RepackProduce
            : false,
          qtyPrompt: product.hasOwnProperty("QtyPrompt")
            ? product.QtyPrompt
            : false,
          associateMembership: product.hasOwnProperty("AssociateMembership")
            ? product.AssociateMembership
            : false,
          membership: product.hasOwnProperty("Membership")
            ? product.Membership
            : "",
          membershipID: product.hasOwnProperty("MembershipID")
            ? product.MembershipID
            : "",
          storeSpecificQtys: storeSpecificQtys,
          notifyCustomerInDays: product.hasOwnProperty("NotifyCustomerInDays")
            ? product.NotifyCustomerInDays
            : 0,
          enableAlerts: product.hasOwnProperty("EnableAlerts")
            ? product.EnableAlerts
            : false,
          smsTemplateID: product.hasOwnProperty("SMSTemplateID")
            ? product.SMSTemplateID
            : "",
          customField1: product.hasOwnProperty("CustomField1")
            ? product.CustomField1
            : "",
          customField2: product.hasOwnProperty("CustomField2")
            ? product.CustomField2
            : "",
          customField3: product.hasOwnProperty("CustomField3")
            ? product.CustomField3
            : "",
          customField4: product.hasOwnProperty("CustomField4")
            ? product.CustomField4
            : "",
          customField5: product.hasOwnProperty("CustomField5")
            ? product.CustomField5
            : "",
          customField6: product.hasOwnProperty("CustomField6")
            ? product.CustomField6
            : "",
          favourite: product.hasOwnProperty("Favourite")
            ? product.Favourite
            : false,
          serviceDuration: product.hasOwnProperty("ServiceDuration")
            ? product.ServiceDuration
            : 0,
          bom: product.hasOwnProperty("BOM") ? product.BOM : false,
          bomManual: product.hasOwnProperty("BOMManual")
            ? product.BOMManual
            : false,
          canBeUsedAsConsumable: product.hasOwnProperty("CanBeUsedAsConsumable")
            ? product.CanBeUsedAsConsumable
            : false,
          batchTrackingBatchNoRequired: product.hasOwnProperty(
            "BatchTrackingBatchNoRequired"
          )
            ? product.BatchTrackingBatchNoRequired
            : false,
          batchTrackingAutoBatchNoRequired: product.hasOwnProperty(
            "BatchTrackingAutoBatchNoRequired"
          )
            ? product.BatchTrackingAutoBatchNoRequired
            : false,
          batchTrackingMRPRequired: product.hasOwnProperty(
            "BatchTrackingMRPRequired"
          )
            ? product.BatchTrackingMRPRequired
            : false,
          batchTrackingMfgDateRequired: product.hasOwnProperty(
            "BatchTrackingMfgDateRequired"
          )
            ? product.BatchTrackingMfgDateRequired
            : false,
          batchTrackingExpDateRequired: product.hasOwnProperty(
            "BatchTrackingExpDateRequired"
          )
            ? product.BatchTrackingExpDateRequired
            : false,
          batchTrackingDateFormat: product.hasOwnProperty(
            "BatchTrackingDateFormat"
          )
            ? product.BatchTrackingDateFormat
            : false,
          OMNIEnabled: product.hasOwnProperty("OMNIEnabled")
            ? product.OMNIEnabled
            : false,
          outOfStock: product.hasOwnProperty("OutOfStock")
            ? product.OutOfStock
            : false,
          //UOM
          secondaryUOMs: product.hasOwnProperty("SecondaryUOMs")
            ? product.SecondaryUOMs
            : [],
          masterUOMs: masterUOMs,
          salesUOM: product.hasOwnProperty("SalesUOM") ? product.SalesUOM : "",
          pruchaseUOM: product.hasOwnProperty("PurchaseUOM")
            ? product.PurchaseUOM
            : "",
          //Variant
          variants: variants, //product.hasOwnProperty('Variants') ? product.Variants : [],
          customSKU: product.hasOwnProperty("CustomSKU")
            ? product.CustomSKU
            : "",
          autogenerateSKU: product.hasOwnProperty("AutoGenerateSKU")
            ? product.AutoGenerateSKU
            : false,
          attributes: product.hasOwnProperty("Attributes")
            ? product.Attributes
            : [],
          showAsAdditionalCharges: product.hasOwnProperty(
            "ShowAsAdditionalCharges"
          )
            ? product.ShowAsAdditionalCharges
            : false,
          packagingCharges: product.hasOwnProperty("PackagingCharges")
            ? product.PackagingCharges
            : 0,
          longDescription: product.hasOwnProperty("LongDescription")
            ? product.LongDescription
            : "",
          sequenceNo: product.hasOwnProperty("SequenceNo")
            ? product.SequenceNo
            : "",
          maxOrderQty: product.hasOwnProperty("MaxOrderQty")
            ? product.MaxOrderQty
            : "",
        });
      });
    } else {
      let storeSpecificQtys = [];
      for (let index = 0; index < stores.length; index++) {
        const obj = stores[index];
        let store = {
          StoreID: obj.key,
          StoreName: obj.StoreName,
          StoreCode: obj.StoreCode,
          MinQty: 0,
          MaxQty: 0,
          ReorderQty: 0,
          InStockQty: 0,
        };
        storeSpecificQtys.push(store);
      }
      this.setState({ storeSpecificQtys: storeSpecificQtys });
      if (
        this.props.user.user.Role.toLowerCase() !== "power user" &&
        this.props.user.user.Role.toLowerCase() !== "mswipe user"
      )
        this.setState({ accessibleToSelectedStores: true });
    }
  }

  getServices() {
    this.setState({
      services: this.props.config.Products.filter(
        (f) => !f.IsDeleted && f.ProductTypeID === 3
      ),
    });
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length > 0) {
      if (acceptedFiles.length + this.state.images.length > 4) {
        alert("Max no images uploaded");
        return;
      }
      if (acceptedFiles[0].size / 1024 > 300) {
        alert("File size should not be more than 100 KB");
        return;
      }
      this.setState({ imageLoading: true });

      let fileName = +new Date().getTime() + acceptedFiles[0].name;
      this.props
        .uploadFileToServer(
          acceptedFiles[0],
          this.props.registration.registration.StorageFolder + "/Product",
          fileName
        )
        .then((downloadURL) => {
          //update product with images url
          let bucket = "posible.in/";
          let folder =
            this.props.registration.registration.StorageFolder + "/Product/";
          let url =
            "https://storage.googleapis.com/" + bucket + folder + fileName;
          let image = {
            ImageName: fileName,
            ImageSize: acceptedFiles[0].size,
            ImagePath: url,
          };
          // {ImagePath:imagePath, ImageName: imageName, ImageSize: imageSize }
          let imagesToBeUploaded = this.state.images;
          imagesToBeUploaded.push(image);
          this.setState({ images: imagesToBeUploaded });
          this.setState({ imageLoading: false });
        });
    } else {
      alert("No file selected");
    }
  }

  onRemoveImage(index) {
    let array = [...this.state.images];
    array.splice(index, 1);
    this.setState({ images: array });
  }

  onCategoryChange = (e) => {
    let categoryID = e.target.value;
    let category = e.target.options[e.target.selectedIndex].text;
    if (categoryID !== "") {
      this.setState({ categoryError: false });
    }
    this.setState({
      category: category,
      categoryID: categoryID,
      subCategory: "",
      subCategoryID: "",
    });
    //this.setState({subCategoryList: this.props.product.Categories[categoryIndex].SubCategories})
  };

  onProductTypeChange = (value) => {
    let productTypeID = value;
    let productType =
      Number(value) > 0
        ? this.state.productTypes.filter(
          (f) => Number(f.value) === Number(value)
        )[0].text
        : "";
    if (productTypeID !== "") {
      this.setState({ productTypeError: "" });
    }
    this.setState({
      productTypeID: Number(productTypeID),
      productType: productType,
      uom: "",
      secondaryUOMs: [],
      masterUOMs: [],
      conversion: 0,
      uniqueLabel: "",
      pruchaseUOM: "",
      salesUOM: "",
      secondaryUOMValue: "",
      secondaryUOMError: "",
      conversionError: "",
      attributes: [],
      attribute: "",
      attributeValue: "",
      attributeValues: [],
      selectedAttribute: null,
      variants: [],
      autogenerateSKU: false,
      batchTrackingBatchNoRequired: false,
      batchTrackingMfgDateRequired: false,
      batchTrackingMRPRequired: false,
      batchTrackingExpDateRequired: false,
    });
  };

  onSubCategoryChange = (e) => {
    let subCategoryID = e.target.value;
    let subCategory = e.target.options[e.target.selectedIndex].text;
    this.setState({ subCategory: subCategory, subCategoryID: subCategoryID });
  };

  onBrandChange(e) {
    let brandID = e.target.value;
    let brand = e.target.options[e.target.selectedIndex].text;
    this.setState({ brand: brand, brandID: brandID });
    //console.log('onBrandChange',e.target.value )
  }

  onTaxChange(e) {
    let taxID = e.target.value;
    let tax = e.target.options[e.target.selectedIndex].text;
    this.setState({ taxGroup: tax, taxID: taxID });
    //console.log('onTaxChange',e.target.value )
  }

  onCancel() {
    if (this.props.calledFromInvoice) {
      // console.log('Called from invoice ')
      this.props.onClose();
    } else {
      // console.log('Not called fomm invoice ')
      this.props.history.push("/home/product");
    }
  }

  async getProductInStockQty(productId) {
    let data = [];
    return database
      .collection("InventoryInStock")
      // .where("StoreID", "==", storeID)
      // .where("LocationID", "==", locationID)
      .where("ProductID", "==", productId)
      // .limit(1)
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          // console.log('doc.data()', doc.data())
          const stockdata=doc.data()
          data.push(stockdata)
          // data = doc.data()
        });
        return data;
      });
    // console.log('inStockQty',inStockQty)
  };


  async onSaveProduct() {
    // console.log('save called')
    this.setState({ saving: true });
    let nameError = "";
    let categoryError = false;
    let priceError = "";
    let skuError = "";
    let priceBasedTaxError = false;
    let productTypeError = "";
    let walletTopUpError = "";
    let supplyPriceError = "";
    let markupError = "";
    let storeSpecificPricesError = "";
    let packageServicesError = "";
    let validityInDaysError = "";
    let membershipError = "";
    let notifyCustomerInDaysError = "";
    let smsTemplateError = "";
    let variantsError = "";
    let variantSKUError = "";
    let mrpError = "";
    let storeSpecificPrices = this.state.storeSpecificPrices;
    let storeSpecificPricesLineItemErrors = false;

    if (this.state.productTypeID === 0) {
      productTypeError = "Please select product type";
    }
    if (this.state.name === "") {
      nameError = "Please provide product name";
      // console.log('nameError',nameError);
    }
    if (this.state.retailPrice < 0) {
      priceError = "Retail price cannot be negative";
    }
    if (
      this.state.productTypeID !== 2 &&
      !this.state.allowPriceEdit &&
      !this.state.accessibleToSelectedStores &&
      !this.state.storeSpecificPricing &&
      this.state.retailPrice === 0
    ) {
      // if()
      priceError = "Please provide retail price";
      // console.log('priceError',priceError);
    }
    if (this.state.supplyPrice < 0) {
      supplyPriceError = "Supply price cannot be negative";
      // console.log('supplyPriceError',supplyPriceError);
    }
    if (this.state.markup < 0) {
      markupError = "Markup cannot be negative";
      // console.log('markupError',markupError);
    }
    if (this.state.category === "") {
      categoryError = true;
      // console.log('categoryError',markupError);
    }
    if (this.state.sku === "") {
      skuError = "Please provide SKU";
      // console.log('skuError',skuError);
    }
    if (
      this.state.productTypeID === 4 &&
      !this.state.walletTopUpSameAsRetailPrice &&
      this.state.walletTopUpAmount === 0
    ) {
      walletTopUpError = "Please provide wallet recharge value";
      // console.log('walletTopUpError',walletTopUpError);
    }
    // if (this.state.enableAlerts && this.state.smsTemplateID === '') {
    //     smsTemplateError = 'Please select SMS Template';
    //     // console.log('smsTemplateError',smsTemplateError);
    // }
    if (this.state.enableAlerts && this.state.notifyCustomerInDays <= 0) {
      notifyCustomerInDaysError = "Notify customer in days cannot be zero";
      // console.log('notifyCustomerInDaysError',notifyCustomerInDaysError);
    }
    if (this.state.enableAlerts && this.state.notifyCustomerInDays > 365) {
      notifyCustomerInDaysError =
        "Notify customer in days cannot be more than 365";
      // console.log('notifyCustomerInDaysError',notifyCustomerInDaysError);
    }
    if (this.state.priceBasedTax) {
      let taxPriceRange = this.state.taxPriceRange;
      this.state.taxPriceRange.map((t, index) => {
        if (index > 0 && t.FromPrice <= 0) {
          taxPriceRange[index].FromPriceErrMsg =
            "Value cannot be 0 or negative";
          priceBasedTaxError = true;
        }
        if (t.ToPrice <= 0) {
          taxPriceRange[index].ToPriceErrMsg = "Value cannot be 0 or negative";
          priceBasedTaxError = true;
        }
        if (t.TaxID === "") {
          taxPriceRange[index].TaxErrMsg = "Please select tax";
          priceBasedTaxError = true;
        }
      });
      this.setState({ taxPriceRange: taxPriceRange });
    }
    if (this.state.accessibleToSelectedStores) {
      if (this.state.storeSpecificPrices.length === 0) {
        storeSpecificPricesError = "Please specify store prices";
        // console.log('storeSpecificPricesError',storeSpecificPricesError)
      }
    }
    if (this.state.productTypeID === 5) {
      if (this.state.validityInDays.trim() === "") {
        validityInDaysError = "Please provide validity in day's";
        // console.log('validityInDaysError',validityInDaysError)
      }
      if (this.state.packageServices.length === 0) {
        packageServicesError = "Please select atleast one service.";
        // console.log('packageServicesError',packageServicesError)
      }
    }
    if (
      this.state.associateMembership &&
      this.state.membershipID.trim() === ""
    ) {
      membershipError = "Please select membership";
      // console.log('membershipError',membershipError)
    }
    //check SKU already exits
    if (
      this.props.config.Products.filter(
        (e) =>
          e.SKU &&
          this.state.sku.trim().toLowerCase() === e.SKU.trim().toLowerCase() &&
          !e.IsDeleted &&
          e.key !== this.props.match.params.key
      ).length > 0 &&
      !this.state.productAutoGenerateSKU
    ) {
      skuError = "SKU already exists";
      // console.log('skuError',skuError)
    }
    //variant
    if (this.state.inventoryType.toLowerCase() === "variants") {
      if (this.state.variants.length === 0) {
        variantsError = "Please specify variants";
        alert(variantsError);
        return;
      }
      let variantCount = this.state.variants.filter((f) => !f.IsDeleted).length;
      if (variantCount === 0) {
        variantsError = "Please specify variants";
        alert(variantsError);
        return;
      }
      if (this.state.variants.length > 0) {
        if (!this.state.autogenerateSKU) {
          // console.log("variants", this.state.variants);
          let skuCodes = [];
          //let duplicateFound = false;
          for (let index = 0; index < this.state.variants.length; index++) {
            const variant = this.state.variants[index];
            if (!variant.IsDeleted && variant.SKU.trim() === "") {
              variant.SKUError = "Please specify SKU";
              variantSKUError = "Please specify SKU";
            } else {
              if (skuCodes.includes(variant.SKU)) {
                variantSKUError = "SKU already exists";
                variant.SKUError = "SKU already exists";
              } else {
                skuCodes.push(variant.SKU);
              }
            }
          }
        }
      }
    }
    if (this.props.registration.registration.EnableONDC) {
      if (
        this.state.productTypeID !== 2 &&
        !this.state.accessibleToSelectedStores
      ) {
        if (Number(this.state.retailPrice) === 0) {
          priceError = "Please provide retail price";
        }
        if (Number(this.state.MRP) === 0) {
          mrpError = "Please provide MRP";
        }
        if (Number(this.state.retailPrice) > Number(this.state.MRP)) {
          priceError = "Retail price can not be more than MRP";
        }
      }
      if (
        this.state.productTypeID !== 2 &&
        this.state.accessibleToSelectedStores
      ) {
        //check line items
        if (this.state.storeSpecificPrices.length === 0) {
          storeSpecificPricesError = "Please specify store prices";
          // console.log('storeSpecificPricesError',storeSpecificPricesError)
        } else {
          storeSpecificPrices.map((m) => {
            let priceError = "";
            let mrpError = "";
            if (Number(m.RetailPrice) === 0) {
              priceError = "Please provide retail price";
            }
            if (Number(m.MRP) === 0) {
              mrpError = "Please provide MRP";
            }
            if (Number(m.RetailPrice) > Number(m.MRP)) {
              priceError = "Retail price can not be more than MRP";
            }
            m.PriceError = priceError;
            m.MRPError = mrpError;
            if (priceError !== "" || mrpError !== "") {
              storeSpecificPricesLineItemErrors = true;
            }
          });
        }
      }
    }
    if (
      nameError.trim() !== "" ||
      mrpError !== "" ||
      priceError !== "" ||
      supplyPriceError !== "" ||
      markupError !== "" ||
      categoryError ||
      skuError !== "" ||
      priceBasedTaxError ||
      productTypeError !== "" ||
      walletTopUpError !== "" ||
      storeSpecificPricesError !== "" ||
      packageServicesError !== "" ||
      notifyCustomerInDaysError !== "" ||
      smsTemplateError != "" ||
      validityInDaysError !== "" ||
      membershipError !== "" ||
      variantsError !== "" ||
      variantSKUError !== "" ||
      storeSpecificPricesLineItemErrors
    ) {
      // console.log('save error');
      // console.log('priceBasedTaxError', priceBasedTaxError)
      this.setState({
        nameError: nameError,
        priceError: priceError,
        supplyPriceError: supplyPriceError,
        markupError: markupError,
        categoryError: categoryError,
        skuError: skuError,
        productTypeError: productTypeError,
        walletTopUpError: walletTopUpError,
        storeSpecificPricesError: storeSpecificPricesError,
        packageServicesError: packageServicesError,
        validityInDaysError: validityInDaysError,
        membershipError: membershipError,
        notifyCustomerInDaysError: notifyCustomerInDaysError,
        smsTemplateError: smsTemplateError,
        mrpError: mrpError,
        variantsError: variantsError,
        variantSKUError: variantSKUError,
        storeSpecificPrices: storeSpecificPrices,
        saving: false,
      });
      return;
    }

    const key = this.props.match.params.key;
    // console.log('key', key)
    let sku = this.state.sku.trim();
    if (this.state.productAutoGenerateSKU && !key) {
      await this.props.getRegistration(
        this.props.registration.registration.key
      );
      sku = this.props.registration.registration.hasOwnProperty(
        "AutoGenerateSKUPrefix"
      )
        ? this.props.registration.registration.AutoGenerateSKUPrefix
        : "";
      // console.log('sku', sku)
      let no = this.props.registration.registration.hasOwnProperty(
        "AutoGenerateSKURunningNo"
      )
        ? this.props.registration.registration.AutoGenerateSKURunningNo
        : 1;
      if (
        this.props.registration.registration.hasOwnProperty(
          "AutoGenerateSKUPaddedZero"
        ) &&
        this.props.registration.registration.AutoGenerateSKUPaddedZero > 0
      ) {
        let padded = ("0000000000" + no).slice(
          -1 *
          Number(
            this.props.registration.registration.AutoGenerateSKUPaddedZero
          )
        );
        sku += padded;
      } else {
        sku += no;
      }
    }
    let longDescription = "";
    if (this.props.registration.registration.EnableONDC) {
      longDescription = this.state.longDescription;
    } else {
      longDescription = this.editorRef.current.getContent();
    }

    // console.log('longDescription',longDescription)
    let product = {
      RegistrationID: this.props.user.user.RegistrationID,
      Name: this.state.name.trim(),
      ProductTypeID: this.state.productTypeID,
      ProductType: this.state.productType,
      Description: this.state.description,
      SKU: sku,
      BarCode: this.state.barCode,
      Category: this.state.category,
      CategoryID: this.state.categoryID,
      SubCategoryID: this.state.subCategoryID,
      SubCategory: this.state.subCategory,
      //Tags:this.state.tags,
      BrandID: this.state.brandID,
      Brand: this.state.brand,
      SupplyPrice: this.state.supplyPrice,
      Markup: this.state.markup,
      RetailPrice: this.state.retailPrice,
      MRP: this.state.MRP,
      MSP: this.state.MSP,
      WalletTopUpSameAsRetailPrice: this.state.walletTopUpSameAsRetailPrice,
      WalletTopUpAmount: this.state.walletTopUpSameAsRetailPrice
        ? this.state.retailPrice
        : this.state.walletTopUpAmount,
      AllowDiscount: this.state.allowDiscount,
      AllowPriceEdit: this.state.allowPriceEdit,
      AllowTaxChange: this.state.allowTaxChange,
      ChargeTaxOnProduct: this.state.chargeTaxOnPoduct,
      TaxInclusive: this.state.taxInclusive,
      TaxInclusivePurchase: this.state.taxInclusivePurchase,
      HSNSACCode: this.state.HSNSACCode,
      TaxID: this.state.taxID,
      TaxGroup: this.state.taxGroup,
      PriceBasedTax: this.state.priceBasedTax,
      TaxPriceRange: this.state.priceBasedTax ? this.state.taxPriceRange : [],
      Images: this.state.images,
      IsDeleted: false,
      EditWalletTopUpAmount: this.state.editWalletTopUpAmount,
      AccessibleToSelectedStores: this.state.accessibleToSelectedStores,
      StoreSpecificPricing: this.state.storeSpecificPricing,
      StoreSpecificPrices: this.state.storeSpecificPrices,
      DiscountAmount: this.state.discountAmount,
      DiscountPercentage: this.state.discountPercentage,
      MinQty: this.state.minQty,
      MaxQty: this.state.maxQty,
      ReorderQty: this.state.reorderQty,
      UniqueLabel: this.state.uniqueLabel,
      InventoryType: this.state.inventoryType,
      UOM: this.state.uom,
      SearchMode: this.state.searchMode,
      RepackProduce: this.state.repackProduce,
      //Package
      PackageServices: this.state.packageServices,
      ValidityInDays: Number(this.state.validityInDays),
      QtyPrompt: this.state.qtyPrompt,
      Membership: this.state.membership,
      MembershipID: this.state.membershipID,
      AssociateMembership: this.state.associateMembership,
      StoreSpecificQtys: this.state.storeSpecificQtys,
      EnableAlerts: this.state.enableAlerts,
      NotifyCustomerInDays: Number(this.state.notifyCustomerInDays),
      SMSTemplateID: this.state.smsTemplateID,
      CustomField1: this.state.customField1,
      CustomField2: this.state.customField2,
      CustomField3: this.state.customField3,
      CustomField4: this.state.customField4,
      CustomField5: this.state.customField5,
      CustomField6: this.state.customField6,
      Favourite: this.state.favourite,
      ServiceDuration: this.state.serviceDuration,
      BOM: this.state.bom,
      BOMManual: this.state.bomManual,
      CanBeUsedAsConsumable: this.state.canBeUsedAsConsumable,
      BatchTrackingBatchNoRequired: this.state.batchTrackingBatchNoRequired,
      BatchTrackingAutoBatchNoRequired: this.state
        .batchTrackingAutoBatchNoRequired,
      BatchTrackingMRPRequired: this.state.batchTrackingMRPRequired,
      BatchTrackingMfgDateRequired: this.state.batchTrackingMfgDateRequired,
      BatchTrackingExpDateRequired: this.state.batchTrackingExpDateRequired,
      BatchTrackingDateFormat: this.state.batchTrackingDateFormat,
      OMNIEnabled: this.state.OMNIEnabled,
      OutOfStock: this.state.outOfStock,
      //UOMs
      SecondaryUOMs: this.state.secondaryUOMs,
      SalesUOM: this.state.salesUOM,
      PurchaseUOM: this.state.pruchaseUOM,
      //Variants
      Attributes: this.state.attributes,
      Variants: this.state.variants,
      // VariantsNew: this.state.variants,
      AutoGenerateSKU: this.state.autogenerateSKU,
      CustomSKU: this.state.sku.trim(),
      ShowAsAdditionalCharges: this.state.showAsAdditionalCharges,
      PackagingCharges: this.state.packagingCharges,
      LongDescription: longDescription,
      SequenceNo: this.state.sequenceNo,
      MaxOrderQty: this.state.maxOrderQty,
    };
    let omniTag = "",
      prodName = "",
      prodSKU = "";
    prodName = product.Name.replace(
      /[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g,
      ""
    ).replace(/[' ']/g, "_");
    prodSKU = product.SKU.replace(
      /[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g,
      ""
    ).replace(/[' ']/g, "_");
    omniTag = prodName + "_" + prodSKU;
    product.OMNITag = omniTag;
    if (key) {
      //update product details

      const productinstockqty = await this.getProductInStockQty(key)
      const batches=[]
       productinstockqty.forEach((getproductinstockqty)=>{
        // console.log("getproductinstockqty",getproductinstockqty)
        let batch = {
          ActionOn:  firebase.firestore.FieldValue.serverTimestamp(), // (string)
          BarCode:getproductinstockqty.BarCode?getproductinstockqty.BarCode:"", // (string)
          BatchMRP:getproductinstockqty.MRP?getproductinstockqty.MRP:0, // (number)
          BatchMfgDate: "", // (string)
          BatchMfgDateValue: null, // (null)
          BatchNo: "", // (string)
          BatchRetailPrice:getproductinstockqty.RetailPrice?getproductinstockqty.RetailPrice:0, // (number)
          Brand:getproductinstockqty.Brand?getproductinstockqty.Brand: "", // (string)
          Category:getproductinstockqty.Category?getproductinstockqty.Category:"", // (string)
          ProductCustomField1:getproductinstockqty.ProductCustomField1?getproductinstockqty.ProductCustomField1:"", // (string)
          ProductCustomField2:getproductinstockqty.ProductCustomField2?getproductinstockqty.ProductCustomField2:"", // (string)
          ProductCustomField3:getproductinstockqty.ProductCustomField3?getproductinstockqty.ProductCustomField3:"", // (string)
          ProductCustomField4:getproductinstockqty.ProductCustomField4?getproductinstockqty.ProductCustomField4:"", // (string)
          ProductCustomField5:getproductinstockqty.ProductCustomField5?getproductinstockqty.ProductCustomField5:"", // (string)
          ProductCustomField6:getproductinstockqty.ProductCustomField6?getproductinstockqty.ProductCustomField6:"", // (string)
          ProductID:key, // (string)
          ProductName:getproductinstockqty.ProductName?getproductinstockqty.ProductName:"", // (string)
          ProductSKU:getproductinstockqty.ProductSKU?getproductinstockqty.ProductSKU:"", // (string)
          Qty: getproductinstockqty.InStockQty?getproductinstockqty.InStockQty:0, // (number)
          StoreCode:getproductinstockqty.StoreCode?getproductinstockqty.StoreCode:"", // (string)
          StoreID:getproductinstockqty.StoreID?getproductinstockqty.StoreID:"", // (string)
          StoreLocation:getproductinstockqty.Location?getproductinstockqty.Location:"", // (string)       
          StoreLocationID:getproductinstockqty.LocationID?getproductinstockqty.LocationID:"", // (string)
          StoreName:getproductinstockqty.StoreName?getproductinstockqty.StoreName:"", // (string)
          SubCategory:getproductinstockqty.SubCategory?getproductinstockqty.SubCategory:"", // (string)
          SupplyPrice:getproductinstockqty.SupplyPrice?getproductinstockqty.SupplyPrice:"", // (number)
          TransactionID:getproductinstockqty.TransactionKey?getproductinstockqty.TransactionKey:"", // (string)
          TransactionNo:getproductinstockqty.TransactionNo?getproductinstockqty.TransactionNo:"", // (string)
          TransactionQty:getproductinstockqty.TransactionQty?getproductinstockqty.TransactionQty: 0 // (number)
        };
        batches.push(batch)

       })
   
      
    // console.log("product", product, key);
    // console.log("getproductinstockqty", getproductinstockqty,
    //   "batch",batch

    // )
    this.props.updateProduct(product, key).then(async () => {
      // console.log("product",product)
      await this.createVariants(product, key);
      await this.props.pushProductToRedux({ ...product, key: key });
      await this.props.poupulateSaleSubCategories();
      await this.props.poupulateSaleCategories();
      let batchId="";
      if(this.state.previousinventoryType=="FIFO"&&this.state.inventoryType=="Batch Tracking"){
       
        for (let i = 0; i < batches.length; i++) {
          let batch=batches[i]
         // console.log("batch",batch)
          batchId=await this.props.addBatches(batch)
        }

      }
      console.log("batchId",batchId)
     
      this.props.getToastr("Product Saved Successfully");
      setTimeout(
        function () {
          //Start the timer
          this.setState({ saving: false });
          // if(this.props.calledFromInvoice){
          // }
          this.props.history.push({ pathname: "/home/product" });
        }.bind(this),
        2000
      );
    });
  } else {
  //create new product
  // console.log('product',product);
  let key = await this.props.addNewProduct(product);
  await this.createVariants(product, key);
  await this.props.pushProductToRedux({ ...product, key: key });
  await this.props.poupulateSaleSubCategories();
  await this.props.poupulateSaleCategories();
  if (this.state.productAutoGenerateSKU) {
    let autoGenerateSKURunningNo =
      Number(
        this.props.registration.registration.AutoGenerateSKURunningNo
      ) + 1;
    await this.props.updateRegistrationGeneralDetails(
      this.props.registration.registration.key,
      { AutoGenerateSKURunningNo: autoGenerateSKURunningNo }
    );
    this.props.getRegistration(this.props.registration.registration.key);
  }
  // console.log('key',key)
  this.props.getToastr("Product Saved Successfully");
  setTimeout(
    function () {
      //Start the timer
      if (this.props.calledFromInvoice) {
        //console.log('Called fomm invoice ')
        this.props.onSave({ ...product, key: key });
      } else {
        this.props.history.push("/home/product");
      }
    }.bind(this),
    2000
  );
}
  }

  async createVariants(product, productID) {
  let productUpdated = false;
  if (
    product.hasOwnProperty("Variants") &&
    product.Variants &&
    product.Variants.length > 0 &&
    !product.IsDeleted
  ) {
    for (let p = 0; p < product.Variants.length; p++) {
      const variant = product.Variants[p];
      // console.log('ctr p', p);
      if (variant.ProductID === "") {
        // console.log('new variant')
        let skuCode = "";
        if (
          product.hasOwnProperty("AutoGenerateSKU") &&
          product.AutoGenerateSKU
        ) {
          skuCode = product.CustomSKU + p; //+ "_ABC";
        } else {
          skuCode = variant.SKU; // + "_ABC";
        }
        let variantProduct = {
          RegistrationID: product.RegistrationID,
          ProductTypeID: product.ProductTypeID,
          ProductType: product.ProductType,
          Description: product.Description,
          BarCode: product.BarCode,
          Category: product.Category,
          CategoryID: product.CategoryID,
          SubCategoryID: product.SubCategoryID,
          SubCategory: product.SubCategory,
          BrandID: product.BrandID,
          Brand: product.Brand,
          Markup: product.Markup,
          WalletTopUpSameAsRetailPrice: product.WalletTopUpSameAsRetailPrice,
          WalletTopUpAmount: product.WalletTopUpAmount,
          AllowDiscount: product.AllowDiscount,
          AllowPriceEdit: product.AllowPriceEdit,
          AllowTaxChange: product.AllowTaxChange,
          ChargeTaxOnProduct: product.ChargeTaxOnProduct,
          TaxInclusive: product.TaxInclusive,
          TaxInclusivePurchase: product.TaxInclusivePurchase,
          HSNSACCode: product.HSNSACCode,
          TaxID: product.TaxID,
          TaxGroup: product.TaxGroup,
          PriceBasedTax: product.PriceBasedTax,
          TaxPriceRange: product.TaxPriceRange,
          Images: product.Images,
          IsDeleted: variant.hasOwnProperty("IsDeleted")
            ? variant.IsDeleted
            : false,

          //IsDeleted: false,
          EditWalletTopUpAmount: product.EditWalletTopUpAmount,
          AccessibleToSelectedStores: product.AccessibleToSelectedStores,
          StoreSpecificPricing: product.StoreSpecificPricing,
          StoreSpecificPrices: product.StoreSpecificPrices,
          MinQty: product.MinQty,
          MaxQty: product.MaxQty,
          ReorderQty: product.ReorderQty,
          UniqueLabel: product.UniqueLabel,
          InventoryType: "FIFO",
          UOM: product.hasOwnProperty("UOM") ? product.UOM : "",
          SearchMode: product.hasOwnProperty("SearchMode")
            ? product.SearchMode
            : "",
          RepackProduce: product.hasOwnProperty("RepackProduce")
            ? product.RepackProduce
            : false,
          //Package
          PackageServices: product.hasOwnProperty("PackageServices")
            ? product.PackageServices
            : [],
          ValidityInDays: product.hasOwnProperty("ValidityInDays")
            ? product.ValidityInDays.toString()
            : "",
          QtyPrompt: product.hasOwnProperty("QtyPrompt")
            ? product.QtyPrompt
            : false,
          Membership: product.hasOwnProperty("Membership")
            ? product.Membership
            : "",
          MembershipID: product.hasOwnProperty("MembershipID")
            ? product.MembershipID
            : "",
          AssociateMembership: product.hasOwnProperty("AssociateMembership")
            ? product.AssociateMembership
            : false,
          StoreSpecificQtys: product.hasOwnProperty("StoreSpecificQtys")
            ? product.StoreSpecificQtys
            : [],
          EnableAlerts: product.hasOwnProperty("EnableAlerts")
            ? product.EnableAlerts
            : false,
          NotifyCustomerInDays: product.hasOwnProperty("NotifyCustomerInDays")
            ? product.NotifyCustomerInDays
            : 0,
          SMSTemplateID: product.hasOwnProperty("SMSTemplateID")
            ? product.SMSTemplateID
            : "",
          CustomField1: product.hasOwnProperty("CustomField1")
            ? product.CustomField1
            : "",
          CustomField2: product.hasOwnProperty("CustomField2")
            ? product.CustomField2
            : "",
          CustomField3: product.hasOwnProperty("CustomField3")
            ? product.CustomField3
            : "",
          CustomField4: product.hasOwnProperty("CustomField4")
            ? product.CustomField4
            : "",
          CustomField5: product.hasOwnProperty("CustomField5")
            ? product.CustomField5
            : "",
          CustomField6: product.hasOwnProperty("CustomField6")
            ? product.CustomField6
            : "",
          Favourite: product.hasOwnProperty("Favourite")
            ? product.Favourite
            : false,
          ServiceDuration: product.hasOwnProperty("ServiceDuration")
            ? product.ServiceDuration
            : 0,
          BOM: product.hasOwnProperty("BOM") ? product.BOM : false,
          BOMManual: product.hasOwnProperty("BOMManual")
            ? product.BOMManual
            : false,
          CanBeUsedAsConsumable: product.hasOwnProperty(
            "CanBeUsedAsConsumable"
          )
            ? product.CanBeUsedAsConsumable
            : false,
          BatchTrackingBatchNoRequired: product.hasOwnProperty(
            "BatchTrackingBatchNoRequired"
          )
            ? product.BatchTrackingBatchNoRequired
            : false,
          BatchTrackingAutoBatchNoRequired: product.hasOwnProperty(
            "BatchTrackingAutoBatchNoRequired"
          )
            ? product.BatchTrackingAutoBatchNoRequired
            : false,
          BatchTrackingMRPRequired: product.hasOwnProperty(
            "BatchTrackingMRPRequired"
          )
            ? product.BatchTrackingMRPRequired
            : false,
          BatchTrackingMfgDateRequired: product.hasOwnProperty(
            "BatchTrackingMfgDateRequired"
          )
            ? product.BatchTrackingMfgDateRequired
            : false,
          BatchTrackingExpDateRequired: product.hasOwnProperty(
            "BatchTrackingExpDateRequired"
          )
            ? product.BatchTrackingExpDateRequired
            : false,
          BatchTrackingDateFormat: product.hasOwnProperty(
            "BatchTrackingDateFormat"
          )
            ? product.BatchTrackingDateFormat
            : false,
          OMNIEnabled: product.hasOwnProperty("OMNIEnabled")
            ? product.OMNIEnabled
            : false,

          //UOMs
          SecondaryUOMs: product.hasOwnProperty("SecondaryUOMs")
            ? product.SecondaryUOMs
            : [],
          SalesUOM: product.hasOwnProperty("SalesUOM")
            ? product.SalesUOM
            : "",
          PurchaseUOM: product.hasOwnProperty("PurchaseUOM")
            ? product.PurchaseUOM
            : "",

          //Variants
          Attributes: [],
          Variants: [],
          AutoGenerateSKU: false,
          CustomSKU: "",
          Name: product.Name.trim() + " / " + variant.Name,
          ParentProductName: product.Name.trim(),
          ParentProductID: productID,
          SKU: skuCode,
          SupplyPrice: variant.SupplyPrice,
          RetailPrice: variant.RetailPrice,
          MRP: variant.MRP,
          DiscountAmount: variant.DiscountAmount,
          DiscountPercentage: variant.DiscountPercentage,

          Attribute1: variant.Attribute1,
          Attribute2: variant.Attribute2,
          Attribute3: variant.Attribute3,

          ActionOn: product.ActionOn,
          ActionBy: product.ActionBy,
          ActionByUID: product.ActionByUID,
          ActionByEmailID: product.ActionByEmailID,
          Action: product.Action,
        };

        let variantID = await this.props.addNewProduct(variantProduct);
        // console.log("variantID", variantID);
        product.Variants[p].ProductID = variantID;
        product.Variants[p].SKU = skuCode;
        productUpdated = true;
      } else if (variant.ProductID !== "") {
        // console.log('update variant')

        let variantProduct = {
          //Variant
          Name: product.Name.trim() + " / " + variant.Name,
          SupplyPrice: variant.SupplyPrice,
          RetailPrice: variant.RetailPrice,
          MRP: variant.MRP,
          DiscountAmount: variant.DiscountAmount,
          DiscountPercentage: variant.DiscountPercentage,
          Attribute1: variant.Attribute1,
          Attribute2: variant.Attribute2,
          Attribute3: variant.Attribute3,

          Category: product.Category,
          CategoryID: product.CategoryID,
          SubCategoryID: product.SubCategoryID,
          SubCategory: product.SubCategory,
          BrandID: product.BrandID,
          Brand: product.Brand,
          Markup: product.Markup,
          WalletTopUpSameAsRetailPrice: product.WalletTopUpSameAsRetailPrice,
          WalletTopUpAmount: product.WalletTopUpAmount,
          AllowDiscount: product.AllowDiscount,
          AllowPriceEdit: product.AllowPriceEdit,
          AllowTaxChange: product.AllowTaxChange,
          ChargeTaxOnProduct: product.ChargeTaxOnProduct,
          TaxInclusive: product.TaxInclusive,
          TaxInclusivePurchase: product.TaxInclusivePurchase,
          HSNSACCode: product.HSNSACCode,
          TaxID: product.TaxID,
          TaxGroup: product.TaxGroup,
          PriceBasedTax: product.PriceBasedTax,
          TaxPriceRange: product.TaxPriceRange,
          Images: product.Images,
          IsDeleted: variant.hasOwnProperty("IsDeleted")
            ? variant.IsDeleted
            : false,

          EditWalletTopUpAmount: product.EditWalletTopUpAmount,
          AccessibleToSelectedStores: product.AccessibleToSelectedStores,
          StoreSpecificPricing: product.StoreSpecificPricing,
          StoreSpecificPrices: product.StoreSpecificPrices,
          MinQty: product.MinQty,
          MaxQty: product.MaxQty,
          ReorderQty: product.ReorderQty,
          UniqueLabel: product.UniqueLabel,

          UOM: product.hasOwnProperty("UOM") ? product.UOM : "",
          SearchMode: product.hasOwnProperty("SearchMode")
            ? product.SearchMode
            : "",
          RepackProduce: product.hasOwnProperty("RepackProduce")
            ? product.RepackProduce
            : false,

          //Package
          PackageServices: product.hasOwnProperty("PackageServices")
            ? product.PackageServices
            : [],
          ValidityInDays: product.hasOwnProperty("ValidityInDays")
            ? product.ValidityInDays.toString()
            : "",
          QtyPrompt: product.hasOwnProperty("QtyPrompt")
            ? product.QtyPrompt
            : false,
          Membership: product.hasOwnProperty("Membership")
            ? product.Membership
            : "",
          MembershipID: product.hasOwnProperty("MembershipID")
            ? product.MembershipID
            : "",
          AssociateMembership: product.hasOwnProperty("AssociateMembership")
            ? product.AssociateMembership
            : false,
          StoreSpecificQtys: product.hasOwnProperty("StoreSpecificQtys")
            ? product.StoreSpecificQtys
            : [],
          EnableAlerts: product.hasOwnProperty("EnableAlerts")
            ? product.EnableAlerts
            : false,
          NotifyCustomerInDays: product.hasOwnProperty("NotifyCustomerInDays")
            ? product.NotifyCustomerInDays
            : 0,
          SMSTemplateID: product.hasOwnProperty("SMSTemplateID")
            ? product.SMSTemplateID
            : "",
          CustomField1: product.hasOwnProperty("CustomField1")
            ? product.CustomField1
            : "",
          CustomField2: product.hasOwnProperty("CustomField2")
            ? product.CustomField2
            : "",
          CustomField3: product.hasOwnProperty("CustomField3")
            ? product.CustomField3
            : "",
          CustomField4: product.hasOwnProperty("CustomField4")
            ? product.CustomField4
            : "",
          CustomField5: product.hasOwnProperty("CustomField5")
            ? product.CustomField5
            : "",
          CustomField6: product.hasOwnProperty("CustomField6")
            ? product.CustomField6
            : "",
          Favourite: product.hasOwnProperty("Favourite")
            ? product.Favourite
            : false,
          ServiceDuration: product.hasOwnProperty("ServiceDuration")
            ? product.ServiceDuration
            : 0,
          BOM: product.hasOwnProperty("BOM") ? product.BOM : false,
          BOMManual: product.hasOwnProperty("BOMManual")
            ? product.BOMManual
            : false,
          CanBeUsedAsConsumable: product.hasOwnProperty(
            "CanBeUsedAsConsumable"
          )
            ? product.CanBeUsedAsConsumable
            : false,

          BatchTrackingBatchNoRequired: product.hasOwnProperty(
            "BatchTrackingBatchNoRequired"
          )
            ? product.BatchTrackingBatchNoRequired
            : false,
          BatchTrackingAutoBatchNoRequired: product.hasOwnProperty(
            "BatchTrackingAutoBatchNoRequired"
          )
            ? product.BatchTrackingAutoBatchNoRequired
            : false,
          BatchTrackingMRPRequired: product.hasOwnProperty(
            "BatchTrackingMRPRequired"
          )
            ? product.BatchTrackingMRPRequired
            : false,
          BatchTrackingMfgDateRequired: product.hasOwnProperty(
            "BatchTrackingMfgDateRequired"
          )
            ? product.BatchTrackingMfgDateRequired
            : false,
          BatchTrackingExpDateRequired: product.hasOwnProperty(
            "BatchTrackingExpDateRequired"
          )
            ? product.BatchTrackingExpDateRequired
            : false,
          BatchTrackingDateFormat: product.hasOwnProperty(
            "BatchTrackingDateFormat"
          )
            ? product.BatchTrackingDateFormat
            : false,
          OMNIEnabled: product.hasOwnProperty("OMNIEnabled")
            ? product.OMNIEnabled
            : false,

          //UOMs
          SecondaryUOMs: product.hasOwnProperty("SecondaryUOMs")
            ? product.SecondaryUOMs
            : [],
          SalesUOM: product.hasOwnProperty("SalesUOM")
            ? product.SalesUOM
            : "",
          PurchaseUOM: product.hasOwnProperty("PurchaseUOM")
            ? product.PurchaseUOM
            : "",

          ActionOn: product.ActionOn,
          ActionBy: product.ActionBy,
          ActionByUID: product.ActionByUID,
          ActionByEmailID: product.ActionByEmailID,
          Action: "EDIT",
        };
        await this.props.updateProduct(variantProduct, variant.ProductID);
      }
    }
    if (productUpdated) {
      // console.log('update parent product', product);
      // console.log('productID',productID)
      await this.props.updateProduct(product, productID);
      // await this.props.updateProductVariants(product.Variants, productID)
      //await admin.firestore().collection("Products").doc(productID).update(product);
    }
  }
}

loadProductBrands(brandID) {
  this.props
    .getProductBrands(actionTypes, this.props.user.user.RegistrationID)
    .then((serachResult) => {
      this.setState({
        brandList: serachResult.searchResult,
        brandID: brandID,
      });
    });
}

onFromPriceChange(index, value) {
  let taxPriceRange = this.state.taxPriceRange;
  taxPriceRange[index].FromPrice = value;
  taxPriceRange[index].FromPriceErrMsg = "";
  this.setState({ taxPriceRange: taxPriceRange });
}

onToPriceChange(index, value) {
  let taxPriceRange = this.state.taxPriceRange;
  taxPriceRange[index].ToPrice = value;
  taxPriceRange[index].ToPriceErrMsg = "";
  this.setState({ taxPriceRange: taxPriceRange });
}

onPriceBasedTaxChange(index, evt) {
  let taxPriceRange = this.state.taxPriceRange;
  taxPriceRange[index].TaxID = evt.target.value;
  taxPriceRange[index].TaxGroup =
    evt.target.options[evt.target.selectedIndex].text;
  taxPriceRange[index].TaxErrMsg = "";
  this.setState({ taxPriceRange: taxPriceRange });
}

onAddStoreSpecificPrice() {
  let storeSpecificPricesError = "";
  let storeRetailPriceError = "";
  let storeMRPError = "";

  if (this.state.storePriceStoreID.trim() === "") {
    storeSpecificPricesError = "Please select store";
  }
  //check if selected store is already added to list
  if (
    this.state.storeSpecificPrices.filter(
      (f) => f.StoreID === this.state.storePriceStoreID
    ).length > 0
  ) {
    storeSpecificPricesError = "Selected store already added";
  }
  if (this.props.registration.registration.EnableONDC) {
    if (
      this.state.productTypeID !== 2 &&
      this.state.accessibleToSelectedStores
    ) {
      if (Number(this.state.storeRetailPrice) === 0) {
        storeRetailPriceError = "Please provide retail price";
      }
      if (Number(this.state.storeMRP) === 0) {
        storeMRPError = "Please provide MRP";
      }
      if (Number(this.state.storeRetailPrice) > Number(this.state.storeMRP)) {
        storeRetailPriceError = "Retail price can not be more than MRP";
      }
    }
  }

  if (
    storeSpecificPricesError !== "" ||
    storeRetailPriceError !== "" ||
    storeMRPError !== ""
  ) {
    this.setState({
      storeSpecificPricesError: storeSpecificPricesError,
      storeRetailPriceError: storeRetailPriceError,
      storeMRPError: storeMRPError,
    });
    return;
  }
  let storeSpecificPrice = {
    StoreID: this.state.storePriceStoreID,
    Store: this.state.storePriceStore,
    SupplyPrice: this.state.storeSupplyPrice,
    Markup: this.state.storeMarkUp,
    RetailPrice: this.state.storeRetailPrice,
    MRP: this.state.storeMRP,
    MSP: this.state.storeMSP,
    WalletTopUpSameAsRetailPrice: this.state
      .storeWalletTopUpSameAsRetailPrice,
    WalletTopUpAmount: this.state.storeWalletTopUpSameAsRetailPrice
      ? this.state.storeRetailPrice
      : this.state.storeWalletTopUpAmount,
    EditWalletTopUpAmount: this.state.storeEditWalletTopUpAmount,
    DiscountAmount: this.state.storeDiscountAmount,
    DiscountPercentage: this.state.storeDiscountPercentage,
  };
  let storeSpecificPrices = this.state.storeSpecificPrices;
  storeSpecificPrices.push(storeSpecificPrice);
  this.setState({
    storeSpecificPrices: storeSpecificPrices,
    storePriceStore: "",
    storePriceStoreID: "",
    storeSupplyPrice: 0,
    storeMarkUp: 0,
    storeRetailPrice: 0,
    storeMRP: 0,
    storeMSP: 0,
    storeWalletTopUpAmount: 0,
    storeWalletTopUpSameAsRetailPrice: true,
    storeEditWalletTopUpAmount: false,
    storeDiscountPercentage: 0,
    storeDiscountAmount: 0,
  });
}

onAddSecondaryUOM() {
  if (this.state.secondaryUOM.trim() === "") {
    this.setState({ secondaryUOMError: "Please select UOM" });
    return;
  }

  if (
    this.state.secondaryUOMValue.trim() !== "" &&
    this.state.uom === this.state.secondaryUOMValue
  ) {
    this.setState({
      secondaryUOMError: "Primary and Secondary UOM cannot be same",
    });
    return;
  }

  if (this.state.conversion <= 0) {
    this.setState({
      conversionError: "Please enter conversion greater than 0",
    });
    return;
  }
  //check if selected store is already added to list
  if (
    this.state.secondaryUOMs.filter(
      (f) => f.SecondaryUOM === this.state.secondaryUOM
    ).length > 0
  ) {
    this.setState({ secondaryUOMError: "Selected UOM already added" });
    return;
  }
  let objSecondaryUOM = {
    SecondaryUOM: this.state.secondaryUOM,
    SecondaryUOMValue: this.state.secondaryUOMValue,
    Conversion: this.state.conversion,
  };
  let secondaryUOMs = this.state.secondaryUOMs;
  secondaryUOMs.push(objSecondaryUOM);

  let masterUOMs = this.state.masterUOMs;
  masterUOMs.push({
    value: objSecondaryUOM.SecondaryUOMValue,
    text: objSecondaryUOM.SecondaryUOM,
  });

  this.setState({
    secondaryUOM: "",
    secondaryUOMValue: "",
    secondaryUOMError: "",
    conversionError: "",
    conversion: 0,
    masterUOMs: masterUOMs,
  });
}

//Variant related
onAddVariant() {
  let attributes = this.state.attributes;
  if (attributes.length > 2) {
    alert("Only 3 variable attributes allowed");
    return;
  } else {
    if (this.state.attribute !== "") {
      if (
        attributes.filter(
          (f) =>
            f.Attribute.toLowerCase().trim() ===
            this.state.attribute.toLowerCase().trim()
        ).length === 0
      ) {
        attributes.push({
          Attribute: this.state.attribute.trim(),
          AttributeValues: [],
        });
      }
    }
  }
  // console.log(attributes.length);
  this.setState({ attributes: attributes, attribute: "" });
}

handleChange(value, actionMeta, index) {
  // console.log(actionMeta);
  // console.log(value);

  let attributes = this.state.attributes;
  if (
    (this.props.match.params.key &&
      this.props.match.params.key !== "" &&
      actionMeta.action === "remove-value") ||
    actionMeta.action === "pop-value"
  ) {
    let variants = this.state.variants;
    // console.log("variants", variants);
    let removed_value = actionMeta.removedValue.label;
    let objVariant = variants.filter(
      (f) =>
        !f.IsDeleted &&
        (f.Attribute1 === removed_value ||
          f.Attribute2 === removed_value ||
          f.Attribute3 === removed_value)
    );
    // console.log("objVariant", objVariant)
    if (objVariant.length > 0 && objVariant[0].ProductID !== "") {
      alert(
        "Cannot delete attribute, corresponding variant already exists!!!"
      );
      return;
    } else {
      attributes[index].AttributeValues = value === null ? [] : value;
      this.defineVariants(attributes);
      this.setState({ attributes: attributes });
    }
  } else {
    attributes[index].AttributeValues = value === null ? [] : value;
    this.defineVariants(attributes);
    this.setState({ attributes: attributes });
  }
}

handleInputChange(attributeValue, index) {
  this.setState({ attributeValue: attributeValue, currentIndex: index });
}

handleKeyDown(event, index) {
  if (this.state.attributeValue === "") return;
  switch (event.key) {
    case "Enter":
    case "Tab":
      let attributes = this.state.attributes;
      // console.log("attributes", attributes)
      if (attributes[index].AttributeValues) {
        attributes[index].AttributeValues.push({
          value: this.state.attributeValue,
          label: this.state.attributeValue,
        });
        this.setState({
          attributeValue: "",
          attributes: attributes,
        });
        this.defineVariants(attributes);
      }
      event.preventDefault();
  }
}

defineVariants(attributes) {
  let variant1 = [];
  let variant2 = [];
  let variant3 = [];

  if (
    attributes.length > 0 &&
    attributes[0].AttributeValues &&
    attributes[0].AttributeValues.length > 0
  ) {
    //variant1 = attributes[0];
    for (let a1 = 0; a1 < attributes[0].AttributeValues.length; a1++) {
      const objA1 = attributes[0].AttributeValues[a1];
      variant1.push(objA1.label);
    }
    if (
      attributes.length > 1 &&
      attributes[1].AttributeValues &&
      attributes[1].AttributeValues.length > 0
    ) {
      for (let a2 = 0; a2 < attributes[1].AttributeValues.length; a2++) {
        const objA2 = attributes[1].AttributeValues[a2];
        variant2.push(objA2.label);
      }
    }
    if (
      attributes.length > 2 &&
      attributes[2].AttributeValues &&
      attributes[2].AttributeValues.length > 0
    ) {
      for (let a3 = 0; a3 < attributes[2].AttributeValues.length; a3++) {
        const objA3 = attributes[2].AttributeValues[a3];
        variant3.push(objA3.label);
      }
    }
  }

  // console.log("variant1", variant1.length);
  // console.log("variant2", variant2.length);
  // console.log("variant3", variant3.length);

  let finalList = [];

  let f = (a, b) => [].concat(...a.map((a) => b.map((b) => [].concat(a, b))));
  let cartesian = (a, b, ...c) => (b ? cartesian(f(a, b), ...c) : a);

  if (variant1.length > 0 && variant2.length > 0 && variant3.length > 0) {
    finalList = cartesian(variant1, variant2, variant3);
  }
  if (variant1.length > 0 && variant2.length > 0 && variant3.length === 0) {
    finalList = cartesian(variant1, variant2);
  }
  if (variant1.length > 0 && variant2.length === 0 && variant3.length === 0) {
    finalList = variant1;
  }
  let variants = [];
  // console.log(finalList)
  if (finalList && finalList.length > 0) {
    let oldVariants = this.state.variants;
    for (let fl = 0; fl < finalList.length; fl++) {
      const objFinal = finalList[fl];
      // console.log(objFinal);

      let variant = {
        ProductID: "",
        Name: "",
        SKU: "",
        SKUError: "",
        RetailPrice: this.state.retailPrice,
        SupplyPrice: this.state.supplyPrice,
        DiscountPercentage: this.state.discountPercentage,
        DiscountAmount: this.state.discountAmount,
        MRP: this.state.MRP,
        MSP: this.state.MSP,
        IsDeleted: false,
      };
      variant.Name = objFinal.toString();
      variant.Name = variant.Name.replace(/,/g, " / ");

      let attributeValues = variant.Name.split("/");

      let attribute1 =
        attributeValues.length > 0 ? attributeValues[0] : variant.Name;
      let attribute2 = attributeValues.length > 1 ? attributeValues[1] : "";
      let attribute3 = attributeValues.length > 2 ? attributeValues[2] : "";

      variant.Attribute1 = attribute1.trim();
      variant.Attribute2 = attribute2.trim();
      variant.Attribute3 = attribute3.trim();

      if (oldVariants && oldVariants.length > 0) {
        // console.log("oldVariants", oldVariants);
        let oldVariant = oldVariants.filter(
          (f) => f.Name.trim() === variant.Name.trim()
        );
        // console.log("oldVariant", oldVariant)
        if (oldVariant && oldVariant.length > 0) {
          variant.ProductID = oldVariant[0].ProductID;
          variant.SKU = oldVariant[0].SKU;
          variant.RetailPrice = oldVariant[0].RetailPrice;
          variant.SupplyPrice = oldVariant[0].SupplyPrice;
          variant.DiscountPercentage = oldVariant[0].DiscountPercentage;
          variant.DiscountAmount = oldVariant[0].DiscountAmount;
          variant.MRP = oldVariant[0].MRP;
          variant.MSP = oldVariant[0].hasOwnProperty("MSP")
            ? oldVariant[0].MSP
            : 0;
        }
      }

      variants.push(variant);
    }

    for (let j = 0; j < oldVariants.length; j++) {
      const oldVariant = oldVariants[j];

      if (
        oldVariant.hasOwnProperty("ProductID") &&
        oldVariant.ProductID !== "" &&
        oldVariant.IsDeleted
      ) {
        variants.push(oldVariant);
      }
    }
    // console.log("variants", JSON.stringify(variants));
  }
  this.setState({ variants: variants });
}

//Package related
onAddService() {
  let packageService = {
    Service: this.state.service,
    ServiceID: this.state.serviceID,
  };
  for (let index = 0; index < this.state.services.length; index++) {
    const service = this.state.services[index];
    if (service.key === packageService.ServiceID) {
      packageService.RetailPrice = service.RetailPrice;
    }
  }
  let packageServices = this.state.packageServices;
  packageServices.push(packageService);
  this.setState({ packageServices: packageServices, serviceID: "" });
}

onRemoveService(index) {
  let packageServices = this.state.packageServices;
  packageServices.splice(index, 1);
  this.setState({ packageServices: packageServices });
}

quickAddFormContorls() {
  return (
    <React.Fragment>
      <div className="box-body">
        <div className="row">
          <div className="col-md-3">
            <Input
              type="select"
              label="Type"
              onChange={this.onProductTypeChange}
              value={this.state.productTypeID}
              error={this.state.productTypeError}
            >
              <option value={0}>Select product type</option>
              {this.state.productTypes.map((m) => (
                <option value={m.value}>{m.text}</option>
              ))}
            </Input>
          </div>
          <div className="col-md-3">
            <div
              className={
                this.state.categoryError
                  ? "form-group has-error"
                  : "form-group "
              }
            >
              <label>Category</label>
              <div className="input-group">
                <select
                  className="form-control"
                  value={this.state.categoryID}
                  onChange={this.onCategoryChange}
                >
                  <option value="">Select category</option>
                  {this.props.config.ProductCategories.filter(
                    (e) => e.ParentID === "" && !e.IsDeleted
                  ).map((category) => (
                    <option value={category.key}>{category.Name}</option>
                  ))}
                </select>
                <div className="input-group-btn">
                  <button
                    type="button"
                    className="btn btn-default btn-md hidden-xs"
                    onClick={() =>
                      this.setState({
                        isShowingModalAddCategory: true,
                        mobile: false,
                        categoryOrSubCategory: actionTypes.PRODUCT_CATEGORY,
                      })
                    }
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-default btn-md hidden-md hidden-lg"
                    onClick={() =>
                      this.setState({
                        isShowingModalAddCategory: true,
                        mobile: true,
                        categoryOrSubCategory: actionTypes.PRODUCT_CATEGORY,
                      })
                    }
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              {this.state.categoryError && (
                <span className="help-block">Please select category</span>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className={
                this.state.skuError !== ""
                  ? "form-group has-error"
                  : "form-group "
              }
            >
              <label>SKU</label>
              <input
                type="text"
                className="form-control"
                onChange={(evt) =>
                  this.setState({ sku: evt.target.value, skuError: "" })
                }
                value={this.state.sku}
                disabled={this.state.productAutoGenerateSKU}
                placeholder=""
              />
              {this.state.skuError !== "" && (
                <span className="help-block">{this.state.skuError}</span>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className={
                this.state.nameError !== ""
                  ? "form-group has-error"
                  : "form-group "
              }
            >
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                autoFocus={true}
                onChange={(evt) => {
                  this.setState({ name: evt.target.value, nameError: "" });
                }}
                value={this.state.name}
                placeholder=""
              />
              {this.state.nameError !== "" && (
                <span className="help-block">{this.state.nameError}</span>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className={
                this.state.priceError !== ""
                  ? "form-group has-error"
                  : "form-group "
              }
            >
              <label>Retail Price</label>
              <input
                type="number"
                className="form-control"
                onChange={(evt) =>
                  this.setState({
                    retailPrice: Number(evt.target.value),
                    discountAmount:
                      (Number(evt.target.value) *
                        Number(this.state.discountPercentage)) /
                      100,
                    priceError: "",
                  })
                }
                value={this.state.retailPrice}
              />
              {this.state.priceError !== "" && (
                <span className="help-block">{this.state.priceError}</span>
              )}
            </div>
          </div>
          <div
            className={
              this.props.isMobileApp() ? "box with no-border" : "box"
            }
          >
            <div
              className={
                this.props.isMobileApp()
                  ? "box-header"
                  : "box-header with-border"
              }
            >
              <h3 className="box-title">Images</h3>
            </div>
            <div className="col-md-3">
              <Dropzone onDrop={(files) => this.onDrop(files)}>
                <div>
                  Try dropping some files here, or click to select files to
                  upload.
                </div>
              </Dropzone>
            </div>
            <div className="col-md-3">
              <ul className="mailbox-attachments clearfix">
                {this.state.images.map((image, index) => (
                  <li>
                    {/* {image.ImagePath} */}
                    <span className="mailbox-attachment-icon has-img">
                      <img src={image.ImagePath} alt="Attachment" />
                    </span>
                    <div className="mailbox-attachment-info">
                      <span className="mailbox-attachment-name">
                        {image.ImageName}
                      </span>
                      <span className="mailbox-attachment-size">
                        {Math.round(image.ImageSize / 1024)} KB
                        <a
                          onClick={() => this.onRemoveImage(index)}
                          className="btn btn-default btn-xs pull-right"
                        >
                          <i className="fa fa-trash"></i>
                        </a>
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-md-3">
            <button
              className="quick-add-button"
              onClick={() => this.setState({ quickAdd: false })}
            >
              View more details
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

renderGeneralDetails() {
  return (
    <PageSection title="General Details">
      <div className="row">
        {!this.state.pincodeMerchant && (
          <div className="col-md-3">
            <Input
              type="select"
              label="Type"
              onChange={this.onProductTypeChange}
              value={this.state.productTypeID}
              error={this.state.productTypeError}
            >
              <option value={0}>Select product type</option>
              {this.state.productTypes.map((m) => (
                <option value={m.value}>{m.text}</option>
              ))}
            </Input>
          </div>
        )}
        <div className="col-md-3">
          <Input
            type="text"
            label="Name"
            onChange={(value) => {
              this.setState({ name: value, nameError: "" });
            }}
            value={this.state.name}
            error={this.state.nameError}
          />
        </div>
        <div className="col-md-3">
          <Input
            type="text"
            label="SKU"
            disabled={this.state.productAutoGenerateSKU}
            onChange={(value) => this.setState({ sku: value, skuError: "" })}
            value={this.state.sku}
            error={this.state.skuError}
          />
        </div>
        <div className="col-md-3">
          <Input
            type="text"
            label="Barcode (ISBN, UPC, GTIN, etc.)"
            value={this.state.barCode}
            onChange={(value) => this.setState({ barCode: value })}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div
            className={
              this.state.categoryError
                ? "form-group has-error"
                : "form-group "
            }
          >
            <label>Category</label>
            <div className="input-group">
              <select
                className="form-control"
                value={this.state.categoryID}
                onChange={this.onCategoryChange}
              >
                <option value="">Select category</option>
                {this.props.config.ProductCategories.filter(
                  (e) => e.ParentID === "" && !e.IsDeleted
                ).map((category) => (
                  <option value={category.key}>{category.Name}</option>
                ))}
              </select>
              <div className="input-group-btn">
                <button
                  type="button"
                  className="btn btn-default btn-md hidden-xs"
                  onClick={() =>
                    this.setState({
                      isShowingModalAddCategory: true,
                      mobile: false,
                      categoryOrSubCategory: actionTypes.PRODUCT_CATEGORY,
                    })
                  }
                >
                  <i className="fa fa-plus"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-md hidden-md hidden-lg"
                  onClick={() =>
                    this.setState({
                      isShowingModalAddCategory: true,
                      mobile: true,
                      categoryOrSubCategory: actionTypes.PRODUCT_CATEGORY,
                    })
                  }
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </div>
            {this.state.categoryError && (
              <span className="help-block">Please select category</span>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Sub Category</label>
            <div className="input-group">
              <select
                className="form-control"
                value={this.state.subCategoryID}
                onChange={this.onSubCategoryChange}
              >
                <option value="">Select sub category</option>
                {this.props.config.ProductSubCategories.filter(
                  (e) => e.ParentID === this.state.categoryID && !e.IsDeleted
                ).map((subCategory, index) => (
                  <option value={subCategory.key}>{subCategory.Name}</option>
                ))}
              </select>
              <div className="input-group-btn">
                <button
                  type="button"
                  className="btn btn-default btn-md"
                  disabled={this.state.categoryID === "" ? true : false}
                  onClick={() =>
                    this.setState({
                      isShowingModalAddCategory: true,
                      categoryOrSubCategory: actionTypes.PRODUCT_SUB_CATEGORY,
                    })
                  }
                >
                  <i className="fa fa-plus"></i>{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Brand</label>
            <div className="input-group">
              <select
                className="form-control"
                value={this.state.brandID}
                onChange={this.onBrandChange}
              >
                <option value="">Select brand</option>
                {this.props.config.ProductBrands.filter(
                  (e) => !e.IsDeleted
                ).map((brand, index) => (
                  <option value={brand.key}>{brand.Brand}</option>
                ))}
              </select>
              <div className="input-group-btn">
                <button
                  type="button"
                  className="btn btn-default btn-md"
                  onClick={() =>
                    this.setState({ isShowingModalAddBrand: true })
                  }
                >
                  <i className="fa fa-plus"></i>{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.productTypeID === 3 && (
          <div className="col-md-3">
            <Input
              type="number"
              label="Service Time"
              onChange={(value) => this.setState({ serviceDuration: value })}
              value={this.state.serviceDuration}
            />
          </div>
        )}
      </div>
      {!this.state.pincodeMerchant && (
        <div className="row">
          <div className="col-md-12">
            <Input
              type="textarea"
              label="Short Description"
              onChange={(value) => this.setState({ description: value })}
              value={this.state.description}
            />
          </div>
        </div>
      )}
      {!this.state.pincodeMerchant && (
        <div className="row">
          <div className="col-md-12">
            <Editor
              apiKey="5t1d5flmdrmruy3o4ogwyazepn6n8qezlohtn06e87unjkiu"
              onInit={(evt, editor) => (this.editorRef.current = editor)}
              initialValue={this.state.longDescription}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help" +
                  " | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </div>
        </div>
      )}
    </PageSection>
  );
}

renderPriceDetails() {
  return (
    <PageSection title="Price & Taxes">
      {this.state.stores.length > 1 && (
        <div className="row">
          {!this.props.isMobileApp() && (
            <div className="col-md-3">
              <div className="form-group ">
                <label>Sold at specific stores only</label>{" "}
                <span
                  className="fa fa-info-circle"
                  data-tip
                  data-for="accessibleStore"
                ></span>
                <ReactTooltip
                  id="accessibleStore"
                  type="dark"
                  place="top"
                  effect="solid"
                >
                  <span>
                    Please select this option if this product is sold only at
                    specific stores
                  </span>
                </ReactTooltip>
                <div className="input-group">
                  <Switch
                    checked={this.state.accessibleToSelectedStores}
                    disabled={
                      this.props.user.user.Role.toLowerCase() !== "power user"
                    }
                    onChange={() =>
                      this.setState({
                        accessibleToSelectedStores: !this.state
                          .accessibleToSelectedStores,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          )}
          {!this.state.accessibleToSelectedStores && (
            <div className="col-md-3">
              {!this.props.isMobileApp() && (
                <div className="form-group ">
                  <label>Some stores have different pricing</label>{" "}
                  <span
                    className="fa fa-info-circle"
                    data-tip
                    data-for="storeSpecificPricing"
                  ></span>
                  <ReactTooltip
                    id="storeSpecificPricing"
                    type="dark"
                    place="top"
                    effect="solid"
                  >
                    <span>
                      Please select this option if product pricing is
                      different at some stores
                    </span>
                  </ReactTooltip>
                  <div className="input-group">
                    <Switch
                      checked={this.state.storeSpecificPricing}
                      onChange={() => {
                        this.setState({
                          storeSpecificPricing: !this.state
                            .storeSpecificPricing,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {this.state.productTypeID !== 4 &&
        !this.state.accessibleToSelectedStores && (
          <div className="row">
            <div className="col-md-2">
              <Input
                type="number"
                label="Supply Price"
                value={this.state.supplyPrice}
                onChange={(value) => {
                  this.setState({ supplyPrice: Number(value) });
                }}
                error={this.state.supplyPriceError}
              />
            </div>
            <div className="col-md-2">
              <Input
                type="number"
                label="MSP"
                value={this.state.MSP}
                onChange={(value) => {
                  this.setState({ MSP: Number(value) });
                }}
                error={this.state.mspError}
              />
            </div>
            <div className="col-md-2">
              <Input
                type="number"
                label="Retail Price"
                onChange={(value) =>
                  this.setState({
                    retailPrice: Number(value),
                    discountAmount:
                      (Number(value) *
                        Number(this.state.discountPercentage)) /
                      100,
                    priceError: "",
                  })
                }
                value={this.state.retailPrice}
                error={this.state.priceError}
              />
            </div>
            <div className="col-md-2 col-xs-4">
              <Input
                type="number"
                label="Discount %"
                onChange={(value) => {
                  this.setState({
                    discountAmount:
                      (this.state.retailPrice * Number(value)) / 100,
                    discountPercentage: Number(value),
                  });
                }}
                value={this.state.discountPercentage}
              />
            </div>
            <div className="col-md-2 col-xs-8">
              <Input
                type="number"
                label="Discount Amount"
                onChange={(value) => {
                  this.setState({
                    discountPercentage: Number(
                      (
                        (Number(value) * 100) /
                        Number(this.state.retailPrice)
                      ).toFixed(3)
                    ),
                    discountAmount: Number(value),
                  });
                }}
                value={this.state.discountAmount}
              />
            </div>
            <div className="col-md-2">
              <Input
                type="number"
                label="MRP"
                value={this.state.MRP}
                onChange={(value) => {
                  this.setState({ MRP: Number(value), mrpError: "" });
                }}
                error={this.state.mrpError}
              />
            </div>
          </div>
        )}
      {this.state.productTypeID === 4 &&
        !this.state.accessibleToSelectedStores && (
          <div className="row">
            <div className="col-md-2">
              <Input
                type="number"
                label="MSP"
                value={this.state.MSP}
                onChange={(value) => {
                  this.setState({ MSP: Number(value) });
                }}
                error={this.state.mspError}
              />
            </div>
            <div className="col-md-2">
              <Input
                type="number"
                label="Retail Price"
                onChange={(value) =>
                  this.setState({
                    retailPrice: Number(value),
                    discountAmount:
                      (Number(value) *
                        Number(this.state.discountPercentage)) /
                      100,
                    priceError: "",
                  })
                }
                value={this.state.retailPrice}
                error={this.state.priceError}
              />
            </div>
            <div className="col-md-3">
              <Input
                type="switch"
                lable="Wallet recharge amount same as retail price"
                value={this.state.walletTopUpSameAsRetailPrice}
                onChange={(value) => {
                  this.setState({ walletTopUpSameAsRetailPrice: value });
                }}
              />
            </div>
            {!this.state.walletTopUpSameAsRetailPrice && (
              <div className="col-md-2">
                <Input
                  type="number"
                  lable="Wallet Recharge Amount"
                  onChange={(value) =>
                    this.setState({
                      walletTopUpAmount: Number(value),
                      walletTopUpError: "",
                    })
                  }
                  value={this.state.walletTopUpAmount}
                  error={this.state.walletTopUpAmount}
                />
              </div>
            )}
            {!this.state.walletTopUpSameAsRetailPrice && (
              <div className="col-md-3">
                <Input
                  type="switch"
                  label="Allow user to specify recharge amount at time of billing"
                  value={this.state.editWalletTopUpAmount}
                  onChange={(value) => {
                    this.setState({ editWalletTopUpAmount: value });
                  }}
                />
              </div>
            )}
          </div>
        )}
      {(this.state.accessibleToSelectedStores ||
        this.state.storeSpecificPricing) && (
          <div style={{ overflowX: "auto" }}>
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th style={{ width: "200px" }}>Store</th>
                  {this.state.productTypeID !== 4 && (
                    <th style={{ width: "150px" }}>Supply Price</th>
                  )}
                  {this.state.productTypeID !== 4 && (
                    <th style={{ width: "150px" }}>MSP</th>
                  )}
                  <th style={{ width: "150px" }}>Retail Price</th>
                  {this.state.productTypeID !== 4 && (
                    <th style={{ width: "150px" }}>Disc %</th>
                  )}
                  {this.state.productTypeID !== 4 && (
                    <th style={{ width: "150px" }}>Disc Amt</th>
                  )}
                  {this.state.productTypeID === 4 && (
                    <th style={{ width: "150px" }}>
                      Wallet recharge amount same as retail price
                    </th>
                  )}
                  {this.state.productTypeID === 4 && (
                    <th style={{ width: "150px" }}>Wallet Recharge Amount</th>
                  )}
                  {this.state.productTypeID === 4 && (
                    <th style={{ width: "150px" }}>
                      Allow user to specify recharge amount at time of billing
                    </th>
                  )}
                  {this.state.productTypeID !== 4 && (
                    <th style={{ width: "150px" }}>MRP</th>
                  )}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div
                      className={
                        this.state.storeSpecificPricesError !== ""
                          ? "form-group has-error"
                          : "form-group "
                      }
                    >
                      <select
                        className="form-control"
                        value={this.state.storePriceStoreID}
                        onChange={(e) => {
                          this.setState({
                            storePriceStoreID: e.target.value,
                            storePriceStore:
                              e.target.options[e.target.selectedIndex].text,
                            storePriceStoreError: "",
                          });
                        }}
                      >
                        <option value="">Select store</option>
                        {this.state.stores
                          .filter((e) => !e.IsDeleted)
                          .map((store) => (
                            <option value={store.key}>
                              {store.StoreCode} {store.StoreName}
                            </option>
                          ))}
                      </select>
                      {this.state.storeSpecificPricesError !== "" && (
                        <span className="help-block">
                          {this.state.storeSpecificPricesError}
                        </span>
                      )}
                    </div>
                  </td>
                  {this.state.productTypeID !== 4 && (
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(evt) =>
                          this.setState({
                            storeSupplyPrice: Number(evt.target.value),
                          })
                        }
                        value={this.state.storeSupplyPrice}
                      />
                      {this.state.storeSupplyPriceError !== "" && (
                        <span className="help-block">
                          {this.state.storeSupplyPriceError}
                        </span>
                      )}
                    </td>
                  )}
                  {this.state.productTypeID !== 4 && (
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(evt) =>
                          this.setState({ storeMSP: Number(evt.target.value) })
                        }
                        value={this.state.storeMSP}
                      />
                      {this.state.storeMSPError !== "" && (
                        <span className="help-block">
                          {this.state.storeMSPError}
                        </span>
                      )}
                    </td>
                  )}
                  <td>
                    <Input
                      type="number"
                      value={this.state.storeRetailPrice}
                      onChange={(value) =>
                        this.setState({
                          storeRetailPrice: Number(value),
                          storeRetailPriceError: "",
                        })
                      }
                      error={this.state.storeRetailPriceError}
                    />
                    {/* <input type="number" className="form-control"
                                        onChange={(evt) => this.setState({ storeRetailPrice: Number(evt.target.value) })}
                                        value={this.state.storeRetailPrice}
                                    />
                                    {this.state.storeRetailPriceError !== "" && <span className="help-block">{this.state.storeRetailPriceError}</span>} */}
                  </td>
                  {this.state.productTypeID !== 4 && (
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(evt) =>
                          this.setState({
                            storeDiscountAmount:
                              Number(this.state.storeRetailPrice) *
                              Number(Number(evt.target.value) / 100),
                            storeDiscountPercentage: Number(evt.target.value),
                          })
                        }
                        value={this.state.storeDiscountPercentage}
                      />
                    </td>
                  )}
                  {this.state.productTypeID !== 4 && (
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(evt) =>
                          this.setState({
                            storeDiscountPercentage: Number(
                              (
                                (Number(evt.target.value) * 100) /
                                Number(this.state.storeRetailPrice)
                              ).toFixed(3)
                            ),
                            storeDiscountAmount: Number(evt.target.value),
                          })
                        }
                        value={this.state.storeDiscountAmount}
                      />
                    </td>
                  )}
                  {this.state.productTypeID === 4 && (
                    <td>
                      <input
                        type="checkbox"
                        checked={this.state.storeWalletTopUpSameAsRetailPrice}
                        onChange={() =>
                          this.setState({
                            storeWalletTopUpSameAsRetailPrice: !this.state
                              .storeWalletTopUpSameAsRetailPrice,
                          })
                        }
                      />
                    </td>
                  )}
                  {this.state.productTypeID === 4 && (
                    <td>
                      {!this.state.storeWalletTopUpSameAsRetailPrice && (
                        <input
                          type="number"
                          className="form-control"
                          onChange={(evt) =>
                            this.setState({
                              storeWalletTopUpAmount: Number(evt.target.value),
                            })
                          }
                          value={this.state.storeWalletTopUpAmount}
                        />
                      )}
                    </td>
                  )}
                  {this.state.productTypeID === 4 && (
                    <td>
                      {!this.state.storeWalletTopUpSameAsRetailPrice && (
                        <input
                          type="checkbox"
                          checked={this.state.storeEditWalletTopUpAmount}
                          onChange={() =>
                            this.setState({
                              storeEditWalletTopUpAmount: !this.state
                                .storeEditWalletTopUpAmount,
                            })
                          }
                        />
                      )}
                    </td>
                  )}
                  {this.state.productTypeID !== 4 && (
                    <td>
                      <Input
                        type="number"
                        value={this.state.storeMRP}
                        onChange={(value) =>
                          this.setState({
                            storeMRP: Number(value),
                            storeMRPError: "",
                          })
                        }
                        error={this.state.storeMRPError}
                      />
                      {/* <input type="number" className="form-control"
                                        onChange={(evt) => this.setState({ storeMRP: Number(evt.target.value) })}
                                        value={this.state.storeMRP}
                                    />
                                    {this.state.storeMRPError !== "" && <span className="help-block">{this.state.storeMRPError}</span>} */}
                    </td>
                  )}
                  <td>
                    <button
                      type="submit"
                      className="btn btn-md btn-default btn-flat"
                      onClick={this.onAddStoreSpecificPrice}
                    >
                      Add
                    </button>
                  </td>
                </tr>
                {this.state.storeSpecificPrices.map((s, index) => (
                  <tr key={index}>
                    <td>{s.Store}</td>
                    {this.state.productTypeID !== 4 && (
                      <td>
                        {/* <input type="number" className="form-control"
                                        onChange={(evt) => {
                                            let storePrices = this.state.storeSpecificPrices
                                            storePrices[index].SupplyPrice = Number(evt.target.value)
                                            this.setState({ storeSpecificPrices: storePrices })
                                        }}
                                        value={s.SupplyPrice}
                                    /> */}
                        <Input
                          type="number"
                          value={s.SupplyPrice}
                          onChange={(value) => {
                            let storePrices = this.state.storeSpecificPrices;
                            storePrices[index].SupplyPrice = Number(value);
                            this.setState({ storeSpecificPrices: storePrices });
                          }}
                        />
                      </td>
                    )}
                    {this.state.productTypeID !== 4 && (
                      <td>
                        {/* <input type="number" className="form-control"
                                        onChange={(evt) => {
                                            let storePrices = this.state.storeSpecificPrices
                                            storePrices[index].MSP = Number(evt.target.value)
                                            this.setState({ storeSpecificPrices: storePrices })
                                        }}
                                        value={s.hasOwnProperty('MSP') ? s.MSP : 0}
                                    /> */}
                        <Input
                          type="number"
                          value={s.MSP}
                          onChange={(value) => {
                            let storePrices = this.state.storeSpecificPrices;
                            storePrices[index].MSP = Number(value);
                            this.setState({ storeSpecificPrices: storePrices });
                          }}
                        />
                      </td>
                    )}
                    {this.state.productTypeID !== 4 && (
                      <td>
                        {/* <input type="number" className="form-control"
                                        onChange={(evt) => {
                                            let storePrices = this.state.storeSpecificPrices
                                            storePrices[index].RetailPrice = Number(evt.target.value)
                                            this.setState({ storeSpecificPrices: storePrices })
                                        }}
                                        value={s.RetailPrice}
                                    /> */}
                        <Input
                          type="number"
                          value={s.RetailPrice}
                          onChange={(value) => {
                            let storePrices = this.state.storeSpecificPrices;
                            storePrices[index].RetailPrice = Number(value);
                            storePrices[index].PriceError = "";
                            this.setState({ storeSpecificPrices: storePrices });
                          }}
                          error={s.PriceError}
                        />
                      </td>
                    )}
                    {this.state.productTypeID !== 4 && (
                      <td>
                        {/* <input type="number" className="form-control"
                                        onChange={(evt) => {
                                            let storePrices = this.state.storeSpecificPrices
                                            storePrices[index].DiscountPercentage = Number(evt.target.value)
                                            storePrices[index].DiscountAmount = Number(storePrices[index].RetailPrice) * Number(evt.target.value) / 100
                                            this.setState({ storeSpecificPrices: storePrices })
                                        }}
                                        value={s.hasOwnProperty('DiscountPercentage') ? s.DiscountPercentage : 0}
                                    /> */}
                        <Input
                          type="number"
                          value={
                            s.hasOwnProperty("DiscountPercentage")
                              ? s.DiscountPercentage
                              : 0
                          }
                          onChange={(value) => {
                            let storePrices = this.state.storeSpecificPrices;
                            storePrices[index].DiscountPercentage = Number(
                              value
                            );
                            storePrices[index].DiscountAmount =
                              (Number(storePrices[index].RetailPrice) *
                                Number(value)) /
                              100;
                            this.setState({ storeSpecificPrices: storePrices });
                          }}
                        />
                      </td>
                    )}
                    {this.state.productTypeID !== 4 && (
                      <td>
                        {/* <input type="number" className="form-control"
                                        onChange={(evt) => {
                                            let storePrices = this.state.storeSpecificPrices
                                            storePrices[index].DiscountPercentage = Number(((Number(evt.target.value) * 100) / Number(storePrices[index].RetailPrice)).toFixed(3))
                                            storePrices[index].DiscountAmount = Number(evt.target.value)
                                            this.setState({ storeSpecificPrices: storePrices })
                                        }}
                                        value={s.hasOwnProperty('DiscountAmount') ? s.DiscountAmount : 0}
                                    /> */}
                        <Input
                          type="number"
                          value={
                            s.hasOwnProperty("DiscountAmount")
                              ? s.DiscountAmount
                              : 0
                          }
                          onChange={(value) => {
                            let storePrices = this.state.storeSpecificPrices;
                            storePrices[index].DiscountPercentage = Number(
                              (
                                (Number(value) * 100) /
                                Number(storePrices[index].RetailPrice)
                              ).toFixed(3)
                            );
                            storePrices[index].DiscountAmount = Number(value);
                            this.setState({ storeSpecificPrices: storePrices });
                          }}
                        />
                      </td>
                    )}
                    {this.state.productTypeID === 4 && <td>{s.RetailPrice}</td>}
                    {this.state.productTypeID === 4 && (
                      <td>
                        {s.hasOwnProperty("WalletTopUpSameAsRetailPrice")
                          ? s.WalletTopUpSameAsRetailPrice
                            ? "Yes"
                            : "No"
                          : "No"}
                      </td>
                    )}
                    {this.state.productTypeID === 4 && (
                      <td>
                        {" "}
                        {s.hasOwnProperty("WalletTopUpAmount")
                          ? s.WalletTopUpAmount
                          : 0}
                      </td>
                    )}
                    {this.state.productTypeID === 4 && (
                      <th>
                        {s.hasOwnProperty("EditWalletTopUpAmount")
                          ? s.EditWalletTopUpAmount
                            ? "Yes"
                            : "No"
                          : "No"}
                      </th>
                    )}
                    {this.state.productTypeID !== 4 && (
                      <td>
                        {/* <input type="number" className="form-control"
                                        onChange={(evt) => {
                                            let storePrices = this.state.storeSpecificPrices
                                            storePrices[index].MRP = Number(evt.target.value)
                                            this.setState({ storeSpecificPrices: storePrices })
                                        }}
                                        value={s.MRP}
                                    /> */}
                        <Input
                          type="number"
                          value={s.MRP}
                          onChange={(value) => {
                            let storePrices = this.state.storeSpecificPrices;
                            storePrices[index].MRP = Number(value);
                            storePrices[index].MRPError = "";
                            this.setState({ storeSpecificPrices: storePrices });
                          }}
                          error={s.MRPError}
                        />
                      </td>
                    )}
                    <td>
                      <button
                        type="submit"
                        className="btn btn-md btn-default btn-flat"
                        onClick={() => {
                          let storePrices = this.state.storeSpecificPrices;
                          storePrices.splice(index, 1);
                          this.setState({ storeSpecificPrices: storePrices });
                        }}
                      >
                        <i className="fa fa-remove"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br></br>
          </div>
        )}

      <div className="row">
        <div className="col-md-2">
          <div className="form-group">
            <label>Charge taxes on this product</label>
            <div className="input-group">
              <Switch
                checked={this.state.chargeTaxOnPoduct}
                onChange={() => {
                  this.setState({
                    chargeTaxOnPoduct: !this.state.chargeTaxOnPoduct,
                  });
                }}
              />
            </div>
            {/* <input type="checkbox" checked={this.state.chargeTaxOnPoduct} onChange={() => this.setState({ chargeTaxOnPoduct: !this.state.chargeTaxOnPoduct })} />
                        <label >Charge taxes on this product</label> */}
          </div>
        </div>
        {this.state.chargeTaxOnPoduct && !this.state.pincodeMerchant && (
          <div className="col-md-2">
            <div className="form-group">
              <label>Tax based on retail price</label>
              <div className="input-group">
                <Switch
                  checked={this.state.priceBasedTax}
                  onChange={() => {
                    this.setState({
                      priceBasedTax: !this.state.priceBasedTax,
                      taxPriceRange:
                        this.state.taxPriceRange &&
                          this.state.taxPriceRange.length > 0
                          ? this.state.taxPriceRange
                          : this.state.initialTaxPriceRange,
                    });
                  }}
                />
              </div>
              {/* <input type="checkbox"
                            checked={this.state.priceBasedTax}
                            onChange={() => {
                                this.setState({
                                    priceBasedTax: !this.state.priceBasedTax,
                                    taxPriceRange: this.state.taxPriceRange && this.state.taxPriceRange.length > 0 ? this.state.taxPriceRange : this.state.initialTaxPriceRange
                                })
                            }} />
                        <label>Tax based on retail price</label> */}
            </div>
          </div>
        )}
        {this.state.chargeTaxOnPoduct && (
          <div className="col-md-2">
            <div className="form-group">
              <label>Tax inclusive (Sales)</label>
              <div className="input-group">
                <Switch
                  checked={this.state.taxInclusive}
                  onChange={() => {
                    this.setState({ taxInclusive: !this.state.taxInclusive });
                  }}
                />
              </div>
              {/* <input type="checkbox"
                            checked={this.state.taxInclusive}
                            onChange={() => this.setState({ taxInclusive: !this.state.taxInclusive })} />
                        <label >Tax inclusive (Sales)</label> */}
            </div>
          </div>
        )}
        {this.state.chargeTaxOnPoduct && (
          <div className="col-md-2">
            <div className="form-group">
              <label>Tax inclusive (Purchase)</label>
              <div className="input-group">
                <Switch
                  checked={this.state.taxInclusivePurchase}
                  onChange={() => {
                    this.setState({
                      taxInclusivePurchase: !this.state.taxInclusivePurchase,
                    });
                  }}
                />
              </div>
              {/* <input type="checkbox"
                            checked={this.state.taxInclusivePurchase}
                            onChange={() => this.setState({ taxInclusivePurchase: !this.state.taxInclusivePurchase })} />
                        <label >Tax inclusive (Purchase)</label> */}
            </div>
          </div>
        )}
      </div>
      {this.state.chargeTaxOnPoduct && (
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label>HSN/SAC Code</label>
              <input
                type="text"
                className="form-control"
                onChange={(evt) =>
                  this.setState({ HSNSACCode: evt.target.value })
                }
                value={this.state.HSNSACCode}
                placeholder="HSN/SAC code"
              />
            </div>
          </div>
          {!this.state.priceBasedTax && (
            <div className="col-md-3">
              <div className="form-group">
                <label>Tax </label>
                <select
                  className="form-control"
                  value={this.state.taxID}
                  onChange={this.onTaxChange}
                >
                  <option value="">Select tax</option>
                  {this.props.config.ProductTaxes.filter(
                    (e) => !e.IsDeleted
                  ).map((t) => (
                    <option value={t.key}>{t.TaxGroup}</option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
      )}
      {this.state.chargeTaxOnPoduct && this.state.priceBasedTax && (
        <div>
          {this.state.taxPriceRange.map((t, index) => (
            <div className="row">
              <div className="col-md-3">
                <div
                  className={
                    t.FromPriceErrMsg !== ""
                      ? "form-group has-error"
                      : "form-group "
                  }
                >
                  <label>Price - From</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={(evt) =>
                      this.onFromPriceChange(index, Number(evt.target.value))
                    }
                    value={t.FromPrice}
                  />
                  {t.FromPriceErrMsg !== "" && (
                    <span className="help-block">{t.FromPriceErrMsg}</span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className={
                    t.ToPriceErrMsg !== ""
                      ? "form-group has-error"
                      : "form-group "
                  }
                >
                  <label>Price - To</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={(evt) =>
                      this.onToPriceChange(index, Number(evt.target.value))
                    }
                    value={t.ToPrice}
                  />
                  {t.ToPriceErrMsg !== "" && (
                    <span className="help-block">{t.ToPriceErrMsg}</span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className={
                    t.TaxErrMsg !== ""
                      ? "form-group has-error"
                      : "form-group "
                  }
                >
                  <label>Tax </label>
                  <select
                    className="form-control"
                    value={t.TaxID}
                    onChange={(evt) => this.onPriceBasedTaxChange(index, evt)}
                  >
                    <option value="">Select tax</option>
                    {this.props.config.ProductTaxes.filter(
                      (e) => !e.IsDeleted
                    ).map((t) => (
                      <option value={t.key}>{t.TaxGroup}</option>
                    ))}
                  </select>
                  {t.TaxErrMsg !== "" && (
                    <span className="help-block">{t.TaxErrMsg}</span>
                  )}
                </div>
              </div>
              {index === 1 && (
                <div className="col-md-3">
                  <div className="form-group">
                    <div className="input-group-btn">
                      <button
                        type="button"
                        className="btn btn-default btn-md"
                        style={{ marginTop: "25px" }}
                        onClick={() => {
                          let taxPriceRange = this.state.taxPriceRange;
                          taxPriceRange.push({
                            FromPrice: 0,
                            ToPrice: 0,
                            TaxID: "",
                            TaxGroup: "",
                            FromPriceErrMsg: "",
                            ToPriceErrMsg: "",
                            TaxErrMsg: "",
                          });
                          this.setState({ taxPriceRange: taxPriceRange });
                        }}
                      >
                        <i className="glyphicon glyphicon-plus"></i>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {index > 1 && (
                <div className="col-md-3">
                  <div className="form-group">
                    <div className="input-group-btn">
                      <button
                        type="button"
                        className="btn btn-default btn-md"
                        style={{ marginTop: "25px" }}
                        onClick={() => {
                          let taxPriceRange = this.state.taxPriceRange;
                          taxPriceRange.splice(index, 1);
                          this.setState({ taxPriceRange: taxPriceRange });
                        }}
                      >
                        <i className="glyphicon glyphicon-remove"></i>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {this.state.variants.length > 0 && (
        <React.Fragment>
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label>Automatically generate SKU</label>
                <div className="input-group">
                  <Switch
                    disabled={this.props.match.params.key}
                    checked={this.state.autogenerateSKU}
                    onChange={() => {
                      this.setState({
                        autogenerateSKU: !this.state.autogenerateSKU,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th style={{ width: "200px" }}>Name</th>
                    <th style={{ width: "100px" }}>SKU</th>
                    <th style={{ width: "100px" }}>Supply Price</th>
                    <th style={{ width: "100px" }}>MSP</th>
                    <th style={{ width: "210px" }}>Retail Price</th>
                    <th style={{ width: "100px" }}>Discount %</th>
                    <th style={{ width: "100px" }}>Discount Amount</th>
                    <th style={{ width: "100px" }}>MRP</th>
                    <th style={{ width: "50px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.variants
                    .filter((f) => !f.IsDeleted)
                    .map((s, index) => (
                      <tr id={index}>
                        <td>{s.Name}</td>
                        <td>
                          {!this.state.autogenerateSKU && s.ProductID === "" && (
                            <React.Fragment>
                              {" "}
                              <input
                                type="text"
                                disabled={
                                  this.props.match.params.key &&
                                  s.ProductID !== ""
                                }
                                className={
                                  s.SKUError !== ""
                                    ? "form-control has-error"
                                    : "form-control"
                                }
                                onChange={(evt) => {
                                  let variants = this.state.variants;
                                  variants[
                                    index
                                  ].SKU = evt.target.value.trim();
                                  variants[index].SKUError = "";
                                  this.setState({
                                    variants: variants,
                                    variantSKUError: "",
                                  });
                                }}
                                value={s.SKU}
                              />
                              {s.SKUError !== "" && (
                                <span
                                  className="help-block"
                                  style={{ color: "red" }}
                                >
                                  {s.SKUError}
                                </span>
                              )}
                            </React.Fragment>
                          )}
                          {s.SKU !== "" && s.ProductID !== "" && (
                            <label>{s.SKU}</label>
                          )}
                        </td>
                        <td>
                          <input
                            type="number"
                            // disabled={this.props.match.params.key && s.ProductID !== ''}
                            className="form-control"
                            min="0"
                            onChange={(evt) => {
                              let variants = this.state.variants;
                              variants[index].SupplyPrice = Number(
                                evt.target.value
                              );
                              this.setState({
                                variants: variants,
                                variantSupplyPriceError: "",
                              });
                            }}
                            value={s.SupplyPrice}
                          />
                          {this.state.variantSupplyPriceError !== "" && (
                            <span className="help-block">
                              {this.state.variantSupplyPriceError}
                            </span>
                          )}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            min="0"
                            onChange={(evt) => {
                              let variants = this.state.variants;
                              variants[index].MSP = Number(evt.target.value);
                              this.setState({ variants: variants });
                            }}
                            value={s.MSP ? s.MSP : 0}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            min="0"
                            onChange={(evt) => {
                              let variants = this.state.variants;
                              variants[index].RetailPrice = Number(
                                evt.target.value
                              );
                              this.setState({
                                variants: variants,
                                variantRetailPriceError: "",
                              });
                            }}
                            value={s.RetailPrice}
                          />
                          {this.state.variantRetailPriceError !== "" && (
                            <span className="help-block">
                              {this.state.variantRetailPriceError}
                            </span>
                          )}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            min="0"
                            onChange={(evt) => {
                              let variants = this.state.variants;
                              variants[index].DiscountPercentage = Number(
                                evt.target.value
                              );
                              variants[index].DiscountAmount =
                                (Number(variants[index].RetailPrice) *
                                  Number(evt.target.value)) /
                                100;
                              this.setState({ variants: variants });
                            }}
                            value={s.DiscountPercentage}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            min="0"
                            className="form-control"
                            onChange={(evt) => {
                              let variants = this.state.variants;
                              variants[index].DiscountAmount = Number(
                                evt.target.value
                              );
                              variants[index].DiscountPercentage = Number(
                                (
                                  (Number(evt.target.value) * 100) /
                                  Number(variants[index].RetailPrice)
                                ).toFixed(3)
                              );
                              this.setState({ variants: variants });
                            }}
                            value={s.DiscountAmount}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            min="0"
                            className="form-control"
                            onChange={(evt) => {
                              let variants = this.state.variants;
                              variants[index].MRP = Number(evt.target.value);
                              this.setState({
                                variants: variants,
                                variantMRPError: "",
                              });
                            }}
                            value={s.MRP}
                          />
                          {this.state.variantMRPError !== "" && (
                            <span className="help-block">
                              {this.state.variantMRPError}
                            </span>
                          )}
                        </td>
                        <td>
                          <button
                            type="submit"
                            className="btn btn-md btn-default btn-flat"
                            onClick={() => {
                              let variants = this.state.variants;
                              if (
                                variants[index].hasOwnProperty("ProductID")
                              ) {
                                variants[index].IsDeleted = true;
                              } else {
                                variants.splice(index, 1);
                              }
                              this.setState({ variants: variants });
                            }}
                          >
                            <i className="fa fa-remove"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </React.Fragment>
      )}
    </PageSection>
  );
}

renderInventoryDetails() {
  return (
    <React.Fragment>
      {(this.state.productTypeID === 1 || this.state.productTypeID === 2) && (
        <PageSection title="Inventory">
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label>Type</label>
                <select
                  className="form-control"
                  value={this.state.inventoryType}
                  disabled={
                    (this.props.match.params.key &&
                      this.props.match.params.key !== ""
                      ? this.state.inventoryType == "FIFO" ? false : true
                      : false)

                  }
                  onChange={(evt) =>
                    this.setState({
                      inventoryType: evt.target.value,
                      uom: "",
                      secondaryUOMs: [],
                      masterUOMs: [],
                      conversion: 0,
                      uniqueLabel: "",
                      pruchaseUOM: "",
                      salesUOM: "",
                      secondaryUOMValue: "",
                      secondaryUOMError: "",
                      conversionError: "",
                      attributes: [],
                      attribute: "",
                      attributeValue: "",
                      attributeValues: [],
                      selectedAttribute: null,
                      variants: [],
                      autogenerateSKU: false,
                      batchTrackingBatchNoRequired: false,
                      batchTrackingMfgDateRequired: false,
                      batchTrackingMRPRequired: false,
                      batchTrackingExpDateRequired: false,
                    })
                  }
                >
                  {
                    this.props.match.params.key &&
                      this.props.match.params.key !== "" &&
                      this.state.inventoryType === "FIFO" ? (
                      <>
                        <option value={"FIFO"}>FIFO</option>
                        <option value={"Batch Tracking"}>Batch Tracking</option>
                      </>
                    ) : (
                      this.state.inventoryTypeOptions.map((m) => (
                        <option key={m.value} value={m.value}>
                          {m.value}
                        </option>
                      ))
                    )
                  }

                </select>
              </div>
            </div>
            {this.state.inventoryType.toLowerCase() === "unique no" && (
              <div className="col-md-2">
                <div className="form-group">
                  <label>Unique Label</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(evt) =>
                      this.setState({ uniqueLabel: evt.target.value })
                    }
                    value={this.state.uniqueLabel}
                  />
                </div>
              </div>
            )}
          </div>
          {this.state.inventoryType.toLowerCase() === "variants" && (
            <React.Fragment>
              <div className="row" style={{ marginBottom: "20px" }}>
                <div className="col-md-6">
                  <label>Define Attribute</label>{" "}
                  <span
                    className="fa fa-info-circle"
                    data-tip
                    data-for="attributes"
                  ></span>
                  &nbsp;&nbsp;&nbsp;
                  <ReactTooltip
                    id="attributes"
                    type="dark"
                    place="top"
                    effect="solid"
                  >
                    <span>
                      Choose up to three variable attributes for this product
                      to create and manage SKUs and their inventory levels.
                    </span>
                  </ReactTooltip>
                  <input
                    type="text"
                    style={{
                      width: "200px",
                      lineHeight: "1.42857143",
                      height: "34px",
                      fontSize: "14px",
                      padding: "6px 12px",
                    }}
                    onChange={(evt) =>
                      this.setState({ attribute: evt.target.value })
                    }
                    value={this.state.attribute}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <button
                    type="submit"
                    className="btn btn-md btn-default btn-flat"
                    disabled={this.state.attribute == ""}
                    onClick={this.onAddVariant}
                  >
                    Add Attribute
                  </button>
                  <br />
                </div>
              </div>
              {this.state.attributes.length > 0 && (
                <div className="row">
                  <div className="col-md-6">
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th style={{ width: "100px" }}>Attribute(s)</th>
                          <th style={{ width: "300px" }}>Value(s)</th>
                          <th style={{ width: "50px" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.attributes.map((s, index) => (
                          <tr>
                            <td>{s.Attribute}</td>
                            <td>
                              <CreatableSelect
                                // components={components}
                                inputValue={
                                  this.state.currentIndex === index
                                    ? this.state.attributeValue
                                    : ""
                                }
                                isClearable={!this.props.match.params.key}
                                isMulti
                                menuIsOpen={false}
                                onChange={(value, actionMeta) =>
                                  this.handleChange(value, actionMeta, index)
                                }
                                onInputChange={(value) =>
                                  this.handleInputChange(value, index)
                                }
                                onKeyDown={(evt) =>
                                  this.handleKeyDown(evt, index)
                                }
                                placeholder="Type something and press enter..."
                                value={s.AttributeValues}
                              />
                            </td>
                            <td>
                              {!this.props.match.params.key && (
                                <button
                                  type="submit"
                                  className="btn btn-md btn-default btn-flat"
                                  onClick={() => {
                                    let attributes = this.state.attributes;
                                    attributes.splice(index, 1);
                                    this.setState({ attributes: attributes });
                                    this.defineVariants(attributes);
                                  }}
                                >
                                  <i className="fa fa-remove"></i>
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
          {this.state.inventoryType.toLowerCase() === "batch tracking" && (
            <div className="row">
              {!this.props.match.params.key &&
               <div className="col-md-2">
                  <div className="form-group">
                    {/* <input type="checkbox" checked={this.state.batchTrackingBatchNoRequired} onChange={() => this.setState({ batchTrackingBatchNoRequired: !this.state.batchTrackingBatchNoRequired })} /> */}
                    <label>Batch No</label>
                    <div className="input-group">
                      <Switch
                        checked={this.state.batchTrackingBatchNoRequired}
                        onChange={() => {
                          this.setState({
                            batchTrackingBatchNoRequired: !this.state
                              .batchTrackingBatchNoRequired,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>}
              {/* {this.state.batchTrackingBatchNoRequired && <div className="col-md-3">
                        <div className="form-group">
                                <input type="checkbox" checked={this.state.batchTrackingAutoBatchNoRequired} onChange={() => this.setState({ batchTrackingAutoBatchNoRequired: !this.state.batchTrackingAutoBatchNoRequired })} />
                                <label>Auto Generate Batch No</label>
                        </div>
                    </div>} */}
              <div className="col-md-2">
                <div className="form-group">
                  {/* <input type="checkbox" checked={this.state.batchTrackingMRPRequired} onChange={() => this.setState({ batchTrackingMRPRequired: !this.state.batchTrackingMRPRequired })} /> */}
                  <label>MRP</label>
                  <div className="input-group">
                    <Switch
                      checked={this.state.batchTrackingMRPRequired}
                      onChange={() => {
                        this.setState({
                          batchTrackingMRPRequired: !this.state
                            .batchTrackingMRPRequired,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              {!this.props.match.params.key &&
               <div className="col-md-2">
                  <div className="form-group">
                    {/* <input type="checkbox" checked={this.state.batchTrackingMfgDateRequired} onChange={() => this.setState({ batchTrackingMfgDateRequired: !this.state.batchTrackingMfgDateRequired })} /> */}
                    <label>Mfg Date</label>
                    <div className="input-group">
                      <Switch
                        checked={this.state.batchTrackingMfgDateRequired}
                        onChange={() => {
                          this.setState({
                            batchTrackingMfgDateRequired: !this.state
                              .batchTrackingMfgDateRequired,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>}
              {!this.props.match.params.key &&
                <div className="col-md-2">
                  <div className="form-group">
                    {/* <input type="checkbox" checked={this.state.batchTrackingExpDateRequired} onChange={() => this.setState({ batchTrackingExpDateRequired: !this.state.batchTrackingExpDateRequired })} /> */}
                    <label>Exp Date</label>
                    <div className="input-group">
                      <Switch
                        checked={this.state.batchTrackingExpDateRequired}
                        onChange={() => {
                          this.setState({
                            batchTrackingExpDateRequired: !this.state
                              .batchTrackingExpDateRequired,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>}
            </div>
          )}
          {this.props.match.params.key &&
            this.props.match.params.key == "" && this.state.inventoryType.toLowerCase() === "Batch tracking" && (
              <div className="row">
                {(this.state.batchTrackingMfgDateRequired ||
                  this.state.batchTrackingExpDateRequired) && (
                    <div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Date Format</label>
                          <select
                            className="form-control"
                            value={this.state.batchTrackingDateFormat}
                            onChange={(evt) =>
                              this.setState({
                                batchTrackingDateFormat: evt.target.value,
                              })
                            }
                          >
                            <option value="mm/yy">mm/yy</option>
                            <option value="mmm/yy">mmm/yy</option>
                            <option value="mmm/yyyy">mmm/yyyy</option>
                            <option value="dd/mm/yy">dd/mm/yy</option>
                            <option value="dd/mmm/yyyy">dd/mmm/yyyy</option>
                            <option value="mm-yy">mm-yy</option>
                            <option value="mmm-yy">mmm-yy</option>
                            <option value="mmm-yyyy">mmm-yyyy</option>
                            <option value="dd-mm-yy">dd-mm-yy</option>
                            <option value="dd-mmm-yyyy">dd-mmm-yyyy</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            )}
          <div className="row">
            <div className="col-md-6">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Store</th>
                    <th>Min Qty</th>
                    <th>Max Qty</th>
                    <th>Reorder Qty</th>
                    {!this.props.match.params.key &&
                      this.state.inventoryType === "FIFO" && (
                        <th>InStock Qty</th>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {this.state.storeSpecificQtys.map((s, index) => (
                    <tr>
                      <td>{s.StoreName}</td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(evt) => {
                            let storeSpecificQtys = this.state
                              .storeSpecificQtys;
                            storeSpecificQtys[index].MinQty = Number(
                              evt.target.value
                            );
                            this.setState({
                              storeSpecificQtys: storeSpecificQtys,
                            });
                          }}
                          disabled={
                            this.state.stores.filter(
                              (e) => e.key === s.StoreID
                            ).length === 0
                          }
                          value={s.MinQty}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(evt) => {
                            let storeSpecificQtys = this.state
                              .storeSpecificQtys;
                            storeSpecificQtys[index].MaxQty = Number(
                              evt.target.value
                            );
                            this.setState({
                              storeSpecificQtys: storeSpecificQtys,
                            });
                          }}
                          disabled={
                            this.state.stores.filter(
                              (e) => e.key === s.StoreID
                            ).length === 0
                          }
                          value={s.MaxQty}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(evt) => {
                            let storeSpecificQtys = this.state
                              .storeSpecificQtys;
                            storeSpecificQtys[index].ReorderQty = Number(
                              evt.target.value
                            );
                            this.setState({
                              storeSpecificQtys: storeSpecificQtys,
                            });
                          }}
                          disabled={
                            this.state.stores.filter(
                              (e) => e.key === s.StoreID
                            ).length === 0
                          }
                          value={s.ReorderQty}
                        />
                      </td>
                      {!this.props.match.params.key &&
                        this.state.inventoryType === "FIFO" && (
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              onChange={(evt) => {
                                let storeSpecificQtys = this.state
                                  .storeSpecificQtys;
                                storeSpecificQtys[index].InStockQty = Number(
                                  evt.target.value
                                );
                                this.setState({
                                  storeSpecificQtys: storeSpecificQtys,
                                });
                              }}
                              disabled={
                                this.state.stores.filter(
                                  (e) => e.key === s.StoreID
                                ).length === 0
                              }
                              value={s.InStockQty}
                            />
                          </td>
                        )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </PageSection>
      )}
    </React.Fragment>
  );
}

renderUOM() {
  return (
    <React.Fragment>
      {(this.state.productTypeID === 1 || this.state.productTypeID === 2) && (
        <PageSection title="Unit Of Measurement">
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label>Primary UOM</label>
                <select
                  className="form-control"
                  value={this.state.uom}
                  disabled={
                    this.props.match.params.key && this.state.uom !== ""
                  }
                  onChange={(evt) => {
                    let masterUOMs = this.state.masterUOMs;
                    if (masterUOMs.length === 0) {
                      masterUOMs.push({
                        value: evt.target.value,
                        text:
                          evt.target.options[evt.target.selectedIndex].text,
                      });
                    }

                    this.setState({
                      uom: evt.target.value,
                      masterUOMs: masterUOMs,
                    });
                  }}
                >
                  <option value="">Select UOM</option>
                  {this.state.uoms.map((t) => (
                    <option value={t.value}>{t.text}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {this.state.uom !== "" && (
            <React.Fragment>
              <div className="row">
                <div className="col-md-6">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th style={{ width: "200px" }}>Secondary UOM</th>
                        <th style={{ width: "200px" }}>Conversion</th>
                        <th style={{ width: "100px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            className={
                              this.state.secondaryUOMError !== ""
                                ? "form-group has-error"
                                : "form-group "
                            }
                          >
                            <select
                              className="form-control"
                              value={this.state.secondaryUOMValue}
                              onChange={(e) => {
                                this.setState({
                                  secondaryUOMValue: e.target.value,
                                  secondaryUOM:
                                    e.target.options[e.target.selectedIndex]
                                      .text,
                                  secondaryUOMError: "",
                                });
                              }}
                            >
                              <option value="">Select UOM</option>
                              {this.state.uoms.map((t) => (
                                <option value={t.value}>{t.text}</option>
                              ))}
                            </select>
                            {this.state.secondaryUOMError !== "" && (
                              <span className="help-block">
                                {this.state.secondaryUOMError}
                              </span>
                            )}
                          </div>
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            onChange={(evt) =>
                              this.setState({
                                conversion: Number(evt.target.value),
                              })
                            }
                            value={this.state.conversion}
                          />
                          {this.state.conversionError !== "" && (
                            <span className="help-block">
                              {this.state.conversionError}
                            </span>
                          )}
                        </td>
                        <td>
                          <button
                            type="submit"
                            className="btn btn-md btn-default btn-flat"
                            onClick={this.onAddSecondaryUOM}
                          >
                            Add
                          </button>
                        </td>
                      </tr>
                      {this.state.secondaryUOMs &&
                        this.state.secondaryUOMs.length > 0 &&
                        this.state.secondaryUOMs.map((s, index) => (
                          <tr key={index}>
                            <td>1 {s.SecondaryUOM} = </td>
                            {this.state.productTypeID !== 4 && (
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  style={{ width: "50%", display: "inline" }}
                                  onChange={(evt) => {
                                    let secondaryUOMs = this.state
                                      .secondaryUOMs;
                                    secondaryUOMs[index].Conversion = Number(
                                      evt.target.value
                                    );
                                    this.setState({
                                      secondaryUOMs: secondaryUOMs,
                                    });
                                  }}
                                  value={s.Conversion}
                                />
                                {this.state.uoms.filter(
                                  (f) => f.value === this.state.uom
                                ).length > 0
                                  ? " (" +
                                  this.state.uoms.filter(
                                    (f) => f.value === this.state.uom
                                  )[0].text +
                                  ") "
                                  : ""}
                              </td>
                            )}
                            <td>
                              <button
                                type="submit"
                                className="btn btn-md btn-default btn-flat"
                                onClick={() => {
                                  let secondaryUOMs = this.state
                                    .secondaryUOMs;
                                  secondaryUOMs.splice(index, 1);

                                  let masterUOMs = [];

                                  masterUOMs.push({
                                    value: this.state.uom,
                                    text: this.state.uoms.filter(
                                      (f) => f.value === this.state.uom
                                    )[0].text,
                                  });

                                  for (
                                    let su = 0;
                                    su < secondaryUOMs.length;
                                    su++
                                  ) {
                                    const obj1 = secondaryUOMs[su];
                                    masterUOMs.push({
                                      value: obj1.SecondaryUOMValue,
                                      text: obj1.SecondaryUOM,
                                    });
                                  }

                                  this.setState({
                                    secondaryUOMs: secondaryUOMs,
                                    masterUOMs: masterUOMs,
                                  });
                                }}
                              >
                                <i className="fa fa-remove"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Default sales UOM</label>
                    <select
                      className="form-control"
                      value={this.state.salesUOM}
                      onChange={(evt) =>
                        this.setState({ salesUOM: evt.target.value })
                      }
                    >
                      {this.state.masterUOMs.map((t) => (
                        <option value={t.value}>{t.text}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Default purchase UOM</label>
                    <select
                      className="form-control"
                      value={this.state.pruchaseUOM}
                      onChange={(evt) =>
                        this.setState({ pruchaseUOM: evt.target.value })
                      }
                    >
                      {this.state.masterUOMs.map((t) => (
                        <option value={t.value}>{t.text}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </PageSection>
      )}
    </React.Fragment>
  );
}

renderPackage() {
  return (
    <React.Fragment>
      {this.state.productTypeID === 5 && (
        <PageSection title="Package">
          <div className="row">
            <div className="col-md-6">
              <div
                className={
                  this.state.validityInDaysError !== ""
                    ? "form-group has-error"
                    : "form-group "
                }
              >
                <label>Validity in day(s)</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="In days"
                  onKeyPress={this.handleKeyPress}
                  style={{ width: "150px" }}
                  onChange={(evt) =>
                    this.setState({
                      validityInDays: evt.target.value,
                      validityInDaysError: "",
                    })
                  }
                  value={this.state.validityInDays}
                />
                {this.state.validityInDaysError !== "" && (
                  <span className="help-block">
                    {this.state.validityInDaysError}
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* <table className="table table-bordered table-striped" style={{ width: "300px" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "300px" }}>Services</th>
                                <th style={{ width: "100px" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            
                        </tbody>
                    </table> */}
          <div className="row">
            <div className="col-md-6">
              <table className="table table-bordered table-striped">
                {/* style={{ width: "740px" }} */}
                <thead>
                  <tr>
                    {/* <th style={{ width: "500px" }}>Services</th>
                                        <th style={{ width: "120px" }}>Retail Price</th>
                                        <th style={{ width: "120px" }}></th> */}
                    <th style={{ width: "45%" }}>Services</th>
                    <th>Retail Price</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div
                        className={
                          this.state.packageServicesError !== ""
                            ? "form-group has-error"
                            : "form-group "
                        }
                      >
                        <select
                          className="form-control"
                          value={this.state.serviceID}
                          onChange={(evt) =>
                            this.setState({
                              service:
                                evt.target.options[evt.target.selectedIndex]
                                  .text,
                              serviceID: evt.target.value,
                              packageServicesError: "",
                            })
                          }
                        >
                          <option value="">Select Service</option>
                          {this.state.services.map((service) => (
                            <option value={service.key}>
                              {service.Name}
                            </option>
                          ))}
                        </select>
                        {this.state.packageServicesError !== "" && (
                          <span className="help-block">
                            {this.state.packageServicesError}
                          </span>
                        )}
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-default btn-md"
                        onClick={this.onAddService}
                      >
                        ADD TO LIST{" "}
                      </button>
                    </td>
                  </tr>
                  {this.state.packageServices.map((packageService, index) => (
                    <tr>
                      <td>{packageService.Service}</td>
                      <td>{packageService.RetailPrice}</td>
                      <td>
                        <div className="input-group-btn">
                          <button
                            type="button"
                            className="btn btn-default btn-md"
                            onClick={() => this.onRemoveService(index)}
                          >
                            <i className="fa fa-trash-o"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {this.state.packageServices.length === 0 && (
                    <tr>
                      <td colSpan="3">
                        <i>No services added</i>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </PageSection>
      )}
    </React.Fragment>
  );
}

renderAdditonalSettings() {
  return (
    <PageSection title="Additional Settings">
      <div className="row">
        <div className="col-md-4">
          {this.props.config.WhiteLabel.Code === "PINCODE" && (
            <div className="col-md-6">
              <Input
                type="number"
                label="Max Order Qty"
                value={this.state.maxOrderQty}
                onChange={(value) => this.setState({ maxOrderQty: value })}
              />
            </div>
          )}
          <div className="col-md-12">
            {this.state.productTypeID !== 2 && (
              <div
                className={
                  this.state.membershipError !== ""
                    ? "form-group has-error"
                    : "form-group "
                }
              >
                <label>Associate membership&nbsp;</label>
                <span
                  className="fa fa-info-circle"
                  data-tip
                  data-for="associateMemebrshipToolTip"
                ></span>
                <ReactTooltip
                  id="associateMemebrshipToolTip"
                  type="dark"
                  place="top"
                  effect="solid"
                >
                  <span>
                    Membership is assigned to customer on purchase of this
                    product
                  </span>
                </ReactTooltip>
                <div className="input-group">
                  <Switch
                    checked={this.state.associateMembership}
                    onChange={() => {
                      this.setState({
                        associateMembership: !this.state.associateMembership,
                      });
                    }}
                  />
                </div>
                {/* <input type="checkbox"
                                    checked={this.state.associateMembership}
                                    onChange={() => this.setState({ associateMembership: !this.state.associateMembership })} />
                                <label>Associate Membership</label> */}
              </div>
            )}
          </div>
          {this.state.associateMembership && this.state.productTypeID !== 2 && (
            <div className="col-md-12">
              <div
                className={
                  this.state.membershipError !== ""
                    ? "form-group has-error"
                    : "form-group "
                }
              >
                <label>Membership&nbsp;</label>
                <select
                  className="form-control"
                  value={this.state.membershipID}
                  onChange={(evt) =>
                    this.setState({
                      membershipID: evt.target.value,
                      membership:
                        evt.target.options[evt.target.selectedIndex].text,
                    })
                  }
                >
                  <option value="">Select membership</option>
                  {this.props.config.ActiveMemberships.map((m, index) => (
                    <option value={m.key}>{m.Name}</option>
                  ))}
                </select>
                {this.state.membershipError.trim() !== "" && (
                  <span className="help-block">
                    {this.state.membershipError}
                  </span>
                )}
              </div>
            </div>
          )}
          {(this.state.productTypeID === 1 ||
            this.state.productTypeID === 2) && (
              <div className="col-md-12">
                <div className="form-group">
                  <label>Repack/Produce&nbsp;</label>
                  <span
                    className="fa fa-info-circle"
                    data-tip
                    data-for="repackProduceToolTip"
                  ></span>
                  <ReactTooltip
                    id="repackProduceToolTip"
                    type="dark"
                    place="top"
                    effect="solid"
                  >
                    <span>
                      Please select this option if this product is repacked or
                      prodcued by you. <br />
                      E.g, packing loose sugar into packs of 1kg.
                    </span>
                  </ReactTooltip>
                  <div className="input-group">
                    <Switch
                      checked={this.state.repackProduce}
                      onChange={() => {
                        this.setState({
                          repackProduce: !this.state.repackProduce,
                        });
                      }}
                    />
                  </div>
                  {/* <input type="checkbox" checked={this.state.repackProduce} onChange={() => this.setState({ repackProduce: !this.state.repackProduce })} />
                            <label>Repack/Produce</label> */}
                </div>
              </div>
            )}
          {this.state.productTypeID !== 4 && this.state.productTypeID !== 5 && (
            <div className="col-md-12">
              <div className="form-group">
                <label>BOM&nbsp;</label>
                <span
                  className="fa fa-info-circle"
                  data-tip
                  data-for="bomToolTip"
                ></span>
                <ReactTooltip
                  id="bomToolTip"
                  type="dark"
                  place="top"
                  effect="solid"
                >
                  <span>
                    Enable bill of materials (BOM) option to specify raw
                    materials required to prepare/service this product. <br />
                    E.g specify ingredients required to prepare pizza or
                    consumables used for facial
                  </span>
                </ReactTooltip>
                <div className="input-group">
                  <Switch
                    checked={this.state.bom}
                    onChange={() => {
                      this.setState({ bom: !this.state.bom });
                    }}
                  />
                </div>
                {/* <input type="checkbox" checked={this.state.bom} onChange={() => this.setState({ bom: !this.state.bom })} />
                                <label>BOM</label> */}
              </div>
            </div>
          )}
          {this.state.bom && (
            <div className="col-md-12">
              <div className="form-group">
                <label>Manually specify product consumption&nbsp;</label>
                <span
                  className="fa fa-info-circle"
                  data-tip
                  data-for="productConsumptionToolTip"
                ></span>
                <ReactTooltip
                  id="productConsumptionToolTip"
                  type="dark"
                  place="top"
                  effect="solid"
                >
                  <span>
                    Enable this option if the raw materials used and qty used
                    are know only at the time of billing. <br />
                    E.g color, brand and qty of for hair color tubes may vary
                    from client to client.
                  </span>
                </ReactTooltip>
                <div className="input-group">
                  <Switch
                    checked={this.state.bomManual}
                    onChange={() => {
                      this.setState({ bomManual: !this.state.bomManual });
                    }}
                  />
                </div>
                {/* <input type="checkbox" checked={this.state.bomManual} onChange={() => this.setState({ bomManual: !this.state.bomManual })} />
                                <label>Manually specify product consumption</label> */}
              </div>
            </div>
          )}
          {this.state.productTypeID === 1 && (
            <div className="col-md-12">
              <div className="form-group">
                <label>Can be used as consumable&nbsp;</label>
                <span
                  className="fa fa-info-circle"
                  data-tip
                  data-for="consumableToolTip"
                ></span>
                <ReactTooltip
                  id="consumableToolTip"
                  type="dark"
                  place="top"
                  effect="solid"
                >
                  <span>
                    Enable this option if this product can be used as raw
                    material/consumable for other product/services.
                  </span>
                </ReactTooltip>
                <div className="input-group">
                  <Switch
                    checked={this.state.canBeUsedAsConsumable}
                    onChange={() => {
                      this.setState({
                        canBeUsedAsConsumable: !this.state
                          .canBeUsedAsConsumable,
                      });
                    }}
                  />
                </div>
                {/* <input type="checkbox" checked={this.state.canBeUsedAsConsumable} onChange={() => this.setState({ canBeUsedAsConsumable: !this.state.canBeUsedAsConsumable })} />
                                <label>Can be used as consumable</label> */}
              </div>
            </div>
          )}
        </div>
        <div className="col-md-4">
          <div className="col-md-12">
            <div className="form-group">
              <label>Favourite&nbsp;</label>
              <span
                className="fa fa-info-circle"
                data-tip
                data-for="favouriteToolTip"
              ></span>
              <ReactTooltip
                id="favouriteToolTip"
                type="dark"
                place="top"
                effect="solid"
              >
                <span>
                  Fast moving product or popular products that can be accessed
                  easily during billing under favourites option
                </span>
              </ReactTooltip>
              <div className="input-group">
                <Switch
                  checked={this.state.favourite}
                  onChange={() => {
                    this.setState({ favourite: !this.state.favourite });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Show as additional charges in bill print &nbsp;</label>
              {/* <span className="fa fa-info-circle" data-tip data-for='showAsAdditionalChargesToolTip' ></span>
                                <ReactTooltip id='showAsAdditionalChargesToolTip' type='dark' place="top" effect="solid">
                                    <span>When this product is selected at the time of billing the product will be printed as an additional charge  </span>
                                </ReactTooltip> */}
              <div className="input-group">
                <Switch
                  checked={this.state.showAsAdditionalCharges}
                  onChange={() => {
                    this.setState({
                      showAsAdditionalCharges: !this.state
                        .showAsAdditionalCharges,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          {this.props.registration.registration.hasOwnProperty(
            "OMNIEnabled"
          ) && (
              <div className="col-md-12">
                <div className="form-group">
                  <label>Show online&nbsp;</label>
                  <span
                    className="fa fa-info-circle"
                    data-tip
                    data-for="showOnlineToolTip"
                  ></span>
                  <ReactTooltip
                    id="showOnlineToolTip"
                    type="dark"
                    place="top"
                    effect="solid"
                  >
                    <span>Show this product in your online store</span>
                  </ReactTooltip>
                  <div className="input-group">
                    <Switch
                      checked={this.state.OMNIEnabled}
                      onChange={() => {
                        this.setState({ OMNIEnabled: !this.state.OMNIEnabled });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          {this.props.registration.registration.hasOwnProperty(
            "OMNIEnabled"
          ) && (
              <div className="col-md-12">
                <div className="form-group">
                  <label>Packaging Charges&nbsp;</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(evt) =>
                      this.setState({ packagingCharges: evt.target.value })
                    }
                    value={this.state.packagingCharges}
                  />
                </div>
              </div>
            )}
          {this.props.registration.registration.hasOwnProperty(
            "OMNIEnabled"
          ) &&
            this.state.OMNIEnabled && (
              <div className="col-md-12">
                <Input
                  type="switch"
                  label="Out of Stock"
                  onChange={(value) => this.setState({ outOfStock: value })}
                  value={this.state.outOfStock}
                />
              </div>
            )}
        </div>
        <div className="col-md-4">
          <div className="col-md-12">
            <div className="form-group">
              <label>Allow price edit&nbsp;</label>
              <span
                className="fa fa-info-circle"
                data-tip
                data-for="allowPriceEditToolTip"
              ></span>
              <ReactTooltip
                id="allowPriceEditToolTip"
                type="dark"
                place="top"
                effect="solid"
              >
                <span>
                  User will be able to change retail price / selling price at
                  the time of billing
                </span>
              </ReactTooltip>
              <div className="input-group">
                <Switch
                  checked={this.state.allowPriceEdit}
                  onChange={() => {
                    this.setState({
                      allowPriceEdit: !this.state.allowPriceEdit,
                    });
                  }}
                />
              </div>
              {/* <input type="checkbox" checked={this.state.allowPriceEdit} onChange={() => this.setState({ allowPriceEdit: !this.state.allowPriceEdit })} />
                            <label >Allow price edit</label> */}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Discountable&nbsp;</label>
              <span
                className="fa fa-info-circle"
                data-tip
                data-for="discountableToolTip"
              ></span>
              <ReactTooltip
                id="discountableToolTip"
                type="dark"
                place="top"
                effect="solid"
              >
                <span>
                  Invoice level dicounts, line item level discounts,
                  membership discounts, offer dicounts can be applied on this
                  product at the time of billing. <br />
                  If this opton is not enabled then no discount can be applied
                  on this product.{" "}
                </span>
              </ReactTooltip>
              <div className="input-group">
                <Switch
                  checked={this.state.allowDiscount}
                  onChange={() => {
                    this.setState({
                      allowDiscount: !this.state.allowDiscount,
                    });
                  }}
                />
              </div>
              {/* <input type="checkbox" checked={this.state.allowDiscount} onChange={() => this.setState({ allowDiscount: !this.state.allowDiscount })} />
                            <label >Discountable</label> */}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Allow tax change&nbsp;</label>
              <span
                className="fa fa-info-circle"
                data-tip
                data-for="allowTaxChange"
              ></span>
              <ReactTooltip
                id="allowTaxChange"
                type="dark"
                place="top"
                effect="solid"
              >
                <span>
                  Tax can be changed by the user at the time of billing{" "}
                </span>
              </ReactTooltip>
              <div className="input-group">
                <Switch
                  checked={this.state.allowTaxChange}
                  onChange={() => {
                    this.setState({
                      allowTaxChange: !this.state.allowTaxChange,
                    });
                  }}
                />
              </div>
              {/* <input type="checkbox" checked={this.state.allowTaxChange} onChange={() => this.setState({ allowTaxChange: !this.state.allowTaxChange })} />
                            <label >Allow tax change</label> */}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Prompt for qty at the time billing&nbsp;</label>
              <span
                className="fa fa-info-circle"
                data-tip
                data-for="promptQtyToolTip"
              ></span>
              <ReactTooltip
                id="promptQtyToolTip"
                type="dark"
                place="top"
                effect="solid"
              >
                <span>
                  When this product is selected at the time of billing a poup
                  screen with option to change qty will be displayed{" "}
                </span>
              </ReactTooltip>
              <div className="input-group">
                <Switch
                  checked={this.state.qtyPrompt}
                  onChange={() => {
                    this.setState({ qtyPrompt: !this.state.qtyPrompt });
                  }}
                />
              </div>
              {/* <input type="checkbox" checked={this.state.qtyPrompt}
                                onChange={() => this.setState({ qtyPrompt: !this.state.qtyPrompt })} />
                            <label >Prompt for Qty at the time billing</label> */}
            </div>
          </div>
          <div className="col-md-12">
            <Input
              type="number"
              label="Sequence No"
              value={this.state.sequenceNo}
              onChange={(value) => this.setState({ sequenceNo: value })}
            />
          </div>
        </div>
      </div>
    </PageSection>
  );
}

renderAlert() {
  return (
    <React.Fragment>
      {(this.state.productTypeID === 1 ||
        this.state.productTypeID === 3 ||
        this.state.productTypeID === 5) && (
          <PageSection title="Alerts">
            <div className="row">
              <div className="col-md-3">
                <Input
                  type="switch"
                  label="Enable alerts"
                  value={this.state.enableAlerts}
                  onChange={(value) => {
                    this.setState({
                      enableAlerts: value,
                      notifyCustomerInDaysError: "",
                      smsTemplateError: "",
                      notifyCustomerInDays: 0,
                      smsTemplateID: "",
                    });
                  }}
                />
              </div>
              {this.state.enableAlerts && (
                <React.Fragment>
                  <div className="col-md-3">
                    <Input
                      type="number"
                      label={
                        this.state.productTypeID === 1 ||
                          this.state.productTypeID === 3
                          ? "Notify customer after (days)"
                          : "Notify customer before (days)"
                      }
                      onChange={(value) =>
                        this.setState({
                          notifyCustomerInDays: value,
                          notifyCustomerInDaysError: "",
                        })
                      }
                      value={this.state.notifyCustomerInDays}
                      error={this.state.notifyCustomerInDaysError}
                    />
                  </div>
                  {/* <div className="col-md-3">
                                <Input
                                    type="select"
                                    label="SMS Template"
                                    value={this.state.smsTemplateID}
                                    onChange={(value) => this.setState({ smsTemplateID: value, smsTemplateError: '' })}
                                    error={this.state.smsTemplateError}
                                >
                                    <option value="">Select template</option>
                                    {this.state.smsTemplates.map((temmplate) =>
                                        <option value={temmplate.key}>{temmplate.Name}</option>
                                    )}
                                </Input>
                            </div> */}
                </React.Fragment>
              )}
            </div>
          </PageSection>
        )}
    </React.Fragment>
  );
}

renderImages() {
  return (
    <PageSection title="Images">
      <div className="row">
        <div className="col-md-3">
          <Dropzone onDrop={(files) => this.onDrop(files)}>
            <div>
              Try dropping some files here, or click to select files to
              upload.
            </div>
          </Dropzone>
        </div>
        <div className="col-md-9">
          <ul className="mailbox-attachments clearfix">
            {this.state.images.map((image, index) => (
              <li>
                {/* {image.ImagePath} */}
                <span className="mailbox-attachment-icon has-img">
                  <img src={image.ImagePath} alt="Attachment" />
                </span>
                <div className="mailbox-attachment-info">
                  <span className="mailbox-attachment-name">
                    {image.ImageName}
                  </span>
                  <span className="mailbox-attachment-size">
                    {Math.round(image.ImageSize / 1024)} KB
                    <a
                      onClick={() => this.onRemoveImage(index)}
                      className="btn btn-default btn-xs pull-right"
                    >
                      <i className="fa fa-trash"></i>
                    </a>
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </PageSection>
  );
}

renderAdditonalAttributes() {
  return (
    <PageSection title="Additonal Information">
      <div className="row">
        <div className="col-md-3">
          <div className="form-group">
            <label>{this.state.customField1DisplayName}</label>
            <input
              type="text"
              className="form-control"
              onChange={(evt) =>
                this.setState({
                  customField1: evt.target.value,
                  firstNameError: false,
                })
              }
              value={this.state.customField1}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>{this.state.customField2DisplayName}</label>
            <input
              type="text"
              className="form-control"
              onChange={(evt) =>
                this.setState({ customField2: evt.target.value })
              }
              value={this.state.customField2}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>{this.state.customField3DisplayName}</label>
            <input
              type="text"
              className="form-control"
              onChange={(evt) =>
                this.setState({
                  customField3: evt.target.value,
                  firstNameError: false,
                })
              }
              value={this.state.customField3}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>{this.state.customField4DisplayName}</label>
            <input
              type="text"
              className="form-control"
              onChange={(evt) =>
                this.setState({ customField4: evt.target.value })
              }
              value={this.state.customField4}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="form-group">
            <label>{this.state.customField5DisplayName}</label>
            <input
              type="text"
              className="form-control"
              onChange={(evt) =>
                this.setState({
                  customField5: evt.target.value,
                  firstNameError: false,
                })
              }
              value={this.state.customField5}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>{this.state.customField6DisplayName}</label>
            <input
              type="text"
              className="form-control"
              onChange={(evt) =>
                this.setState({ customField6: evt.target.value })
              }
              value={this.state.customField6}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          {this.props.match.params.key && (
            <AuditTrail
              {...this.props}
              module={constants.MODULE_PRODUCT}
              parentKey={this.props.match.params.key}
            />
          )}
        </div>
      </div>
    </PageSection>
  );
}

formControls() {
  return (
    <React.Fragment>
      {this.renderGeneralDetails()}

      {this.renderPriceDetails()}

      {this.renderInventoryDetails()}

      {!this.state.pincodeMerchant && this.renderUOM()}

      {!this.state.pincodeMerchant && this.renderPackage()}

      {!this.state.pincodeMerchant && this.renderAdditonalSettings()}

      {!this.state.pincodeMerchant && this.renderAlert()}

      {this.renderImages()}

      {!this.state.pincodeMerchant && this.renderAdditonalAttributes()}
    </React.Fragment>
  );
}

render() {
  return (
    <React.Fragment>
      <Page
        title={this.props.match.params.key ? "Edit Product" : "New Product"}
        showSaveButton={true}
        showCancelButton={true}
        processing={this.state.saving}
        onClick={(action) => {
          if (action === "cancel") {
            this.onCancel();
          } else if (action === "save") {
            this.onSaveProduct();
          }
        }}
      >
        {this.formControls()}
      </Page>

      {this.state.isShowingModalAddCategory && (
        <AddEditProductCategory
          {...this.props}
          // mobile={this.props.mobile}
          showWindow={true}
          type={this.state.categoryOrSubCategory}
          productCategory={null}
          parentID={this.state.categoryID}
          parent={this.state.category}
          onSave={(key, name) => {
            if (
              this.state.categoryOrSubCategory ===
              actionTypes.PRODUCT_CATEGORY
            ) {
              this.setState({
                categoryID: key,
                category: name,
                isShowingModalAddCategory: false,
              });
            } else {
              this.setState({
                subCategoryID: key,
                subCategory: name,
                isShowingModalAddCategory: false,
              });
            }
          }}
          onClose={() => this.setState({ isShowingModalAddCategory: false })}
        />
      )}

      {this.state.isShowingModalAddBrand && (
        <AddEditProductBrand
          {...this.props}
          // mobile={this.props.mobile}
          showWindow={true}
          onSave={(key, name) => {
            //reload brands
            // this.props.getProductBrands(this.props.user.user.RegistrationID)
            this.setState({
              isShowingModalAddBrand: false,
              brand: name,
              brandID: key,
            });
          }}
          onClose={() => this.setState({ isShowingModalAddBrand: false })}
        />
      )}
    </React.Fragment>
  );
}
}

export default withRouter(Product);
