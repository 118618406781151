import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import * as actionTypes from "../actionTypes";
import * as constants from "../Constatnts";

// Initialize PouchDB
// const db = new PouchDB('products');
const createDatabase = () => {
    return new PouchDB('products');
};
const db = createDatabase();

// Enable the PouchDB Find plugin
PouchDB.plugin(PouchDBFind);

const insertProduct = async (product) => {
  try {
    const data = { ...product, _id: product.key };
    const response = await db.put(data);
    console.log('Product inserted successfully', response);
  } catch (error) {
    console.error('Error inserting product', error);
  }
};
const deleteAllData = async () => {
  try {
    // Destroy the database
    await db.destroy();
    db = createDatabase();
    console.log('Database deleted successfully');
  } catch (error) {
    console.error('Error deleting database:', error);
  }
};

// const bulkInsertProducts = async (products) => {
//     try {
//         const response = await db.bulkDocs(products);
//         console.log('Bulk insert response:', response);

//         // Check for conflicts in the response
//         response.forEach((res) => {
//             if (res.error && res.name === 'conflict') {
//                 console.warn(`Conflict for product ID: ${res.id}`);
//                 // Handle conflict, e.g., log it, retry, etc.
//             }
//         });
//     } catch (error) {
//         console.error('Error during bulk insert:', error);
//     }
// };

const bulkInsertProducts = async (products) => {
  try {
    const response = await db.bulkDocs(products);
    console.log('Bulk insert response:', response);

    // Check for conflicts in the response
    const conflicts = response.filter(res => res.error && res.name === 'conflict');

    if (conflicts.length > 0) {
      console.warn('Conflicts detected during bulk insert:', conflicts);

      for (const conflict of conflicts) {
        // Retrieve the existing document
        const existingProduct = await db.get(conflict.id);

        // Prepare the updated product, merging the existing and new data
        const updatedProduct = { ...existingProduct, ...products.find(p => p._id === conflict.id) };

        // Retry updating the existing product
        await updateProductWithRetry(updatedProduct);
      }
    }
  } catch (error) {
    console.error('Error during bulk insert:', error);
  }
};

const fetchProductsFromPouchDB = async (registerId) => {
  try {
    const result = await db.find({
      selector: {
        RegistrationID: registerId // Match by registerId
      },
      limit: 10000 // Increase limit if necessary
    });

    console.log('Products with registerId:', result.docs);
    const getproduct = result.docs.map(product => {
      const { _id, _rev, ...rest } = product; // Exclude _id and _rev
      return rest; // Return the fetched products without _id and _rev
    });

    return getproduct; // Return the fetched products
  } catch (error) {
    console.error('Error fetching products by registerId:', error);
  }
};

const updateProductWithRetry = async (product) => {
  try {
    const response = await db.put(product);
    console.log('Product updated successfully:', response);
  } catch (error) {
    if (error.name === 'conflict') {
      console.warn('Update conflict detected, retrying...');
      const existingProduct = await db.get(product._id);
      const updatedProduct = { ...existingProduct, ...product };
      await updateProductWithRetry(updatedProduct); // Retry the update
    } else {
      console.error('Error updating product:', error);
    }
  }
};
const syncProductsWithAPI = async (apiProducts, registerId) => {
    // Fetch products from PouchDB
    const pouchProducts = await fetchProductsFromPouchDB(registerId);
  
    // If there are no pouchProducts, directly insert all apiProducts
    if (!pouchProducts || pouchProducts.length === 0) {
      const newProducts = apiProducts.map(product => ({ ...product, _id: product.key }));
  
      // Bulk insert new products into PouchDB
      if (newProducts.length) {
        await bulkInsertProducts(newProducts);
      }
  
      // Fetch and return the updated product list from PouchDB
      const updatedProducts = await fetchProductsFromPouchDB(registerId);
      return updatedProducts;
    }
  
    // If pouchProducts exist, create a map for comparison
    const pouchProductMap = new Map(pouchProducts.map(product => [product.key, product]));
    const newProducts = [];
    const updatedProducts = [];
  
    for (const apiProduct of apiProducts) {
      const pouchProduct = pouchProductMap.get(apiProduct.key);
  
      if (!pouchProduct) {
        // Prepare new product for bulk insert if it's not in PouchDB
        newProducts.push({ ...apiProduct, _id: apiProduct.key });
      } else {
        // Check if the existing pouchProduct needs to be updated
        const needsUpdate = Object.keys(apiProduct).some(key => {
          if (Array.isArray(apiProduct[key]) && Array.isArray(pouchProduct[key])) {
            // Compare arrays by length and values
            return apiProduct[key].length !== pouchProduct[key].length;
          }
          return apiProduct[key] !== pouchProduct[key];
        });
  
        if (needsUpdate) {
          try {
            // Fetch the latest version of the existing product
            const existingProduct = await db.get(apiProduct.key);
  
            // Prepare updated product with the latest revision
            const updatedProduct = { ...existingProduct, ...apiProduct };
            updatedProducts.push(updatedProduct);
          } catch (error) {
            if (error.name === 'not_found') {
              console.error(`Product not found for update: ${apiProduct.key}`);
            } else {
              console.error('Error fetching existing product for update:', error);
            }
          }
        }
      }
    }
  
    // Bulk insert new products
    if (newProducts.length) {
      await bulkInsertProducts(newProducts);
    }
  
    // Update existing products with retry logic
    for (const product of updatedProducts) {
      await updateProductWithRetry(product);
    }
  
    // Fetch and return the updated product list from PouchDB
    const updatedProductsList = await fetchProductsFromPouchDB(registerId);
    return updatedProductsList;
  };
  

// const syncProductsWithAPI = async (apiProducts, registerId) => {
//     //  return async (dispatch)  => {


//   const pouchProducts = await fetchProductsFromPouchDB(registerId);
//   const pouchProductMap = new Map(pouchProducts.map(product => [product.key, product]));

//   const newProducts = [];
//   const updatedProducts = [];

//   for (const apiProduct of apiProducts) {
//     const pouchProduct = pouchProductMap.get(apiProduct.key);
//     // console.log("pouchProduct",pouchProduct)
//     if (!pouchProduct) {
//       // Prepare new product for bulk insert
//       newProducts.push({ ...apiProduct, _id: apiProduct.key });
//     } else {
//       // If product exists, check for updates
//       // const needsUpdate = Object.keys(apiProduct).some(key =>
//       //     apiProduct[key] !== pouchProduct[key] && key !== '_id'
//       // );
//       const needsUpdate = Object.keys(apiProduct).some(key => {
//         // If both are arrays, compare them using length and content
//         if (Array.isArray(apiProduct[key]) && Array.isArray(pouchProduct[key])) {
//           return apiProduct[key].length !== pouchProduct[key].length
//           // return apiProduct[key].length !== pouchProduct[key].length || 
//           //        !apiProduct[key].every((val, index) => val === pouchProduct[key][index]);
//         }

//         // Otherwise, compare normally
//         return apiProduct[key] !== pouchProduct[key];
//       });
//       //   console.log("needsUpdate",needsUpdate)

//       if (needsUpdate) {
//         try {
//           // Fetch the latest version of the existing product
//           const existingProduct = await db.get(apiProduct.key);

//           // Prepare updated product with the latest revision
//           const updatedProduct = { ...existingProduct, ...apiProduct };
//           updatedProducts.push(updatedProduct);
//         } catch (error) {
//           if (error.name === 'not_found') {
//             console.error(`Product not found for update: ${apiProduct.key}`);
//           } else {
//             console.error('Error fetching existing product for update:', error);
//           }
//         }
//       }
//     }
//   }

//   // Bulk insert new products
//   if (newProducts.length) {
//     await bulkInsertProducts(newProducts);
//   }

//   // Update existing products with retry logic
//   for (const product of updatedProducts) {
//     await updateProductWithRetry(product);
//   }

//   // Fetch and return the updated product list from PouchDB
//   const getproduct = await fetchProductsFromPouchDB(registerId);
//   // dispatch({ type: actionTypes.GET_ACTIVE_PRODUCTS, getproduct });
//   // console.log("getproduct", getproduct)
//   return getproduct
//     //  }
// };

export { syncProductsWithAPI, deleteAllData ,fetchProductsFromPouchDB};
