import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';

// Initialize PouchDB
// const db = new PouchDB('auth');
const createDatabase = () => {
    return new PouchDB('auth');
};
const db = createDatabase();
// Enable the PouchDB Find plugin
PouchDB.plugin(PouchDBFind);

const insertAuth = async (auth) => {
    try {
        const data = { ...auth, _id: auth.RegistrationID };
        const response = await db.put(data);
        console.log('auth inserted successfully', response);
    } catch (error) {
        console.error('Error inserting auth', error);
    }
};
// const fetchAuth = async () => {
//     try {
//       const allDocs = await db.allDocs({ include_docs: true });
//       console.log("allDocs",allDocs);
//       const user = allDocs.rows.map(row => row.doc);
//       return user
//     //   setProducts(products);
//     } catch (error) {
//       console.error('Failed to fetch auth', error);
//     }
//   };
const fetchAuth = async () => {
    try {
        const allDocs = await db.allDocs({ include_docs: true });
        console.log("allDocs", JSON.stringify(allDocs, null, 2)); // Use JSON.stringify for clearer output
        const users = allDocs.rows.map(row => row.doc);
        return users; // Return the array of documents
    } catch (error) {
        console.error('Failed to fetch auth', error);
    }
};

const deleteAuth = async () => {
    try {
        // Destroy the database
        await db.destroy();
        // Re-create the database after deletion
        db = createDatabase();
        console.log('AuthDatabase deleted successfully');
        return true
       
    } catch (error) {
        console.error('Error deleting database:', error);
    }
};

export { insertAuth, deleteAuth, fetchAuth }