import firebase, { database } from "../firebase";
import * as actionTypes from "../actionTypes";
import * as constants from "../Constatnts";

export function getActiveProductsCountByCategoryID(categoryIDs) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            terms: {
              "CategoryID.keyword": categoryIDs,
            },
          },
          {
            match: {
              IsDeleted: false,
            },
          },
        ],
      },
    },
    aggs: {
      productCount: {
        terms: {
          field: "CategoryID.keyword",
          size: 1000,
        },
      },
    },
  };
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getProducts");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;
      let productCount = [];
      sanitizedMessage.aggregations.productCount.buckets.map(
        (categoryBucket, index) => {
          productCount.push({
            key: categoryBucket.key,
            count: categoryBucket.doc_count,
          });
        }
      );
      return productCount;
    });
  };
}

export function getActiveProductsCountBySubCategoryID(subCategoryIDs) {
  //example  ["Zz6tr9YK8ogK40WYlu1B", "41eYVxP3Xn6BXbWhjpmi"]
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            terms: {
              "SubCategoryID.keyword": subCategoryIDs,
            },
          },
          {
            match: {
              IsDeleted: false,
            },
          },
        ],
      },
    },
    aggs: {
      productCount: {
        terms: {
          field: "SubCategoryID.keyword",
          size: 1000,
        },
      },
    },
  };
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getProducts");

    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;
      let productCount = [];
      sanitizedMessage.aggregations.productCount.buckets.map(
        (categoryBucket, index) => {
          productCount.push({
            key: categoryBucket.key,
            count: categoryBucket.doc_count,
          });
        }
      );
      return productCount;
    });
  };
}

export function addNewProduct(prod) {
  return (dispatch, getState) => {
    // prod.ActionOn= firebase.firestore.FieldValue.serverTimestamp()
    // prod.ActionBy = getState().user.user.Name
    // prod.ActionByUID = getState().user.user.UID
    // prod.ActionByEmailID = getState().user.user.EmailID
    // prod.Action = constants.AUDIT_TRAIL_NEW

    let ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    let ActionBy = getState().user.user.Name;
    let ActionByUID = getState().user.user.UID;
    let ActionByEmailID = getState().user.user.EmailID;
    let Action = constants.AUDIT_TRAIL_NEW;

    return database
      .collection("Products")
      .add({
        ...prod,
        ActionOn: ActionOn,
        ActionBy: ActionBy,
        ActionByUID: ActionByUID,
        ActionByEmailID: ActionByEmailID,
        Action: Action,
      })
      .then(function(docRef) {
        console.log("docRef.id", docRef.id);
        return docRef.id;
      })
      .catch(function(error) {
        console.error("Error adding product: ", error);
      });
  };
}

export function updateProductImage(
  productKey,
  imagePath,
  imageName,
  imageSize
) {
  return (dispatch) => {
    return database
      .collection("Products")
      .doc(productKey)
      .update({
        Images: firebase.firestore.FieldValue.arrayUnion({
          ImagePath: imagePath,
          ImageName: imageName,
          ImageSize: imageSize,
        }),
      });
  };
}

export function getProducts(
  RegistrationID,
  from,
  size,
  option,
  searchText,
  searchProductType = "",
  searchCategory,
  searchSubCategory,
  searchBrand,
  searchTags,
  searchDescription,
  searchHSNSAC,
  forBOM = false
) {
  let searchParameters = {
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [{ match: { RegistrationID: RegistrationID } }],
        must_not: [],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    sort: [
      {
        ActionOn: {
          order: "desc",
        },
      },
    ],
  };
  if (option === "active") {
    searchParameters.query.bool.must_not.push({ match: { IsDeleted: "true" } });
  }
  if (option === "inactive") {
    searchParameters.query.bool.must_not.push({
      match: { IsDeleted: "false" },
    });
  }

  if (!forBOM) {
    searchParameters.query.bool.must_not.push({
      exists: {
        field: "ParentProductID",
      },
    });
  }
  // console.log("searchText", searchText);

  searchParameters.query.bool.should.push({
    regexp: { Name: "@" + searchText.trim().toLowerCase() + ".*" },
  });
  searchParameters.query.bool.should.push({
    regexp: { SKU: "@" + searchText.trim().toLowerCase() + ".*" },
  });
  searchParameters.query.bool.should.push({
    regexp: { BarCode: "@" + searchText.trim().toLowerCase() + ".*" },
  });
  if (searchProductType.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      term: { ProductType: searchProductType.trim().toLowerCase() },
    });
  }
  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Category: searchCategory.trim().toLowerCase() },
    });
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { SubCategory: searchSubCategory.trim().toLowerCase() },
    });
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Brand: searchBrand.trim().toLowerCase() },
    });
  }
  if (searchTags.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { Tags: "@" + searchTags.trim().toLowerCase() + ".*" },
    });
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        Description: "@" + searchDescription.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchHSNSAC.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { HSNSACCode: "@" + searchHSNSAC.trim().toLowerCase() + ".*" },
    });
  }
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getProducts");
    console.log("getProducts", JSON.stringify(searchParameters));
    if (
      getState().user.user.Role === "Store Owner" ||
      getState().user.user.Role === "Store Manager"
    ) {
      searchParameters.query.bool.must.push({
        match: {
          "StoreSpecificPrices.StoreID": getState().user.store.key,
        },
      });
    }
    return addMessage({ text: searchParameters }).then(function(result) {
      const products = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        products.push({ key: data._id, ...data._source });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: products,
      };
    });
  };
}

// export function getProducts(RegistrationID, from, size, option , searchText, searchProductType="",searchCategory,searchSubCategory,searchBrand,
//     searchTags, searchDescription, searchHSNSAC){
//     let searchParameters =   {
//         "from": from,
//         "size": size,
//         "query": {
//           "bool": {
//             "must": [
//               { "match": { "RegistrationID": RegistrationID}}
//             ],
//             "must_not": [
//               {
//                 "exists": {
//                   "field": "ParentProductID"
//                 }
//               }
//             ],
//             "should": [

//             ],
//             "minimum_should_match": 1,
//             "filter": {
//               "bool": {
//                 "must": [

//                   ]
//               }
//             }
//           }
//         },
//         "sort": [
//        {
//          "ActionOn": {
//            "order": "desc"
//          }
//        }
//      ]
//       }
//       if(option === "active")
//       {
//         searchParameters.query.bool.must_not.push( {"match": { "IsDeleted": "true" }})
//       }
//       if(option === "inactive")
//       {
//         searchParameters.query.bool.must_not.push( {"match": { "IsDeleted": "false" }})
//       }
//       searchParameters.query.bool.should.push({ "regexp": { "Name": "@"+ searchText.trim().toLowerCase() +  ".*" }})
//       searchParameters.query.bool.should.push({ "regexp": { "SKU": "@"+ searchText.trim().toLowerCase() +  ".*" }})
//       searchParameters.query.bool.should.push({ "regexp": { "BarCode": "@"+ searchText.trim().toLowerCase() +  ".*" }})
//       if(searchProductType.trim()!=="")
//       {
//         searchParameters.query.bool.filter.bool.must.push({"term":{ "ProductType": searchProductType.trim().toLowerCase()}})
//       }
//       if(searchCategory.trim()!=="")
//       {
//         searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Category": searchCategory.trim().toLowerCase() }})
//       }
//       if(searchSubCategory.trim()!=="")
//       {
//         searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "SubCategory": searchSubCategory.trim().toLowerCase() }})
//       }
//       if(searchBrand.trim()!=="")
//       {
//         searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Brand": searchBrand.trim().toLowerCase() }})
//       }
//       if(searchTags.trim()!=="")
//       {
//         searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Tags": "@"+ searchTags.trim().toLowerCase() +  ".*" }})
//       }
//       if(searchBrand.trim()!=="")
//       {
//         searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Description": "@"+ searchDescription.trim().toLowerCase() +  ".*" }})
//       }
//       if(searchHSNSAC.trim()!=="")
//       {
//         searchParameters.query.bool.filter.bool.must.push({ "regexp": { "HSNSACCode": "@"+ searchHSNSAC.trim().toLowerCase() +  ".*" }})
//       }
//     return (dispatch,getState) => {

//         let addMessage =  firebase.functions().httpsCallable('getProducts');
//     console.log('getProducts',JSON.stringify(searchParameters))
//       if (getState().user.user.Role === "Store Owner" || getState().user.user.Role === "Store Manager") {
//         searchParameters.query.bool.must.push(
//           {
//             "match": {
//               "StoreSpecificPrices.StoreID": getState().user.store.key
//             }
//           }
//         )
//       }
//         return addMessage({text: searchParameters}).then(function(result) {
//             const products = []
//             let sanitizedMessage = result.data.text;
//                 sanitizedMessage.hits.hits.map((data, index)=>{
//                 products.push({key: data._id, ...data._source})
//             })
//         return  {totalItemsCount: sanitizedMessage.hits.total.value, searchResult:products}
//         });
//     }
// }

export function getProductsForInventorySearch(
  RegistrationID,
  searchText,
  excludeUniqueNo
) {
  let searchParameters = {
    from: 0,
    size: 1000,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          { match: { RegistrationID: RegistrationID } },
          { match: { IsDeleted: "false" } },
        ],
        should: [
          {
            term: {
              ProductType: "goods for sale",
            },
          },
          {
            term: {
              ProductType: "goods not for sale",
            },
          },
        ],
        minimum_should_match: 1,
        filter: {
          bool: {
            should: [],
            minimum_should_match: 1,
          },
        },
      },
    },
    sort: [
      {
        Name: {
          order: "asc",
        },
      },
    ],
  };
  searchParameters.query.bool.filter.bool.should.push({
    regexp: { Name: "@" + searchText.trim().toLowerCase() + ".*" },
  });
  searchParameters.query.bool.filter.bool.should.push({
    regexp: { SKU: "@" + searchText.trim().toLowerCase() + ".*" },
  });
  searchParameters.query.bool.filter.bool.should.push({
    regexp: { BarCode: "@" + searchText.trim().toLowerCase() + ".*" },
  });
  // // searchParameters.query.bool.filter.bool.must.push({ "match": { "ProductDetails": searchText.trim() } })
  // // console.log(JSON.stringify(searchParameters));
  // searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "ProductDetails": searchText.trim().toLowerCase() }})
  // searchParameters.query.bool.filter.bool.should.push({ "regexp": { "SKU": "@"+ searchText.trim().toLowerCase() +  ".*" }})
  // searchParameters.query.bool.filter.bool.should.push({ "regexp": { "BarCode": "@"+ searchText.trim().toLowerCase() +  ".*" }})
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getProducts");
    return addMessage({ text: searchParameters }).then(function(result) {
      const products = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        if (data._source.hasOwnProperty("InventoryType")) {
          if (data._source.InventoryType === "Unique No" && excludeUniqueNo) {
            ///DONT PUSH
          } else {
            products.push({ key: data._id, ...data._source });
          }
        } else {
          products.push({ key: data._id, ...data._source });
        }
      });
      // console.log('getProductsForInventorySearch', getProductsForInventorySearch)
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: products,
      };
    });
  };
}

export function deleteProduct(key) {
  return (dispatch, getState) => {
    return database
      .collection("Products")
      .doc(key)
      .update({
        IsDeleted: true,
        ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
        ActionBy: getState().user.user.Name,
        ActionByUID: getState().user.user.UID,
        ActionByEmailID: getState().user.user.EmailID,

        Action: constants.AUDIT_TRAIL_DELETE,
      })
      .then(() => {
        return true;
      });
  };
}

export function getProductDetails(key) {
  return (dispatch) => {
    return database
      .collection("Products")
      .doc(key)
      .get()
      .then((doc) => {
        if (doc.exists) {
          // console.log("Product found", doc.data());
          return doc.data();
        } else {
          console.log("No such product!");
        }
      });
  };
}

export function updateProductVariants(variants, key) {
  return (dispatch, getState) => {
    let ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    let ActionBy = getState().user.user.Name;
    let ActionByUID = getState().user.user.UID;
    let ActionByEmailID = getState().user.user.EmailID;
    let Action = constants.AUDIT_TRAIL_EDIT;
    console.log("key", key);
    console.log("variants", variants);
    return database
      .collection("Products")
      .doc(key)
      .update({
        Variants1: variants,
        Variants: variants,
        ActionOn: ActionOn,
        ActionBy: ActionBy,
        ActionByUID: ActionByUID,
        ActionByEmailID: ActionByEmailID,
        Action: Action,
      });
  };
}

export function updateProduct(product, key) {
  return (dispatch, getState) => {
    // product.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
    // product.ActionBy = getState().user.user.Name
    // product.ActionByUID = getState().user.user.UID
    // product.ActionByEmailID = getState().user.user.EmailID

    // product.Action = constants.AUDIT_TRAIL_EDIT
    let ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    let ActionBy = getState().user.user.Name;
    let ActionByUID = getState().user.user.UID;
    let ActionByEmailID = getState().user.user.EmailID;
    let Action = constants.AUDIT_TRAIL_EDIT;
    console.log("updateProduct key", key);
    console.log("updateProduct product", product);
    return database
      .collection("Products")
      .doc(key)
      .update({
        ...product,
        ActionOn: ActionOn,
        ActionBy: ActionBy,
        ActionByUID: ActionByUID,
        ActionByEmailID: ActionByEmailID,
        Action: Action,
      });
  };
}

export function getActiveProducts(RegistrationID, scrollID, storeID) {
  let searchParameters = null;
  if (scrollID == "") {
    searchParameters = {
      from: 0,
      size: 2000,
      track_total_hits: true,
      query: {
        bool: {
          must: [{ match: { RegistrationID: RegistrationID } }],
          must_not: [
            { match: { IsDeleted: "true" } },
            { match: { IsArchived: "true" } },
          ],
        },
      },
      sort: [
        {
          Name: {
            order: "asc",
          },
        },
      ],
    };
  } else {
    searchParameters = {
      scroll: "1m",
      scroll_id: scrollID,
    };
  }

  return (dispatch) => {
    let addMessage = "";
    if (scrollID === "") {
      addMessage = firebase
        .functions()
        .httpsCallable("getProductsWithScrollID");
    } else {
      addMessage = firebase.functions().httpsCallable("getProductsForScrollID");
    }

    return addMessage({ text: searchParameters }).then(function(result) {
      const products = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((doc, index) => {
        let product = doc._source;
        product.ActionOn = null;
        let checkForStoreSpecificPriceOptions = true;
        if (
          product.hasOwnProperty("ParentProductID") &&
          product.ParentProductID !== ""
        ) {
          //this is variant product so selecte what rate is specified in
          checkForStoreSpecificPriceOptions = false;
        }
        // console.log(
        //   "checkForStoreSpecificPriceOptions",
        //   checkForStoreSpecificPriceOptions
        // );

        if (
          product.hasOwnProperty("AccessibleToSelectedStores") &&
          product.AccessibleToSelectedStores &&
          checkForStoreSpecificPriceOptions
        ) {
          let storePrice = product.StoreSpecificPrices.filter(
            (f) => f.StoreID === storeID
          );
          if (storePrice.length > 0) {
            product.RetailPrice = storePrice[0].RetailPrice;
            product.SupplyPrice = storePrice[0].SupplyPrice;
            product.Markup = storePrice[0].Markup;
            product.DiscountAmount = storePrice[0].hasOwnProperty(
              "DiscountAmount"
            )
              ? storePrice[0].DiscountAmount
              : 0;
            product.DiscountPercentage = storePrice[0].hasOwnProperty(
              "DiscountPercentage"
            )
              ? storePrice[0].DiscountPercentage
              : 0;
            product.MRP = storePrice[0].MRP;
            product.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
              "WalletTopUpSameAsRetailPrice"
            )
              ? storePrice[0].WalletTopUpSameAsRetailPrice
              : false;
            product.WalletTopUpAmount = storePrice[0].hasOwnProperty(
              "WalletTopUpAmount"
            )
              ? storePrice[0].WalletTopUpAmount
              : 0;
            product.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
              "EditWalletTopUpAmount"
            )
              ? storePrice[0].EditWalletTopUpAmount
              : false;
            products.push({ key: doc._id, ...product });
          }
        } else if (
          product.hasOwnProperty("StoreSpecificPricing") &&
          product.StoreSpecificPricing &&
          checkForStoreSpecificPriceOptions
        ) {
          // console.log(e)
          let storePrice = product.StoreSpecificPrices.filter(
            (f) => f.StoreID === storeID
          );
          if (storePrice.length > 0) {
            product.RetailPrice = storePrice[0].RetailPrice;
            product.SupplyPrice = storePrice[0].SupplyPrice;
            product.Markup = storePrice[0].Markup;
            product.DiscountAmount = storePrice[0].hasOwnProperty(
              "DiscountAmount"
            )
              ? storePrice[0].DiscountAmount
              : 0;
            product.DiscountPercentage = storePrice[0].hasOwnProperty(
              "DiscountPercentage"
            )
              ? storePrice[0].DiscountPercentage
              : 0;
            product.MRP = storePrice[0].MRP;
            product.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
              "WalletTopUpSameAsRetailPrice"
            )
              ? storePrice[0].WalletTopUpSameAsRetailPrice
              : false;
            product.WalletTopUpAmount = storePrice[0].hasOwnProperty(
              "WalletTopUpAmount"
            )
              ? storePrice[0].WalletTopUpAmount
              : 0;
            product.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
              "EditWalletTopUpAmount"
            )
              ? storePrice[0].EditWalletTopUpAmount
              : false;
            products.push({ key: doc._id, ...product });
            // if(product.SKU ==="PERI PERI Sauce Fries")
            // {
            //   console.log('StoreSpecificPricing', product)
            // }
          } else {
            products.push({ key: doc._id, ...product });
          }
        } else {
          products.push({ key: doc._id, ...product });
        }
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: products,
        scrollID: sanitizedMessage._scroll_id,
      };
    });
  };
}

export function pushProductToRedux(product) {
  return (dispatch) => {
    dispatch({ type: actionTypes.PUSH_PRODUCT_TO_REDUX, product });
  };
}

export function getProductsByBarCode(RegistrationID, from, size, searchText) {
  let searchParameters = {
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          { match: { RegistrationID: RegistrationID } },
          { match: { IsDeleted: "false" } },
        ],
        should: [],
        minimum_should_match: 1,
      },
    },
    sort: [
      {
        ActionOn: {
          order: "desc",
        },
      },
    ],
  };
  searchParameters.query.bool.should.push({
    match_phrase: { SKU: searchText.trim() },
  });
  searchParameters.query.bool.should.push({
    match_phrase: { BarCode: searchText.trim() },
  });
  // console.log("getProductsByBarCode", JSON.stringify(searchParameters))
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getProducts");
    return addMessage({ text: searchParameters }).then(function(result) {
      const products = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        products.push({ key: data._id, ...data._source });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: products,
      };
    });
  };
}

export function pushProductsToRedux(products) {
  return (dispatch) => {
    
    dispatch({ type: actionTypes.GET_ACTIVE_PRODUCTS, products });
  };
}

// export function getBulkProducts(RegistrationID, scrollID, option , searchText, searchProductType="",searchCategory,searchSubCategory,searchBrand,
// searchTags, searchDescription, searchHSNSAC) {
//   let searchParameters = null
//   if (scrollID == "") {
//     searchParameters = {
//       "from": 0,
//       "size": 2000,
//       "query": {
//         "bool": {
//           "must": [
//             { "match": { "RegistrationID": RegistrationID } }
//           ],
//           "must_not": [],
//           "filter": {
//             "bool": {
//               "must": [

//                 ],
//                 "should": [

//                 ],"minimum_should_match": 1
//             }
//           }
//         }
//       },
//       "sort": [
//         {
//           "Name": {
//             "order": "asc"
//           }
//         }
//       ]
//     }
//   }
//   else {
//     searchParameters = {
//       "scroll": "1m",
//       "scroll_id": scrollID
//     }
//   }
//   if(option === "active")
//   {
//     searchParameters.query.bool.must_not.push( {"match": { "IsDeleted": "true" }})
//   }
//   if(option === "inactive")
//   {
//     searchParameters.query.bool.must_not.push( {"match": { "IsDeleted": "false" }})
//   }
//   if(searchText.trim() !== ""){
//   searchParameters.query.bool.filter.bool.should.push({ "regexp": { "Name": "@"+ searchText.trim().toLowerCase() +  ".*" }})
//   searchParameters.query.bool.filter.bool.should.push({ "regexp": { "SKU": "@"+ searchText.trim().toLowerCase() +  ".*" }})
//   searchParameters.query.bool.filter.bool.should.push({ "regexp": { "BarCode": "@"+ searchText.trim().toLowerCase() +  ".*" }})

//   searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "Name": searchText.trim().toLowerCase() }})
//   searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "SKU": searchText.trim().toLowerCase()  }})
//   searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "BarCode": searchText.trim().toLowerCase() }})
//   }
//   if (searchProductType.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "term": { "ProductType": searchProductType.trim().toLowerCase() } })
//   }
//   if(searchCategory.trim()!=="")
//   {
//     searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Category": "@"+ searchCategory.trim().toLowerCase() +  ".*" }})
//   }
//   if(searchSubCategory.trim()!=="")
//   {
//     searchParameters.query.bool.filter.bool.must.push({ "regexp": { "SubCategory": "@"+ searchSubCategory.trim().toLowerCase() +  ".*" }})
//   }
//   if(searchBrand.trim()!=="")
//   {
//     searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Brand": "@"+ searchBrand.trim().toLowerCase() +  ".*" }})
//   }
//   if(searchTags.trim()!=="")
//   {
//     searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Tags": "@"+ searchTags.trim().toLowerCase() +  ".*" }})
//   }
//   if(searchBrand.trim()!=="")
//   {
//     searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Description": "@"+ searchDescription.trim().toLowerCase() +  ".*" }})
//   }
//   if(searchHSNSAC.trim()!=="")
//   {
//     searchParameters.query.bool.filter.bool.must.push({ "regexp": { "HSNSACCode": "@"+ searchHSNSAC.trim().toLowerCase() +  ".*" }})
//   }

//   return (dispatch, getState) => {
//     let addMessage = ''
//     if (getState().user.user.Role === "Store Owner" && scrollID == "") {
//       searchParameters.query.bool.must.push(
//         {
//           "match": {
//             "StoreSpecificPrices.StoreID": getState().user.store.key
//           }
//         }
//       )
//     }
//     if (scrollID === "") {
//       addMessage = firebase.functions().httpsCallable('getProductsWithScrollID');
//     }
//     else {
//       addMessage = firebase.functions().httpsCallable('getProductsForScrollID');
//     }
//     console.log("searchParameters", JSON.stringify(searchParameters));

//     return addMessage({ text: searchParameters }).then(function (result) {
//       const products = []
//       let sanitizedMessage = result.data.text;
//       sanitizedMessage.hits.hits.map((data, index) => {
//         products.push({ key: data._id, ...data._source })
//       })
//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: products, scrollID: sanitizedMessage._scroll_id }
//     });

//   }
// }

// export function getBulkProducts(RegistrationID, scrollID, option , searchText, searchProductType="",searchCategory,searchSubCategory,searchBrand,
// searchTags, searchDescription, searchHSNSAC) {
//   let searchParameters = null
//   if (scrollID == "") {
//     searchParameters = {
//       "from": 0,
//       "size": 2000,
//       "query": {
//         "bool": {
//           "must": [
//             { "match": { "RegistrationID": RegistrationID } }
//           ],
//           "must_not": [
//             {
//               "exists": {
//                 "field": "ParentProductID"
//               }
//             }
//           ],
//           "filter": {
//             "bool": {
//               "must": [

//                 ],
//                 "should": [

//                 ],"minimum_should_match": 1
//             }
//           }
//         }
//       },
//       "sort": [
//         {
//           "Name": {
//             "order": "asc"
//           }
//         }
//       ]
//     }
//   }
//   else {
//     searchParameters = {
//       "scroll": "1m",
//       "scroll_id": scrollID
//     }
//   }
//   if(option === "active")
//   {
//     searchParameters.query.bool.must_not.push( {"match": { "IsDeleted": "true" }})
//   }
//   if(option === "inactive")
//   {
//     searchParameters.query.bool.must_not.push( {"match": { "IsDeleted": "false" }})
//   }
//   if(searchText.trim() !== ""){
//   searchParameters.query.bool.filter.bool.should.push({ "regexp": { "Name": "@"+ searchText.trim().toLowerCase() +  ".*" }})
//   searchParameters.query.bool.filter.bool.should.push({ "regexp": { "SKU": "@"+ searchText.trim().toLowerCase() +  ".*" }})
//   searchParameters.query.bool.filter.bool.should.push({ "regexp": { "BarCode": "@"+ searchText.trim().toLowerCase() +  ".*" }})

//   searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "Name": searchText.trim().toLowerCase() }})
//   searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "SKU": searchText.trim().toLowerCase()  }})
//   searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "BarCode": searchText.trim().toLowerCase() }})
//   }
//   if (searchProductType.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "term": { "ProductType": searchProductType.trim().toLowerCase() } })
//   }
//   if(searchCategory.trim()!=="")
//   {
//     searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Category": "@"+ searchCategory.trim().toLowerCase() +  ".*" }})
//   }
//   if(searchSubCategory.trim()!=="")
//   {
//     searchParameters.query.bool.filter.bool.must.push({ "regexp": { "SubCategory": "@"+ searchSubCategory.trim().toLowerCase() +  ".*" }})
//   }
//   if(searchBrand.trim()!=="")
//   {
//     searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Brand": "@"+ searchBrand.trim().toLowerCase() +  ".*" }})
//   }
//   if(searchTags.trim()!=="")
//   {
//     searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Tags": "@"+ searchTags.trim().toLowerCase() +  ".*" }})
//   }
//   if(searchBrand.trim()!=="")
//   {
//     searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Description": "@"+ searchDescription.trim().toLowerCase() +  ".*" }})
//   }
//   if(searchHSNSAC.trim()!=="")
//   {
//     searchParameters.query.bool.filter.bool.must.push({ "regexp": { "HSNSACCode": "@"+ searchHSNSAC.trim().toLowerCase() +  ".*" }})
//   }

//   return (dispatch, getState) => {
//     let addMessage = ''
//     if (getState().user.user.Role === "Store Owner" && scrollID == "") {
//       searchParameters.query.bool.must.push(
//         {
//           "match": {
//             "StoreSpecificPrices.StoreID": getState().user.store.key
//           }
//         }
//       )
//     }
//     if (scrollID === "") {
//       addMessage = firebase.functions().httpsCallable('getProductsWithScrollID');
//     }
//     else {
//       addMessage = firebase.functions().httpsCallable('getProductsForScrollID');
//     }
//     // console.log("searchParameters", JSON.stringify(searchParameters));

//     return addMessage({ text: searchParameters }).then(function (result) {
//       const products = []
//       let sanitizedMessage = result.data.text;
//       sanitizedMessage.hits.hits.map((data, index) => {
//         products.push({ key: data._id, ...data._source })
//       })
//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: products, scrollID: sanitizedMessage._scroll_id }
//     });

//   }
// }

export function getBulkProducts(
  RegistrationID,
  scrollID,
  option,
  searchText,
  searchProductType = "",
  searchCategory,
  searchSubCategory,
  searchBrand,
  searchTags,
  searchDescription,
  searchHSNSAC,
  forBOM = false
) {
  let searchParameters = null;
  if (scrollID == "") {
    searchParameters = {
      from: 0,
      size: 2000,
      track_total_hits: true,
      query: {
        bool: {
          must: [{ match: { RegistrationID: RegistrationID } }],
          must_not: [],
          filter: {
            bool: {
              must: [],
              should: [],
              minimum_should_match: 1,
            },
          },
        },
      },
      sort: [
        {
          Name: {
            order: "asc",
          },
        },
      ],
    };
  } else {
    searchParameters = {
      scroll: "1m",
      scroll_id: scrollID,
    };
  }
  if (!forBOM) {
    searchParameters.query.bool.must_not.push({
      exists: {
        field: "ParentProductID",
      },
    });
  }

  if (option === "active") {
    searchParameters.query.bool.must_not.push({ match: { IsDeleted: "true" } });
  }
  if (option === "inactive") {
    searchParameters.query.bool.must_not.push({
      match: { IsDeleted: "false" },
    });
  }
  if (searchText.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({
      regexp: { Name: "@" + searchText.trim().toLowerCase() + ".*" },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: { SKU: "@" + searchText.trim().toLowerCase() + ".*" },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: { BarCode: "@" + searchText.trim().toLowerCase() + ".*" },
    });

    searchParameters.query.bool.filter.bool.should.push({
      match_phrase: { Name: searchText.trim().toLowerCase() },
    });
    searchParameters.query.bool.filter.bool.should.push({
      match_phrase: { SKU: searchText.trim().toLowerCase() },
    });
    searchParameters.query.bool.filter.bool.should.push({
      match_phrase: { BarCode: searchText.trim().toLowerCase() },
    });
  }
  if (searchProductType.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      term: { ProductType: searchProductType.trim().toLowerCase() },
    });
  }
  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { Category: "@" + searchCategory.trim().toLowerCase() + ".*" },
    });
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        SubCategory: "@" + searchSubCategory.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { Brand: "@" + searchBrand.trim().toLowerCase() + ".*" },
    });
  }
  if (searchTags.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { Tags: "@" + searchTags.trim().toLowerCase() + ".*" },
    });
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        Description: "@" + searchDescription.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchHSNSAC.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { HSNSACCode: "@" + searchHSNSAC.trim().toLowerCase() + ".*" },
    });
  }

  return (dispatch, getState) => {
    let addMessage = "";
    if (getState().user.user.Role === "Store Owner" && scrollID == "") {
      searchParameters.query.bool.must.push({
        match: {
          "StoreSpecificPrices.StoreID": getState().user.store.key,
        },
      });
    }
    if (scrollID === "") {
      addMessage = firebase
        .functions()
        .httpsCallable("getProductsWithScrollID");
    } else {
      addMessage = firebase.functions().httpsCallable("getProductsForScrollID");
    }
    // console.log("searchParameters", JSON.stringify(searchParameters));

    return addMessage({ text: searchParameters }).then(function(result) {
      const products = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        products.push({ key: data._id, ...data._source });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: products,
        scrollID: sanitizedMessage._scroll_id,
      };
    });
  };
}

export function poupulateSaleCategories() {
  return (dispatch, getState) => {
    let salesProductCategories = getState().config.ProductCategories.filter(
      (e) =>
        !e.IsDeleted &&
        getState().config.Products.filter(
          (f) => e.key === f.CategoryID && f.ProductTypeID !== 2
        ).length > 0
    );

    dispatch({ type: actionTypes.GET_SALES_PRODUCTS, salesProductCategories });
  };
}

export function poupulateSaleSubCategories() {
  return (dispatch, getState) => {
    let salesProductSubCategories = getState().config.ProductSubCategories.filter(
      (e) =>
        !e.IsDeleted &&
        getState().config.Products.filter(
          (f) => e.key === f.SubCategoryID && f.ProductTypeID !== 2
        ).length > 0
    );
    dispatch({
      type: actionTypes.GET_SALES_SUB_PRODUCTS,
      salesProductSubCategories,
    });
  };
}

// export function getProductsForACategory(from = 0, size = 10000, categoryID, subCategoryID) {
//   //console.log('categoryID',categoryID)
//   //console.log('subCategoryID',subCategoryID)
//   //console.log('data',data)
//   return (dispatch) => {

//     let addMessage = firebase.functions().httpsCallable('apiGetProductsForACategory');

//     return addMessage({ from: from, size: size, categoryID: categoryID, subCategoryID: subCategoryID }).then(function (result) {

//       console.log(result);
//       return [];
//     });
//   }
// }

export function getProductsByCategoryID(categoryID) {
  return (dispatch, getState) => {
    return database
      .collection("Products")
      .where("RegistrationID", "==", getState().user.user.RegistrationID)
      .where("CategoryID", "==", categoryID)
      .get()
      .then((querySnapshot) => {
        let products = [];
        querySnapshot.forEach((doc) => {
          let product = doc.data();
          products.push({ key: doc.id, ...product });
        });
        return products;
      });
  };
}

export function getProductsBySubCategoryID(subCategoryID) {
  return (dispatch, getState) => {
    return database
      .collection("Products")
      .where("RegistrationID", "==", getState().user.user.RegistrationID)
      .where("SubCategoryID", "==", subCategoryID)
      .get()
      .then((querySnapshot) => {
        let products = [];
        querySnapshot.forEach((doc) => {
          let product = doc.data();
          products.push({ key: doc.id, ...product });
        });
        return products;
      });
  };
}
