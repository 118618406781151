import React, { Component } from "react";
import { auth } from "../../firebase";
import ImageButton from "./Controls/ImageButton";
import Button from "./Controls/Button";
// import { Modal, Box, Typography } from '@mui/material';
import { useState } from "react";
// import Icon from './Controls/Icon'
// import Input from './Controls/Input'
// import logo from '../../images/Posible_logo_new.png'
import "./style.css";
// import * as actionTypes from '../../actionTypes'
import Avatar from "../Common/Avatar";
import ReactHtmlParser from "react-html-parser";
import { isMobileApp } from "../../actions/Util";
import Menu from "./Menu";
// import MenuButton from './MenuButton';
import Logo from "./Controls/Logo";
import ChangePassword from "../ChangePassword";
import Input from "./Controls/Input";
import Window from "./Controls/Window";
import {syncProductsWithAPI, deleteAllData,fetchProductsFromPouchDB} from '../../Db/ProductDb'
class Header extends Component {
  constructor() {
    super();
    this.state = {
      showMenu: false,
      menuCss: "menu-left",
      rightMenuCss: "menu-right ",
      showQuickAdd: false,
      showChangePassword: false,
      showSupportTicket: false,
      supportQuery: "",
      savingSupportTicket: false,
      supportQueryError: "",
      showmodal:false,
    };
    this.logout = this.logout.bind(this);
    this.onCloseMenu = this.onCloseMenu.bind(this);
    this.renderOnlineOfflineButton = this.renderOnlineOfflineButton.bind(this);
    this.renderSupportRequest = this.renderSupportRequest.bind(this);
    this.syncproduct = this.syncproduct.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.timer = null;
  }

  onOpenMenu(button) {
    if (button === "menubar") {
      this.setState({
        showMenu: true,
        menuCss: "menu-left menu-show-left",
        buttonSelected: button,
      });
    } else {
      this.setState({
        showMenu: true,
        rightMenuCss: "menu-right menu-show-right",
        buttonSelected: button,
      });
    }
  }

  onCloseMenu() {
    this.setState({
      showMenu: false,
      orientation: "",
      menuCss: "menu-left",
      rightMenuCss: "menu-right",
      buttonSelected: "",
    });
  }

  logout() {
    this.props.signOutUser();
    auth.signOut().then(() => {
      // console.log('signout done')
      this.onCloseMenu();
      this.props.history.push({ pathname: "/" });
    });
  }

  renderUserWidget() {
    return (
      <div className="menu-user">
        <div className="menu-user-content">
          <Avatar text={this.props.user.user.Name} />
          <div>
            <div>
              <b>{this.props.user.user.Name}</b>
            </div>
            <div>{this.props.user.user.EmailID}</div>
            <br />
            <br />
            <div>
              <i>Version: {this.props.config.Version}</i>
            </div>
          </div>
        </div>
        <br />
        <a
          onClick={() => {
            this.setState({ showChangePassword: true });
            this.onCloseMenu();
          }}
        >
          Change Password
        </a>
        <br />
        <br />
        <Button type="secondary" text="Sign Out" onClick={this.logout} />
      </div>
    );
  }


  renderStoreWidget() {
    return (
      <div className="menu-user">
        <div className="menu-user-content">
          <Avatar text={this.props.user.store.StoreName} />
          <div>
            <div>
              {" "}
              {this.props.user.store.StoreCode} -{" "}
              {this.props.user.store.StoreName}
            </div>
            <div>{this.props.user.store.Address1}</div>
            <div>{this.props.user.store.Address2}</div>
            <div>{this.props.user.store.City}</div>
            <div>{this.props.user.store.State}</div>
            <div>{this.props.user.store.Country}</div>
          </div>
        </div>

        <br />
        {this.props.config.WhiteLabel.Code !== "PINCODE" && (
          <div className="info">
            <div className="info-header">SMS</div>
            <div className="info-text">
              Sender ID :{" "}
              {this.props.registration.registration.hasOwnProperty("SenderID")
                ? this.props.registration.registration.SenderID
                : "Posble"}
            </div>
            <div className="info-text">
              Transactional SMS :{" "}
              {this.props.user.store.hasOwnProperty("NoOfTransactionalSMS")
                ? this.props.user.store.NoOfTransactionalSMS
                : "Unlimited"}
            </div>
            <div className="info-text">
              Promotional SMS :{" "}
              {this.props.user.store.hasOwnProperty("NoOfPromotionalSMS")
                ? this.props.user.store.NoOfPromotionalSMS
                : 0}
            </div>
          </div>
        )}
        {this.props.user.hasAccessToMultipleStores && (
          <Button
            type="secondary"
            text="Change Store"
            onClick={() => {
              this.onCloseMenu();
              this.props.onStoreChangeResetConfig();
              this.props.onStoreChangeResetUser();
              this.props.history.push("/home");
            }}
          />
        )}
      </div>
    );
  }

  renderCashRegisterWidget() {
    return (
      <div className="menu-user">
        <div className="menu-user-content">
          <Avatar text={this.props.user.cashRegister.CashRegisterName} />
          <div>
            <div>
              {this.props.user.cashRegister.CashRegisterCode}-
              {this.props.user.cashRegister.CashRegisterName}
            </div>
          </div>
        </div>
        <br />
        {this.props.user.cashRegisterClosing && (
          <React.Fragment>
            <div className="info">
              <div className="info-header">Cash Register Closing </div>
              <div className="info-text"></div>
              <div className="info-text">
                Opening Balance:{" "}
                {this.props.user.cashRegisterClosing.OpeningBalance}
              </div>
              <div className="info-text">
                Opening Time:{" "}
                {new Date(
                  this.props.user.cashRegisterClosing.OpeningTime.seconds * 1000
                )
                  .toString()
                  .substring(0, 24)}
              </div>
              <div className="v">
                Opening By:{" "}
                {this.props.user.cashRegisterClosing.OpeningByUserName}
              </div>
            </div>
            <Button
              type="secondary"
              text="Close Cash Register"
              onClick={() => {
                this.onCloseMenu();
                this.props.history.push({
                  pathname: "/home/sales/cashregisterclosing/",
                  state: {
                    cashRegisterClosing: this.props.user.cashRegisterClosing,
                  },
                });
              }}
            />
          </React.Fragment>
        )}
        <Button
          type="secondary"
          text="Setup"
          onClick={() => {
            this.props.history.push("/home/settings/cashregister/setup/");
          }}
        />
      </div>
    );
  }

  renderSupportRequest() {
    return (
      <Window
        header={"Raise Support Ticket"}
        height={"300px"}
        modal={true}
        processing={this.state.savingSupportTicket}
        onClose={() => this.setState({ showSupportTicket: false })}
        onButtonClick={(buttonType) => {
          if (buttonType.toLowerCase() === "submit") {
            if (this.state.supportQuery.trim() === "") {
              this.setState({
                supportQueryError:
                  "Please mention problem or issue that you are facing",
              });
              return;
            }
            this.setState({ savingSupportTicket: true, supportQueryError: "" });
            let email = {
              RegistrationID: this.props.registration.registration.key,
              StoreID: this.props.user.store.key,
              UserID: this.props.user.user.key,
              // To: "sellersupport@pincode.com",
              To: "pawan@posible.in",
              From:
                this.props.registration.registration.EmailID !== ""
                  ? this.props.registration.registration.EmailID
                  : "noreply@posible.in",
              Subject: "Provider ID " + this.props.user.store.key,
              TextBody: this.state.supportQuery,
              HTMLBody: "",
            };
            // console.log("email", email);
            this.props.sendEmail(email);
            this.props.getToastr("Support ticket raised.");
            this.setState({
              savingSupportTicket: false,
              showSupportTicket: false,
            });
          } else {
            this.setState({ showSupportTicket: false });
          }
        }}
        buttons={[
          { Type: "secondary", Text: "Cancel", Align: "left" },
          { Type: "primary", Text: "Submit", Align: "right" },
        ]}
      >
        <Input
          type="textarea"
          // className="textarea"
          // style={{ width: "100%", height: "150px" }}
          label="Describe Your Issue"
          onChange={(value) =>
            this.setState({ supportQuery: value, supportQueryError: "" })
          }
          error={this.state.supportQueryError}
          value={this.state.supportQuery}
        ></Input>
      </Window>
    );
  }

  renderHelpWidget() {
    return (
      <div className="menu-user">
        <div className="menu-user-content">
          <div className="info">
            <div className="info-header">Customer Support</div>
            <div className="info-text">
              {this.props.config.WhiteLabel.Code !== "PINCODE" && (
                <>
                  For any assistance please call us at{" "}
                  {this.props.config.WhiteLabel.HelpLineNo} or email us at{" "}
                  {this.props.config.WhiteLabel.HelpLineEmailID}
                </>
              )}
              {this.props.config.WhiteLabel.Code === "PINCODE" && (
                <>
                  Need help? Click the 'Raise Support Ticket' button to notify
                  our support team. We're here to assist you!
                  <br></br>
                  <br></br>
                  <Button
                    text="Raise Support Ticket"
                    type="primary"
                    onClick={() => {
                      this.setState({ showSupportTicket: true });
                      // window.location.href =
                      //   "mailto:sellersupport@pincode.com?subject=Provider ID " +
                      //   this.props.user.store.key;
                      // let email = {
                      //   RegistrationID: this.props.registration.registration
                      //     .key,
                      //   StoreID: this.props.user.store.key,
                      //   UserID: this.props.user.user.key,
                      //   To: "sellersupport@pincode.com",
                      //   //To: "vanshika.gupta@phonepe.com",
                      //   From: "noreply@posible.in",
                      //   Subject: "Provider ID " + this.props.user.store.key,
                      //   TextBody: "Need Help",
                      //   HTMLBody: "",
                      // };
                      // // console.log("email", email);
                      // this.props.sendEmail(email);
                      // this.props.getToastr("Support ticket raised.");
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div style={{ textAlign: "left" }}>
            {ReactHtmlParser(this.props.config.HelpContent)}
          </div>
        </div>
      </div>
    );
  }

  renderMenu() {
    return (
      <React.Fragment>
        {this.state.showMenu && (
          <div className={"menu-overlay"} onClick={() => this.onCloseMenu()} />
        )}
        <div className={this.state.menuCss}>
          {/* <div className="menu-content">
                    <div className="menu-logo" ><img src={logo} /></div>
                    {this.state.menus.filter(f=> !f.hasOwnProperty('Parent')).map((menu,index)=>this.renderMenuItem(menu, index))}
                </div>  */}
          <Menu {...this.props} onCloseMenu={this.onCloseMenu} />
          <div className="menu-close-button" onClick={() => this.onCloseMenu()}>
            <ImageButton type="remove" onClick={() => this.onCloseMenu()} />
          </div>
        </div>
        <div className={this.state.rightMenuCss}>
          <div className="menu-close-button" onClick={() => this.onCloseMenu()}>
            <ImageButton type="remove" onClick={() => this.onCloseMenu()} />
          </div>
          <div className="menu-content">
            {this.state.buttonSelected === "user" &&
              this.props.user.user &&
              this.renderUserWidget()}
            {this.state.buttonSelected === "store" &&
              this.props.user.store &&
              this.renderStoreWidget()}
            {this.state.buttonSelected === "cashregister" &&
              this.props.user.cashRegister &&
              this.renderCashRegisterWidget()}
            {this.state.buttonSelected === "help" && this.renderHelpWidget()}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderQuickAddWidget() {
    return (
      <React.Fragment>
        <div
          className="menu-overlay"
          onClick={() => this.setState({ showQuickAdd: false })}
        />
        <div className="quick-add-container">
          <div className="quick-add">
            <div className="quick-add-header">Quick Add</div>
            <div className="quick-add-content">
              {this.state.menus.filter(
                (f) =>
                  f.Parent === "sales" &&
                  f.hasOwnProperty("QuickAdd") &&
                  f.QuickAdd
              ).length > 0 && (
                <div className="quick-add-options">
                  <div className="quick-add-options-header">Sales</div>
                  {this.state.menus
                    .filter(
                      (f) =>
                        f.Parent === "sales" &&
                        f.hasOwnProperty("QuickAdd") &&
                        f.QuickAdd
                    )
                    .map((s, index) => (
                      <Button
                        key={"sales_button_" + index}
                        text={s.Title}
                        type="secondary"
                        onClick={() => {
                          this.setState({ showQuickAdd: false });
                          this.onMenuClicked(s, index);
                        }}
                      />
                    ))}
                </div>
              )}
              {this.state.menus.filter(
                (f) =>
                  f.Parent === "inventory" &&
                  f.hasOwnProperty("QuickAdd") &&
                  f.QuickAdd
              ).length > 0 && (
                <div className="quick-add-options">
                  <div className="quick-add-options-header">Inventory</div>
                  {this.state.menus
                    .filter(
                      (f) =>
                        f.Parent === "inventory" &&
                        f.hasOwnProperty("QuickAdd") &&
                        f.QuickAdd
                    )
                    .map((s, index) => (
                      <Button
                        key={"inventory_button_" + index}
                        text={s.Title}
                        type="secondary"
                        onClick={() => {
                          this.setState({ showQuickAdd: false });
                          this.onMenuClicked(s, index);
                        }}
                      />
                    ))}
                </div>
              )}
            </div>
          </div>
          <div
            className="quick-add-close-button"
            onClick={() => this.onCloseMenu()}
          >
            <ImageButton
              type="remove"
              onClick={() => this.setState({ showQuickAdd: false })}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderOnlineOfflineButton() {
    let show = false;

    if (this.props.config.WhiteLabel.Code === "PINCODE") {
      show = true;
    }
    if (
      this.props.config.WhiteLabel.Code === "PINCODE" &&
      this.props.registration.registration.hasOwnProperty(
        "EnablePincodeCatalogManagement"
      )
    ) {
      if (this.props.registration.registration.EnablePincodeCatalogManagement) {
        show = false;
      }
    }
    if (this.props.registration.registration.PincodeIntegration) {
      show = this.props.registration.registration.PincodeIntegration;
    }

    return (
      <>
        {show && this.props.user.store && (
          <div className="header-online-switch">
            {this.state.acceptOnlineOrdersLoading && (
              <i className="fa fa-spinner fa-spin" />
            )}
            {!this.state.acceptOnlineOrdersLoading && (
              <>
                <span>
                  {this.props.user.store.AcceptOnlineOrders
                    ? "Online"
                    : "Offline"}
                </span>
                <Input
                  type="switch"
                  label=""
                  value={this.props.user.store.AcceptOnlineOrders}
                  disable={this.state.acceptOnlineOrdersLoading}
                  onChange={async (value) => {
                    // console.log("onChange called");
                    this.setState({ acceptOnlineOrdersLoading: true });
                    let store = this.props.user.store;
                    store.AcceptOnlineOrders = value;
                    await this.props.setStore(store);
                    await this.props.updateStore(
                      { AcceptOnlineOrders: value },
                      store.key,
                      value ? "Online" : "Offline"
                    );
                    this.setState({ acceptOnlineOrdersLoading: false });
                  }}
                />
              </>
            )}
          </div>
        )}
      </>
    );
  }
  // syncProducts(products, this.props.user.user.RegistrationID)
  // .then(async updatedProducts => {
  //   console.log('Updated Products:', updatedProducts);
  //   await this.props.pushProductsToRedux(updatedProducts);
  //   await this.props.poupulateSaleCategories();
  //   await this.props.poupulateSaleSubCategories();
  // })
  // .catch(error => {
  //   console.error('Error syncing products:', error);
  // });
  syncproduct(){
    this.setState({showmodal:true})
    fetchProductsFromPouchDB(this.props.user.user.RegistrationID).then(async product=>{
      console.log("product",product)
      await this.props.pushProductsToRedux(product);
      this.setState({showmodal:false});
      await this.props.poupulateSaleCategories();
      await this.props.poupulateSaleSubCategories();
    })
  }
 
  
  
  render() {

    return (
      <React.Fragment>
        {/* {this.showmodal&&<MyModal showmodal={this.showmodal}/>} */}
        {this.state.showSupportTicket && this.renderSupportRequest()}
        {this.state.showChangePassword && (
          <ChangePassword
            onClose={() => this.setState({ showChangePassword: false })}
            {...this.props}
          />
        )}
        {this.renderMenu()}
        {this.state.showQuickAdd && this.renderQuickAddWidget()}
        <div className="header-pos no-print">
          <div className="left">
            {/* {this.props.user.store && <MenuButton {...this.props} onOpenMenu={(value)=>{this.onOpenMenu(value)}}/>} */}
            {this.props.user.store && <Menu {...this.props} />}
            {/* {(this.props.user.store && this.props.user.store.NoOfDaysToExpire && this.props.user.store.NoOfDaysToExpire > 0 )&&  <div className="menu-button"><ImageButton type="menubar" onClick={()=>this.onOpenMenu('menubar')}/></div>} */}
            <div
              className="logo"
              onClick={() => this.props.history.push({ pathname: "/home" })}
            >
              <Logo
                logoOption={this.props.config.WhiteLabel.HomePageLogoOption}
                {...this.props}
                height="46px"
              />
            </div>
            {/* <div className="logo" onClick={()=> this.props.history.push({pathname:"/home"})}><img src={logo}/></div> */}
            {/* <div className="search"><Input type="search" value={this.state.searchText} onChange={(value)=> this.setState({searchText:value})}/></div> */}
            {/* <div className="add"><ImageButton type="add" onClick={()=>this.setState({showQuickAdd:true})}/></div> */}
          </div>
          <div className="right">
            {!isMobileApp() && this.renderOnlineOfflineButton()}
            {!isMobileApp() && (
              <div className="help" onClick={() => this.onOpenMenu("help")}>
                <ImageButton type="help" />
              </div>
            )}
             {/* <div className="store" onClick={() => this.syncproduct("store")}>
                <ImageButton type="sync" />
              </div> */}
            {/* <div className="notification"><ImageButton type="notification"/></div> */}
            {this.props.user.cashRegister && (
              <div
                className="cashregister"
                onClick={() => this.onOpenMenu("cashregister")}
              >
                <ImageButton type="cashregister" />
              </div>
            )}
            {this.props.user.store && (
              <div className="store" onClick={() => this.onOpenMenu("store")}>
                <ImageButton type="store" />
              </div>
            )}
            {this.props.user.user && !isMobileApp() && (
              <div className="user" onClick={() => this.onOpenMenu("user")}>
                <ImageButton type="user" />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// const MyModal = ({showmodal}) => {
//   // const [showModal, setShowModal] = useState(showmodal?showmodal:false);
//   const [showModal, setShowModal] = useState(true);

//   const handleOpen = () => setShowModal(true);
//   const handleClose = () => setShowModal(false);

//   return (
//     <div>
//       <button type="button" className="btn btn-primary" onClick={handleOpen}>
//         Open Modal
//       </button>

//       {/* Modal */}
//       <div
//         className={`modal fade ${showModal ? 'show' : ''}`}
//         style={{ display: showModal ? 'block' : 'none' }}
//         tabIndex="-1"
//         role="dialog"
//       >
//         <div className="modal-dialog" role="document">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h5 className="modal-title">Bootstrap Modal</h5>
//               <button type="button" className="close" onClick={handleClose}>
//                 <span>&times;</span>
//               </button>
//             </div>
//             <div className="modal-body">
//               <p>This is a Bootstrap modal in React!</p>
//             </div>
//             <div className="modal-footer">
//               <button type="button" className="btn btn-secondary" onClick={handleClose}>
//                 Close
//               </button>
//               <button type="button" className="btn btn-primary">
//                 Save changes
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Backdrop */}
//       {showModal && <div className="modal-backdrop fade show"></div>}
//     </div>
//   );
// };

export default Header;
